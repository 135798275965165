import { toastr } from 'react-redux-toastr';
import { SubmissionError } from 'redux-form';

export default function handleErrorResponse(
  error,
  defaultMessage = 'An application error has occurred',
) {
  const message =
    error?.response?.data?.errors?.[0]?.detail ??
    error?.response?.data?.message ??
    defaultMessage;
  toastr.error('Error', message);

  throw new SubmissionError({ _error: message });
}
