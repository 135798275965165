import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import screeningActions from '../redux/screeningActions';
import { Button } from '../../../common/components';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import InternalTabs from '../../layout/components/InternalTabs/InternalTabs';
import ScreeningEvents from '../components/ScreeningEvents/ScreeningEvents';
import ScreeningScore from '../components/ScreeningScore/ScreeningScore';

const TAB_NAME = 'screeningTabs';

const ViewScreeningContainer = ({ dispatch, actions }) => {
  const { screeningId } = useParams();
  const screening = useSelector((state) => state.screening);

  useEffect(() => {
    if (screening.sessionId !== screeningId) {
      actions.resetSession();
      actions.getSession(screeningId);
    }

    return () => {
      actions.resetSession();
    };
  }, []);

  const rescreen = () => {
    return dispatch(push('/setup/' + screeningId));
  };

  return (
    <div className="container">
      <div className="content-block header-block">
        <h1 style={{ display: 'inline-block' }}>
          Viewing Session <br />
          <small className="text-muted">{screeningId}</small>
        </h1>
        <Button
          link
          className="pull-right top-margin"
          variant="primary"
          size="lg"
          label="Rescreen"
          handleClick={rescreen}
        />
      </div>

      <InternalTabs
        id={TAB_NAME}
        tabs={[
          {
            title: 'Score',
            content: (
              <ScreeningScore key="score-tab" sessionData={screening.session?.data} />
            ),
          },
          {
            title: 'Event Log',
            content: (
              <ScreeningEvents
                key="events-tab"
                events={screening.session?.data?.events ?? []}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default connect(null, (dispatch) => ({
  actions: bindActionCreators(screeningActions, dispatch),
  dispatch,
}))(ViewScreeningContainer);
