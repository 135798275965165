export const SET_ORGANISATION = 'cega/organisation/SET_ORGANISATION';
export const CLEAR_ORGANISATION = 'cega/organisation/CLEAR_ORGANISATION';
export const IS_SUBMITTING = 'cega/organisation/IS_SUBMITTING';

export const initialState = {
  isSubmitting: false,
  resources: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status,
      };
    case SET_ORGANISATION:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data,
          },
        },
      };
    case CLEAR_ORGANISATION:
      return initialState;
    default:
      return state;
  }
}
