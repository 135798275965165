import React from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import displayScore from '../../../../helpers/displayScore';

const ScreeningScoreConditionBreakdown = ({ showBreakdown, sessionData, settings }) => {
  const conditions = sessionData.conditions ?? [];
  const conditionScores = sessionData.conditionScores ?? [];
  const conditionAnswers = sessionData.conditionAnswers ?? {};
  const associatedEvents = settings.associatedEvents;

  const renderAnswer = (answer, key, questionValue) => {
    const isSelectedAnswer = Array.isArray(questionValue)
      ? questionValue.indexOf(answer.id ?? '') > -1
      : questionValue === (answer.id ?? '');

    return (
      <ListGroup.Item key={key} active={isSelectedAnswer}>
        {answer.text ?? ''}{' '}
        {showBreakdown && (answer.severityScore ?? 0) > 0 && (
          <Badge variant="secondary">{displayScore(answer.severityScore)}</Badge>
        )}{' '}
        {isSelectedAnswer && <strong>[Selected]</strong>}
      </ListGroup.Item>
    );
  };

  const renderQuestion = (question, key, conditionId) => {
    const answers = question.answers ?? [];
    const questionConditionAnswers = conditionAnswers[conditionId] ?? {};
    const questionValue = questionConditionAnswers[question.id] ?? '';

    return (
      <div key={key}>
        <h4>
          {question.position ?? ''}: {question.text ?? ''}
        </h4>
        <ListGroup>
          {Object.keys(answers).map((key) =>
            renderAnswer(answers[key], key, questionValue),
          )}
        </ListGroup>
        <br />
      </div>
    );
  };

  const renderCondition = (condition, key) => {
    const score = conditionScores[condition.id ?? ''] ?? {};
    const questions = condition.questions ?? [];

    return (
      <ListGroup.Item key={key}>
        <h3>
          {condition.name ?? ''}{' '}
          <Badge variant="secondary">{displayScore(score.score)}</Badge>
        </h3>
        {Object.keys(questions).map((key) =>
          renderQuestion(questions[key], key, condition.id ?? ''),
        )}
        {associatedEvents && score.associatedEvents && (
          <>
            <h4>Associated Events</h4>
            <p>
              <strong>Description:</strong> {score.associatedEvents.description ?? ''}{' '}
              <br />
              <strong>Conditions:</strong>{' '}
              {(score.associatedEvents.conditions ?? [])
                .map(
                  (condition) =>
                    (condition.name ?? '') +
                    ((condition.synonyms ?? []).length > 0
                      ? ' (' +
                        condition.synonyms.map((synonym) => synonym.name).join(', ') +
                        ')'
                      : ''),
                )
                .join(', ')}
            </p>
          </>
        )}
      </ListGroup.Item>
    );
  };

  return (
    <>
      <h2>Condition Breakdown</h2>
      <ListGroup>
        {Object.keys(conditions).map((key) => renderCondition(conditions[key], key))}
      </ListGroup>
    </>
  );
};

export default ScreeningScoreConditionBreakdown;
