import axios from 'axios';
import { encrypt, decrypt } from './encryption';

const oauthClient = {
  client_id: process.env.OAUTH_CLIENT_ID,
  client_secret: process.env.OAUTH_CLIENT_SECRET,
};

export function getAccessToken(data) {
  const requestData = {
    ...oauthClient,
    ...data,
    scope: 'admin screening',
  };

  return axios.post('/oauth/token', requestData);
}

export function storeOAuthData(res, { access_token, refresh_token }) {
  const cookieValue = JSON.stringify({
    access_token,
    refresh_token,
  });

  // cookie expires in 90 minutes
  res.cookie('oauth-secrets', encrypt(cookieValue), {
    maxAge: 90 * 60 * 1000,
    httpOnly: true,
  });
}

export function getOAuthData(req) {
  try {
    const cookieValue = JSON.parse(decrypt(req.cookies['oauth-secrets']));

    return cookieValue;
  } catch (e) {
    console.log('Invalid cookie data');
    // Invalid cookie ciphertext
    // Ignore and handle below
    return {};
  }
}
