import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import * as medicalQuestionSetActions from './medicalQuestionSetReducer';
import { push } from 'connected-react-router';

const defaultIncludes = [
  'questions',
  'questions.question',
  'questions.answerSet',
  'questions.answers',
  'questions.answers.forwardCondition',
];

const isSubmitting = (status) => {
  return {
    type: medicalQuestionSetActions.IS_SUBMITTING,
    status,
  };
};

const getMedicalQuestionSet = (id, includes = defaultIncludes) => {
  return (dispatch) => {
    const endpoint = `library/question-sets/${id}${queryString(includes)}`;

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: medicalQuestionSetActions.SET_QUESTION_SET,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        dispatch(push('/dashboard'));
        handleErrorResponse(error, 'Unable to load question set');
      });
  };
};

const createMedicalQuestionSet = (resource) => {
  return () => {
    return Axios.post('/library/question-sets', resource)
      .then((response) => {
        toastr.success('Success', 'Question set has been created successfully');
        return response.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error creating the question set');
      });
  };
};

const updateMedicalQuestionSet = (id, resource, includes = defaultIncludes) => {
  return () => {
    const endpoint = `/library/question-sets/${id}${queryString(includes)}`;

    return Axios.patch(endpoint, resource)
      .then((response) => {
        toastr.success('Success', 'Question set has been updated successfully');
        return response.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error updating the question set');
      });
  };
};

const actions = {
  isSubmitting,
  getMedicalQuestionSet,
  createMedicalQuestionSet,
  updateMedicalQuestionSet,
};
export default actions;
