export const SET_SESSION = 'cega/screening/SET_SESSION';
export const RESET_SESSION = 'cega/screening/RESET_SESSION';
export const SET_VERSIONS = 'cega/screening/SET_VERSIONS';

export const initialState = {
  versions: [],
  sessionId: '',
  ready: false,
  session: {
    data: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...state,
        ready: true,
        sessionId: action.session.id,
        session: {
          data: action.session,
        },
      };
    case SET_VERSIONS:
      return {
        ...state,
        versions: action.data,
      };
    case RESET_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
