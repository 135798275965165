import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

const Link = ({ to, children, onClick, className }) => (
  <RouterLink to={to} onClick={onClick} className={className}>
    {children}
  </RouterLink>
);

export default Link;
