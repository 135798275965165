import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  SelectReduxRow,
  ToggleRow,
  FullRow,
  ButtonBar,
  Button,
} from '../../../../common/components';
import { Form } from 'react-bootstrap';
import { reduxForm, getFormValues } from 'redux-form';
import { compose } from 'redux';

const LABEL_SIZES = [12, 12, 4, 4, 4];
const FIELD_SIZES = [12, 12, 8, 8, 8];

const SetupForm = ({
  previousSettings,
  versions,
  handleSubmit,
  handleSetupSubmit,
  submitting,
  dispatch,
  change,
  formValues,
}) => {
  useEffect(() => {
    if (previousSettings) {
      dispatch(change('version', previousSettings.version ?? ''));
      dispatch(change('associatedEvents', previousSettings.associatedEvents ?? false));
      dispatch(
        change('altitudeExclusions', previousSettings.altitudeExclusions ?? false),
      );
    }
  }, []);

  const versionsList = versions?.data
    ? versions?.data.map((version) => {
        return {
          label: version.attributes?.version ?? '',
          value: version.attributes?.version ?? '',
        };
      })
    : [];

  return (
    <Form horizontal="true" onSubmit={handleSubmit(handleSetupSubmit)}>
      <SelectReduxRow
        label="Library Version"
        labelSizes={LABEL_SIZES}
        fieldSizes={FIELD_SIZES}
        name="version"
        options={versionsList}
      />
      <ToggleRow
        label="Associated Events"
        labelSizes={LABEL_SIZES}
        fieldSizes={FIELD_SIZES}
        name="associatedEvents"
      />
      <ToggleRow
        label="Altitude Exclusions"
        labelSizes={LABEL_SIZES}
        fieldSizes={FIELD_SIZES}
        name="altitudeExclusions"
      />
      <hr />
      <FullRow>
        <ButtonBar>
          <Button
            type="submit"
            className="pull-right"
            variant="primary"
            isLoading={submitting}
            disabled={submitting}
          >
            Start Screening
          </Button>
        </ButtonBar>
      </FullRow>
    </Form>
  );
};

const getInitialValues = () => () => ({
  version: '',
  associatedEvents: false,
  altitudeExclusions: false,
});
const FORM_NAME = 'setupForm';
export default compose(
  connect((state) => ({
    initialValues: getInitialValues()(state),
    formValues: getFormValues(FORM_NAME)(state),
  })),
  reduxForm({ form: FORM_NAME }),
)(SetupForm);
