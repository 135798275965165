export const medicalCondition = {
  data: {
    type: 'medical-condition',
    attributes: {
      name: '',
      conditionScore: 1,
      altitudeExclusion: false,
    },
    meta: {
      synonyms: [],
      childAssociatedEvents: [],
      questions: [],
      questionSetId: '',
    },
  },
};

export default medicalCondition;
