import React from 'react';
import Icon from '../Icon/Icon';
import './Loader.scss';

const Loader = ({ message = 'Loading' }) => (
  <div className="loading__container small">
    <div className="loading__center-container">
      <h2 className="loading__message">
        {message} <br />
        <span className="loader-dots">
          <Icon iconName="loaderDot" />
          <Icon iconName="loaderDot" />
          <Icon iconName="loaderDot" />
        </span>
      </h2>
    </div>
  </div>
);

export default Loader;
