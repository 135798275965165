import React, { forwardRef } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { CheckCircle, Icon } from '../../../../common/components';
import displayName from '../../../../helpers/displayName';
import './Permission.scss';

const DescriptionTitle = ({ children }) => {
  return <div className="description-title">{children}</div>;
};
const Description = forwardRef((props, ref) => {
  return (
    <Popover id="popover-basic" ref={ref} {...props}>
      <Popover.Title as={DescriptionTitle}> {props.name}</Popover.Title>
      <Popover.Content>{props.description}</Popover.Content>
    </Popover>
  );
});

const Label = ({ permission }) => (
  <OverlayTrigger
    key={permission.id}
    placement="bottom"
    delay={{ show: 250, hide: 400 }}
    overlay={
      <Description
        description={permission.attributes.description}
        name={displayName(permission.attributes.action)}
      />
    }
  >
    <div>{displayName(permission.attributes.action)}</div>
  </OverlayTrigger>
);

const Permission = ({ onPermissionChange, permission, isChecked }) => {
  const handlePermissionChange = () => {
    onPermissionChange(permission.id);
  };
  if (onPermissionChange) {
    return (
      <CheckCircle
        onChange={handlePermissionChange}
        size="small"
        inline
        value={permission.id}
        checked={isChecked}
        label={<Label permission={permission} />}
      />
    );
  }
  return <Label permission={permission} />;
};

export default Permission;
