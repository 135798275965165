import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from '../redux/medicalAnswerSetActions';
import { Loader } from '../../../common/components';
import MedicalAnswerSetDetails from '../components/MedicalAnswerSetDetails/MedicalAnswerSetDetails';
import getIncludedResource from '../../../helpers/getIncludedResource';

const MedicalAnswerSetShowContainer = ({ getMedicalAnswerSet }) => {
  const { id } = useParams();
  useEffect(() => {
    getMedicalAnswerSet(id);
  }, [getMedicalAnswerSet, id]);

  const resource = useSelector((state) => state.medicalAnswerSet.resources?.[id]);
  const answers = resource
    ? getIncludedResource(resource.data, resource.included, 'answers')
    : [];
  return (
    <div className="container">
      <div className="content-block">
        {resource ? (
          <MedicalAnswerSetDetails resource={resource} answers={answers} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default connect(null, {
  getMedicalAnswerSet: actions.getMedicalAnswerSet,
})(MedicalAnswerSetShowContainer);
