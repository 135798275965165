import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import * as organisationActions from './organisationReducer';
import { push } from 'connected-react-router';

const defaultIncludes = [];

const isSubmitting = (status) => {
  return {
    type: organisationActions.IS_SUBMITTING,
    status,
  };
};

const getOrganisation = (id, includes = defaultIncludes) => {
  return (dispatch) => {
    const endpoint = `organisations/${id}${queryString(includes)}`;

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: organisationActions.SET_ORGANISATION,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        dispatch(push('/dashboard'));
        handleErrorResponse(error, 'Unable to load organisation');
      });
  };
};

const createOrganisation = (resource) => {
  return () => {
    return Axios.post('organisations', resource)
      .then((response) => {
        toastr.success('Success', 'Organisation has been created successfully');
        return response.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error creating the organisation');
      });
  };
};

const updateOrganisation = (id, resource, includes = defaultIncludes) => {
  return () => {
    const endpoint = `organisations/${id}${queryString(includes)}`;

    return Axios.patch(endpoint, resource)
      .then((response) => {
        toastr.success('Success', 'Organisation has been updated successfully');
        return response.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error updating the organisation');
      });
  };
};

const actions = {
  isSubmitting,
  getOrganisation,
  createOrganisation,
  updateOrganisation,
};
export default actions;
