import React from 'react';
import { Col, Form, Row as BootstrapRow } from 'react-bootstrap';

const FULL_ROW_SIZE = 12;
const LabelRow = ({
  labelSize,
  labelSizes,
  label,
  fieldSize,
  fieldSizes,
  fieldOffset,
  fieldOffsets,
  // labelOffset,
  // labelOffsets,
  showIfEmpty,
  className,
  children,
}) => {
  const smLabelSize = labelSize || 2;
  const smFieldSize = fieldSize || FULL_ROW_SIZE - smLabelSize;
  const smFieldOffset = fieldOffset || 0;

  if (!children && !showIfEmpty) {
    return null;
  }
  return (
    <BootstrapRow className={className}>
      {label && (
        <Form.Label
          column
          xs={labelSizes?.[0] ?? smLabelSize}
          sm={labelSizes?.[1] ?? smLabelSize}
          md={labelSizes?.[2] ?? smLabelSize}
          lg={labelSizes?.[3] ?? smLabelSize}
          xl={labelSizes?.[4] ?? smLabelSize}
        >
          {label}
        </Form.Label>
      )}
      <Form.Label
        column
        as={Col}
        xs={{
          span: fieldSizes?.[0] ?? smFieldSize,
          offset: fieldOffsets?.[0] ?? smFieldOffset,
        }}
        sm={{
          span: fieldSizes?.[1] ?? smFieldSize,
          offset: fieldOffsets?.[1] ?? smFieldOffset,
        }}
        md={{
          span: fieldSizes?.[2] ?? smFieldSize,
          offset: fieldOffsets?.[2] ?? smFieldOffset,
        }}
        lg={{
          span: fieldSizes?.[3] ?? smFieldSize,
          offset: fieldOffsets?.[3] ?? smFieldOffset,
        }}
        xl={{
          span: fieldSizes?.[4] ?? smFieldSize,
          offset: fieldOffsets?.[4] ?? smFieldOffset,
        }}
      >
        {children}
      </Form.Label>
    </BootstrapRow>
  );
};

export default LabelRow;
