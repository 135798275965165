import React from 'react';
import { connect } from 'react-redux';
import { resetPassword } from '../redux/authActions';
import ResetPasswordForm from '../components/ResetPasswordForm/ResetPasswordForm';
import { push } from 'connected-react-router';
import { useParams, useLocation } from 'react-router-dom';

const PASSWORD_TIMEOUT = 4000;
const ResetPasswordContainer = ({ dispatch }) => {
  const { token } = useParams();
  const { search } = useLocation();
  const email = React.useMemo(() => new URLSearchParams(search), [search]).get('email');

  const handlePasswordResetSubmit = (values) => {
    Promise.resolve(
      dispatch(
        resetPassword(token, values.email, values.password, values.password_confirmation),
      ),
    )
      .then(() => {})
      .catch((errorResponse) => {
        const errors = Object.values(errorResponse.errors);

        if (errors.length > 0 && errors.join(' ').includes('Reset token has expired')) {
          setTimeout(() => {
            dispatch(push('/password/remind'));
          }, PASSWORD_TIMEOUT);
        }
      });
  };
  return (
    <div>
      <ResetPasswordForm onSubmit={handlePasswordResetSubmit} initialValues={{ email }} />
    </div>
  );
};

export default connect()(ResetPasswordContainer);
