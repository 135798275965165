export const medicalQuestionSet = {
  data: {
    type: 'medical-question',
    attributes: {
      name: '',
    },
    meta: {
      questions: [],
    },
  },
};

export default medicalQuestionSet;
