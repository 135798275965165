import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import actions from '../redux/medicalQuestionSetActions';
import { Loader } from '../../../common/components';
import MedicalQuestionSetForm from '../components/MedicalQuestionSetForm/MedicalQuestionSetForm';

const MedicalQuestionSetEditContainer = ({
  getMedicalQuestionSet,
  updateMedicalQuestionSet,
  goTo,
}) => {
  const { id } = useParams();

  useEffect(() => {
    getMedicalQuestionSet(id);
  }, [getMedicalQuestionSet, id]);

  const saveQuestionSet = (values) => {
    return Promise.resolve(
      updateMedicalQuestionSet(id, values).then((resp) => {
        goTo(`/library/question-sets/${resp.data.id}`);
      }),
    );
  };
  const resource = useSelector((state) => state.medicalQuestionSet.resources?.[id]);

  return (
    <div className="container">
      <div className="content-block">
        {resource?.data?.id ? (
          <MedicalQuestionSetForm resource={resource} saveQuestionSet={saveQuestionSet} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default connect(null, {
  getMedicalQuestionSet: actions.getMedicalQuestionSet,
  updateMedicalQuestionSet: actions.updateMedicalQuestionSet,
  goTo: push,
})(MedicalQuestionSetEditContainer);
