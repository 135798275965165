import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import MedicalAnswerSetForm from '../components/MedicalAnswerSetForm/MedicalAnswerSetForm';
import answerSetSchema from '../../../schemas/medicalAnswerSet';
import actions from '../redux/medicalAnswerSetActions';

const MedicalAnswerSetNewContainer = ({ createMedicalAnswerSet, goTo }) => {
  const saveAnswerSet = (values) => {
    return Promise.resolve(
      createMedicalAnswerSet(values).then((resp) => {
        goTo(`/library/answer-sets/${resp.data.id}`);
      }),
    );
  };

  return (
    <div className="container">
      <div className="content-block">
        <MedicalAnswerSetForm resource={answerSetSchema} saveAnswerSet={saveAnswerSet} />
      </div>
    </div>
  );
};

export default connect(null, {
  createMedicalAnswerSet: actions.createMedicalAnswerSet,
  goTo: push,
})(MedicalAnswerSetNewContainer);
