import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import UserForm from '../components/UserForm/UserForm';
import userSchema from '../../../schemas/user';
import actions from '../redux/userActions';

const UserNewContainer = ({ createUser, goTo }) => {
  const saveUser = (values) => {
    return Promise.resolve(
      createUser(values).then((resp) => {
        goTo(`/admin/users/${resp.data.id}`);
      }),
    );
  };

  return (
    <div className="container">
      <div className="content-block">
        <UserForm resource={userSchema} saveUser={saveUser} />
      </div>
    </div>
  );
};

export default connect(null, {
  createUser: actions.createUser,
  goTo: push,
})(UserNewContainer);
