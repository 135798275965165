export default function getByStringPath(object, path) {
  if (path === undefined || object === undefined || object === null || !object) {
    return null;
  }

  if (typeof object == 'string') {
    return object;
  }

  path = path.replace(/\[(\w+)\]/g, '.$1');
  path = path.replace(/^\./, '');
  const pathArray = path.split('.');
  pathArray.forEach(function(value) {
    if (value in object) {
      object = object[value];
      if (!object) {
        return null;
      }
    }
  });
  return object;
}
