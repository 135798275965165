import React from 'react';
import { FullRow, Button } from '../../../common/components';
import InternalButtonBar from '../../layout/components/InternalButtonBar/InternalButtonBar';
import MedicalQuestionSetList from '../components/MedicalQuestionSetList/MedicalQuestionSetList';

const MedicalQuestionSetListContainer = () => {
  return (
    <div className="container">
      <div className="content-block">
        <h1>Question Set List</h1>
        <FullRow>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['medical_library.modify'],
                button: (
                  <Button
                    key="new-medical-question-set"
                    to="/library/question-sets/new"
                    className="pull-right"
                    variant="primary"
                    label="New Question Set"
                  />
                ),
              },
            ]}
          />
        </FullRow>
        <MedicalQuestionSetList />
      </div>
    </div>
  );
};

export default MedicalQuestionSetListContainer;
