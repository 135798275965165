import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, FieldArray } from 'redux-form';
import { Form } from 'react-bootstrap';
import { compose } from 'redux';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import {
  InputRow,
  Button,
  FullRow,
  BackButton,
  Row,
  ToggleRow,
  SelectMenuAsyncRow,
} from '../../../../common/components';
import SynonymsSection from './SynonymsSection';
import AssociatedEventsSection from './AssociatedEventsSection';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import medicalQuestionDataToFormData from '../MedicalQuestionSetForm/medicalQuestionDataToFormData';
import QuestionsSection from '../MedicalQuestionSetForm/QuestionsSection';
import actions from '../../redux/medicalAnswerSetActions';

const FORM_NAME = 'medicalConditionForm';

const LABEL_SIZES = [4, 4, 3, 3, 3];
const FIELD_SIZES = [8, 8, 9, 9, 9];

const MedicalConditionForm = ({
  handleSubmit,
  saveCondition,
  submitting,
  resource,
  formValues,
  array,
  dispatch,
  change,
  returnMedicalAnswerSetDetails,
}) => {
  const [preloadedForwardConditions, setPreloadedForwardConditions] = useState({});
  const [preloadedAssociatedEvents, setPreloadedAssociatedEvents] = useState({});

  const conditionName = formValues?.data?.attributes?.name
    ? formValues.data.attributes.name
    : 'New Condition';

  const synonyms = resource
    ? getIncludedResource(resource.data, resource.included, 'children')
    : [];

  const associatedEvents = resource
    ? getIncludedResource(resource.data, resource.included, 'childAssociatedEvents')
    : [];

  const medicalQuestionSet = resource
    ? getIncludedResource(resource.data, resource.included, 'questionSet').data
    : null;

  const questions = resource
    ? getIncludedResource(resource.data, resource.included, 'questions')
    : [];

  useEffect(() => {
    if (Array.isArray(synonyms)) {
      dispatch(
        change(
          'data.meta.synonyms',
          Object.keys(synonyms).map((key) => {
            return { id: synonyms[key].id, name: synonyms[key].attributes?.name };
          }),
        ),
      );
    } else {
      dispatch(change('data.meta.synonyms', []));
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(associatedEvents)) {
      dispatch(
        change(
          'data.meta.childAssociatedEvents',
          Object.keys(associatedEvents).map((key) => {
            return associatedEvents[key]?.id;
          }),
        ),
      );

      const associatedEventConditions = {};
      associatedEvents.forEach((associatedEvent) => {
        if (associatedEvent) {
          associatedEventConditions[associatedEvent.id] = associatedEvent.attributes.name;
        }
      });
      setPreloadedAssociatedEvents(associatedEventConditions);
    } else {
      dispatch(change('data.meta.childAssociatedEvents', []));
    }
  }, []);

  useEffect(() => {
    if (medicalQuestionSet) {
      dispatch(change('data.meta.questionSetId', medicalQuestionSet.id));
    } else {
      dispatch(change('data.meta.questionSetId', ''));
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(questions)) {
      const questionData = medicalQuestionDataToFormData(questions, resource.included);
      dispatch(change('data.meta.questions', questionData));

      const forwardConditions = {};
      questionData.forEach((question) => {
        if (question.answers && question.answers.length > 0) {
          question.answers.forEach((answer) => {
            if (answer.forwardConditionId) {
              const tempResource = {
                relationships: {
                  forward_condition: {
                    data: {
                      id: answer.forwardConditionId,
                      type: 'medical-condition',
                    },
                  },
                },
              };
              const includedCondition = getIncludedResource(
                tempResource,
                resource.included,
                'forward_condition',
              );
              if (includedCondition) {
                forwardConditions[includedCondition.data.id] =
                  includedCondition.data.attributes.name;
              }
            }
          });
        }
      });
      setPreloadedForwardConditions(forwardConditions);
    } else {
      dispatch(change('data.meta.questions', []));
    }
  }, []);

  const reorderQuestions = (path, startIndex, endIndex) => {
    array.move(path, startIndex, endIndex);
  };

  return (
    <>
      <h2 className="resource-name">{conditionName}</h2>
      <Form className="condition-form" onSubmit={handleSubmit(saveCondition)}>
        <InputRow
          name="data.attributes.name"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          label="Name"
        />
        <InputRow
          name="data.attributes.conditionScore"
          labelSizes={LABEL_SIZES}
          fieldSizes={[8, 4, 4, 2, 2]}
          type="number"
          label="Condition Score"
        />
        <ToggleRow
          name="data.attributes.altitudeExclusion"
          label="Altitude Exclusion"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
        />
        <Row labelSizes={LABEL_SIZES} fieldSizes={FIELD_SIZES} label="Synonyms">
          <FieldArray name="data.meta.synonyms" component={SynonymsSection} />
        </Row>
        <Row labelSizes={LABEL_SIZES} fieldSizes={FIELD_SIZES} label="Associated Events">
          <FieldArray
            name="data.meta.childAssociatedEvents"
            component={AssociatedEventsSection}
            associatedEvents={formValues?.data?.meta?.childAssociatedEvents ?? []}
            preloadedAssociatedEvents={preloadedAssociatedEvents}
          />
        </Row>
        <hr />
        {(!Array.isArray(questions) || questions.length <= 0) && (
          <SelectMenuAsyncRow
            name="data.meta.questionSetId"
            label="Link to Question Set"
            labelSizes={LABEL_SIZES}
            fieldSizes={FIELD_SIZES}
            url="library/question-sets"
            optionValuePath="id"
          />
        )}
        {!formValues?.data?.meta?.questionSetId && (
          <>
            <h3>Questions</h3>
            <FieldArray
              name="data.meta.questions"
              component={QuestionsSection}
              reorderQuestions={reorderQuestions}
              returnMedicalAnswerSetDetails={returnMedicalAnswerSetDetails}
              questions={formValues?.data?.meta?.questions ?? []}
              preloadedForwardConditions={preloadedForwardConditions}
            />
          </>
        )}
        <FullRow>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['medical_library.modify'],
                button: (
                  <Button
                    key="edit-condition"
                    className="pull-right"
                    type="submit"
                    variant="primary"
                    isLoading={submitting}
                    label="Save Condition"
                  />
                ),
              },
              {
                button: (
                  <BackButton
                    key="back"
                    to={
                      resource.data?.id
                        ? `/library/conditions/${resource.data.id}`
                        : '/library/conditions'
                    }
                  />
                ),
              },
            ]}
          />
        </FullRow>
      </Form>
    </>
  );
};

const getInitialValues = (resource) => () => resource;
export default compose(
  connect(
    (state, ownProps) => ({
      initialValues: getInitialValues(ownProps.resource)(state),
      formValues: getFormValues(FORM_NAME)(state),
    }),
    {
      returnMedicalAnswerSetDetails: actions.returnMedicalAnswerSetDetails,
    },
  ),
  reduxForm({ form: FORM_NAME }),
)(MedicalConditionForm);
