import React from 'react';
import { connect, useSelector } from 'react-redux';
import GuestHeader from '../components/GuestHeader/GuestHeader';
import InternalHeader from '../components/InternalHeader/InternalHeader';
import { logOut } from '../../auth/redux/authActions';

const HeaderContainer = ({ dispatch }) => {
  const user = useSelector((state) => state.auth.user);
  const authenticated = useSelector((state) => state.auth.authenticated);

  const handleLogout = () => {
    dispatch(logOut());
  };

  if (authenticated) {
    return <InternalHeader user={user} handleLogout={handleLogout} />;
  }
  return <GuestHeader />;
};

export default connect()(HeaderContainer);
