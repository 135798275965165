import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from '../redux/medicalQuestionSetActions';
import { Loader } from '../../../common/components';
import MedicalQuestionSetDetails from '../components/MedicalQuestionSetDetails/MedicalQuestionSetDetails';
import getIncludedResource from '../../../helpers/getIncludedResource';

const MedicalQuestionSetShowContainer = ({ getMedicalQuestionSet }) => {
  const { id } = useParams();
  useEffect(() => {
    getMedicalQuestionSet(id);
  }, [getMedicalQuestionSet, id]);

  const resource = useSelector((state) => state.medicalQuestionSet.resources?.[id]);
  return (
    <div className="container">
      <div className="content-block">
        {resource ? <MedicalQuestionSetDetails resource={resource} /> : <Loader />}
      </div>
    </div>
  );
};

export default connect(null, {
  getMedicalQuestionSet: actions.getMedicalQuestionSet,
})(MedicalQuestionSetShowContainer);
