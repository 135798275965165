import React from 'react';
import { BackButton, Button, FullRow, LabelRow } from '../../../../common/components';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import QuestionList from '../MedicalConditionDetails/QuestionList';
import getIncludedResource from '../../../../helpers/getIncludedResource';

const LABEL_SIZES = 2;
const FIELD_SIZES = 10;

const MedicalQuestionSetDetails = ({ resource }) => {
  const resourceAttributes = resource.data.attributes;

  const medicalConditionQuestions = getIncludedResource(
    resource.data,
    resource.included,
    'questions',
  );

  return (
    <>
      <h2 className="resource-name">{resourceAttributes.name}</h2>

      <LabelRow label="Name" labelSizes={LABEL_SIZES} fieldSizes={FIELD_SIZES}>
        {resourceAttributes.name}
      </LabelRow>
      <QuestionList
        medicalConditionQuestions={medicalConditionQuestions}
        included={resource.included}
      />

      <FullRow>
        <InternalButtonBar
          buttons={[
            {
              permissions: ['medical_library.modify'],
              button: (
                <Button
                  key="edit-question-set"
                  to={`/library/question-sets/${resource.data.id}/edit`}
                  className="pull-right"
                  bsStyle="primary"
                  rightIcon="edit"
                  label="Edit Question Set"
                />
              ),
            },
            {
              button: <BackButton key="back" to="/library/question-sets" />,
            },
          ]}
        />
      </FullRow>
    </>
  );
};

export default MedicalQuestionSetDetails;
