import React from 'react';
import HeaderContainer from './Header';
import Footer from '../components/Footer/Footer';
import '../../../styles/vendor.scss';
import '../../../styles/app.scss';

const AppContainer = ({ children }) => {
  return (
    <>
      <div className="page-wrapper">
        <HeaderContainer />
        <main>{children}</main>
        <div className="push" />
      </div>
      <Footer className="hidden" />
    </>
  );
};
export default AppContainer;
