export const organisation = {
  data: {
    type: 'organisation',
    attributes: {
      name: '',
      description: '',
    },
  },
};

export default organisation;
