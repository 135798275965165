export const SET_CONDITION = 'cega/medical-condition/SET_CONDITION';
export const CLEAR_CONDITION = 'cega/medical-condition/CLEAR_CONDITION';
export const IS_SUBMITTING = 'cega/medical-condition/IS_SUBMITTING';

export const initialState = {
  isSubmitting: false,
  resources: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status,
      };
    case SET_CONDITION:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data,
          },
        },
      };
    case CLEAR_CONDITION:
      return initialState;
    default:
      return state;
  }
}
