import React from 'react';
import getIncludedResource from '../../../../helpers/getIncludedResource';

const QuestionList = ({ medicalConditionQuestions, included }) => {
  const showSeverityScore = (score) =>
    score ? <span className="subtitle"> x {score}</span> : '';
  const showForward = (forward) =>
    forward ? <span className="subtitle"> => forward</span> : '';
  const showNextQuestionIndex = (index) =>
    index ? <span className="subtitle"> => {index}</span> : '';

  return (
    <>
      {medicalConditionQuestions.length > 0 ? (
        <ul className="unmarked-list">
          {medicalConditionQuestions.map((medicalConditionQuestion, key) => {
            const question = getIncludedResource(
              medicalConditionQuestion,
              included,
              'question',
            ).data;
            const medicalConditionAnswers = getIncludedResource(
              medicalConditionQuestion,
              included,
              'answers',
            );
            return (
              <li key={key}>
                {medicalConditionQuestion.attributes.questionIndex}.{' '}
                {question.attributes?.question}
                <ul>
                  {medicalConditionAnswers.map((medicalConditionAnswer, key2) => {
                    const answer = getIncludedResource(
                      medicalConditionAnswer,
                      included,
                      'answer',
                    ).data;
                    return (
                      <li key={key2}>
                        {answer.attributes?.answer}
                        {showSeverityScore(
                          medicalConditionAnswer.attributes?.severityScore,
                        )}
                        {showForward(
                          medicalConditionAnswer.attributes?.forwardConditionId,
                        )}
                        {showNextQuestionIndex(
                          medicalConditionAnswer.attributes?.nextQuestionIndex,
                        )}
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      ) : (
        <>No questions</>
      )}
    </>
  );
};

export default QuestionList;
