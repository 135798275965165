import React from 'react';
import {
  BackButton,
  Button,
  FullRow,
  LabelRow,
  Link,
} from '../../../../common/components';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import isEmpty from '../../../../helpers/isEmpty';
import displayName from '../../../../helpers/displayName';
import { Row, Col } from 'react-bootstrap';
import QuestionList from './QuestionList';

const LABEL_SIZES = [4, 4, 4, 4, 4];
const FIELD_SIZES = [8, 8, 8, 8, 8];

const MedicalConditionDetails = ({ resource }) => {
  const resourceAttributes = resource.data.attributes;
  const parent = getIncludedResource(resource.data, resource.included, 'parent').data;
  const isSynonym = !isEmpty(parent);
  const synonyms = getIncludedResource(resource.data, resource.included, 'children');
  const associatedEvents = getIncludedResource(
    resource.data,
    resource.included,
    'childAssociatedEvents',
  );
  const parentAssociatedEvents = getIncludedResource(
    resource.data,
    resource.included,
    'parentAssociatedEvents',
  );
  const medicalConditionQuestions = getIncludedResource(
    resource.data,
    resource.included,
    'questions',
  );
  const medicalQuestionSet = getIncludedResource(
    resource.data,
    resource.included,
    'questionSet',
  ).data;

  return (
    <>
      <h2 className="resource-name">{resourceAttributes.name}</h2>

      <Row>
        <Col sm={6}>
          <LabelRow label="Name" labelSizes={LABEL_SIZES} fieldSizes={FIELD_SIZES}>
            {resourceAttributes.name}{' '}
            {isSynonym ? <span className="subtitle">(synonym)</span> : ''}
          </LabelRow>

          {isSynonym ? (
            <LabelRow
              label="Parent Condition"
              labelSizes={LABEL_SIZES}
              fieldSizes={FIELD_SIZES}
            >
              {parent.attributes?.name}
            </LabelRow>
          ) : (
            <>
              <LabelRow
                label="Condition Score"
                labelSizes={LABEL_SIZES}
                fieldSizes={FIELD_SIZES}
              >
                {resourceAttributes.conditionScore}
              </LabelRow>
              <LabelRow
                label="Altitude Exclusion"
                labelSizes={LABEL_SIZES}
                fieldSizes={FIELD_SIZES}
              >
                {displayName(resourceAttributes.altitudeExclusion)}
              </LabelRow>
              <LabelRow
                label="Synonyms"
                labelSizes={LABEL_SIZES}
                fieldSizes={FIELD_SIZES}
              >
                {synonyms.length > 0 ? (
                  <ul className="unmarked-list">
                    {synonyms.map((synonym, key) => (
                      <li key={key}>{synonym.attributes.name}</li>
                    ))}
                  </ul>
                ) : (
                  <>None</>
                )}
              </LabelRow>
              <LabelRow
                label="Associated Events"
                labelSizes={LABEL_SIZES}
                fieldSizes={FIELD_SIZES}
              >
                {associatedEvents.length > 0 ? (
                  <>
                    {resourceAttributes.associatedEventsDescription}:
                    <ul className="unmarked-list">
                      {associatedEvents.map((associatedEvent, key) => (
                        <li key={key}>{associatedEvent.attributes.name}</li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <>None</>
                )}
              </LabelRow>
              {Array.isArray(parentAssociatedEvents) &&
                parentAssociatedEvents.length > 0 && (
                  <LabelRow
                    label="Associated Event of"
                    labelSizes={LABEL_SIZES}
                    fieldSizes={FIELD_SIZES}
                  >
                    <ul className="unmarked-list">
                      {parentAssociatedEvents.map((associatedEvent, key) => (
                        <li key={key}>{associatedEvent.attributes.name}</li>
                      ))}
                    </ul>
                  </LabelRow>
                )}
              {medicalQuestionSet.id && (
                <LabelRow
                  label="Question Set"
                  labelSizes={LABEL_SIZES}
                  fieldSizes={FIELD_SIZES}
                >
                  <Link to={`/library/question-sets/${medicalQuestionSet.id}`}>
                    {medicalQuestionSet?.attributes?.name}
                  </Link>
                </LabelRow>
              )}
            </>
          )}
        </Col>
        <Col sm={6}>
          {!medicalQuestionSet.id && (
            <QuestionList
              medicalConditionQuestions={medicalConditionQuestions}
              included={resource.included}
            />
          )}
        </Col>
      </Row>

      <FullRow>
        <InternalButtonBar
          buttons={[
            {
              permissions: ['medical_library.modify'],
              filter: [{ value: isSynonym, filterValues: [false] }],
              button: (
                <Button
                  key="edit-condition"
                  to={`/library/conditions/${resource.data.id}/edit`}
                  className="pull-right"
                  bsStyle="primary"
                  rightIcon="edit"
                  label="Edit Condition"
                />
              ),
            },
            {
              permissions: ['medical_library.view'],
              filter: [{ value: isSynonym, filterValues: [true] }],
              button: (
                <Button
                  key="parent-condition"
                  to={`/library/conditions/${parent.id}`}
                  className="pull-right"
                  bsStyle="primary"
                  label="View Parent Condition"
                />
              ),
            },
            {
              button: <BackButton key="back" to="/library/conditions" />,
            },
          ]}
        />
      </FullRow>
    </>
  );
};

export default MedicalConditionDetails;
