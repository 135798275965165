import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import actions from '../redux/medicalQuestionActions';
import { Loader } from '../../../common/components';
import MedicalQuestionForm from '../components/MedicalQuestionForm/MedicalQuestionForm';

const MedicalQuestionEditContainer = ({
  getMedicalQuestion,
  updateMedicalQuestion,
  goTo,
}) => {
  const { id } = useParams();

  useEffect(() => {
    getMedicalQuestion(id);
  }, [getMedicalQuestion, id]);

  const saveQuestion = (values) => {
    return Promise.resolve(
      updateMedicalQuestion(id, values).then((resp) => {
        goTo(`/library/questions/${resp.data.id}`);
      }),
    );
  };
  const resource = useSelector((state) => state.medicalQuestion.resources?.[id]);

  return (
    <div className="container">
      <div className="content-block">
        {resource?.data?.id ? (
          <MedicalQuestionForm resource={resource} saveQuestion={saveQuestion} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default connect(null, {
  getMedicalQuestion: actions.getMedicalQuestion,
  updateMedicalQuestion: actions.updateMedicalQuestion,
  goTo: push,
})(MedicalQuestionEditContainer);
