import React, { useEffect, useState } from 'react';
import { BackButton, Button, FullRow, LabelRow } from '../../../../common/components';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import PermissionsList from '../PermissionsList/PermissionsList';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';

const LABEL_SIZES = 2;
const FIELD_SIZES = 10;

const RoleDetails = ({ resource }) => {
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    const rolePermissions = getIncludedResource(
      resource.data,
      resource.included,
      'permissions',
    );
    const permissionCategories = rolePermissions.reduce((carry, permission) => {
      const { category } = permission.attributes;

      if (carry[category]) {
        carry[category].push(permission);
      } else {
        carry[category] = [permission];
      }
      return carry;
    }, {});
    setPermissions(permissionCategories);
  }, [resource]);

  const resourceAttributes = resource.data.attributes;

  return (
    <>
      <h2 className="resource-name">{resourceAttributes.name}</h2>

      <LabelRow label="Name" labelSizes={LABEL_SIZES} fieldSizes={FIELD_SIZES}>
        {resourceAttributes.name}
      </LabelRow>

      <LabelRow label="Description" labelSizes={LABEL_SIZES} fieldSizes={FIELD_SIZES}>
        {resourceAttributes.description}
      </LabelRow>

      <hr />

      <PermissionsList permissions={permissions} />

      <FullRow>
        <InternalButtonBar
          buttons={[
            {
              permissions: ['role.edit'],
              button: (
                <Button
                  key="edit-role"
                  to={`/admin/roles/${resource.data.id}/edit`}
                  className="pull-right"
                  bsStyle="primary"
                  rightIcon="edit"
                  label="Edit Role"
                />
              ),
            },
            {
              button: <BackButton key="back" to="/admin/roles" />,
            },
          ]}
        />
      </FullRow>
    </>
  );
};

export default RoleDetails;
