import React from 'react';
import { Field } from 'redux-form';
import { Col, Form, Row } from 'react-bootstrap';
import SelectRedux from '../Selects/SelectRedux';
import Input from '../Input/Input';

const TITLES = [
  { label: 'Mr', value: 'Mr' },
  { label: 'Mrs', value: 'Mrs' },
  { label: 'Miss', value: 'Miss' },
  { label: 'Ms', value: 'Ms' },
  { label: 'Master', value: 'Master' },
  { label: 'Mx', value: 'Mx' },
  { label: 'Dr', value: 'Dr' },
  { label: 'Prof', value: 'Prof' },
  { label: 'Rev', value: 'Rev' },
  { label: 'Cpt', value: 'Cpt' },
  { label: 'Sir', value: 'Sir' },
  { label: 'Dame', value: 'Dame' },
  { label: 'Lord', value: 'Lord' },
  { label: 'Lady', value: 'Lady' },
];

const upper = (value) => {
  if (!value) {
    return value;
  }
  const names = value.split(' ');
  const capitalised = names.map((name) => name.charAt(0).toUpperCase() + name.slice(1));
  return capitalised.join(' ');
};

const Label = ({ labelSizes, label }) => {
  if (!label) {
    return null;
  }
  return (
    <Form.Label
      column
      xs={labelSizes?.[0] ?? 2}
      sm={labelSizes?.[1] ?? 2}
      md={labelSizes?.[2] ?? 2}
      lg={labelSizes?.[3] ?? 2}
      xl={labelSizes?.[4] ?? 2}
    >
      {label}
    </Form.Label>
  );
};

const Title = ({ show, fieldSizes, isClearable, validate }) => {
  if (!show) {
    return null;
  }
  return (
    <Form.Group
      as={Col}
      xs={fieldSizes[0]}
      sm={fieldSizes[1]}
      md={fieldSizes[2]}
      lg={fieldSizes[3]}
      xl={fieldSizes[4]}
    >
      <Field
        clearable={isClearable}
        name="title"
        placeholder="Title"
        options={TITLES}
        validate={validate}
        component={SelectRedux}
      />
    </Form.Group>
  );
};

const NameFields = ({
  label,
  hideTitle,
  disableOnBlur,
  labelSizes,
  fieldSizes,
  titleSizes,
  isClearable,
  validate,
}) => {
  const titleColSizes = titleSizes || [2, 2, 2, 2, 2];
  const fieldColSizes = fieldSizes || [5, 5, 5, 5, 5];

  return (
    <Form.Row as={Row}>
      <Label label={label} labelSizes={labelSizes} />
      <Title
        show={!hideTitle}
        isClearable={isClearable}
        validate={validate}
        fieldSizes={titleColSizes}
      />

      <Form.Group
        as={Col}
        xs={fieldColSizes[0]}
        sm={fieldColSizes[1]}
        md={fieldColSizes[2]}
        lg={fieldColSizes[3]}
        xl={fieldColSizes[4]}
      >
        <Field
          name="first_name"
          placeholder="First Name"
          normalize={disableOnBlur ? upper : null}
          disableOnBlur={disableOnBlur}
          validate={validate}
          component={Input}
        />
      </Form.Group>
      <Form.Group
        as={Col}
        xs={fieldColSizes[0]}
        sm={fieldColSizes[1]}
        md={fieldColSizes[2]}
        lg={fieldColSizes[3]}
        xl={fieldColSizes[4]}
      >
        <Field
          name="last_name"
          placeholder="Surname"
          normalize={disableOnBlur ? upper : null}
          validate={validate}
          component={Input}
        />
      </Form.Group>
    </Form.Row>
  );
};

export default NameFields;
