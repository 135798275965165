export default function displayName(value, customFunction) {
  if (value === undefined || value === null) {
    return '';
  }

  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  let newValue = value.replace(/-/g, ' ').replace(/_/g, ' ');
  if (!customFunction) {
    newValue = newValue.replace(/([A-Z])/g, ' $1').trim();
    return newValue.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  }

  if (customFunction && typeof customFunction === 'function') {
    return customFunction(newValue);
  }

  return value;
}
