import Axios from 'axios/index';
import { subMinutes, isAfter } from 'date-fns';

const SET_DATA = 'selects/SET_DATA';
const IS_FETCHING = 'selects/IS_FETCHING';
const SET_DISPLAY_OPTIONS = 'selects/SET_DISPLAY_OPTIONS';

export const initialState = {};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        [action.source]: {
          data: action.data,
          included: action.included,
          options: action.options,
          lastFetch: action.lastFetch,
          isFetching: action.isFetching,
        },
      };
    case SET_DISPLAY_OPTIONS:
      return {
        ...state,
        [action.source]: {
          ...state[action.source],
          displayOptions: action.data,
        },
      };
    case IS_FETCHING:
      return {
        ...state,
        [action.source]: {
          isFetching: action.status,
        },
      };
    default:
      return state;
  }
}

const isFetching = (source, status) => ({
  type: IS_FETCHING,
  source,
  status,
});

const setDisplayOptions = (source, options) => ({
  type: SET_DISPLAY_OPTIONS,
  source,
  data: options,
});

const setData = (source, response, options) => {
  return {
    type: SET_DATA,
    source,
    data: response.data,
    included: response.included || [],
    options,
    lastFetch: new Date(),
    isFetching: false,
  };
};

const fetchData = (
  source,
  sourceKey = '',
  filter = '',
  labelPath,
  valuePath,
  stringify,
  includes,
  filterUrl,
  override,
) => {
  return (dispatch) => {
    const shouldFetchData = dispatch(shouldFetch(source + sourceKey, override));
    if (shouldFetchData) {
      let endpoint = source;

      // Appending a URL to the end of Endpoint
      if (filterUrl) {
        endpoint += filterUrl;
      }

      // Get all data
      if (!endpoint.includes('page[limit]')) {
        const joinChar = endpoint.includes('?') ? '&' : '?';
        endpoint = `${endpoint + joinChar}page[limit]=0`;
      }

      dispatch(isFetching(source, true));
      return Axios.get(endpoint)
        .then((response) => {
          const options = response.data.data.map((r) => {
            const optionValue = valuePath ? r.valuePath : r;
            return {
              label: r.labelPath ?? r.attributes.name,
              value: stringify ? JSON.stringify(optionValue) : optionValue,
            };
          });
          dispatch(isFetching(source, false));
          return dispatch(setData(source + sourceKey, response.data, options));
        })
        .catch((error) => {
          dispatch(isFetching(source, false));
          console.error(error);
        });
    }
  };
};

const shouldFetch = (key, override = false) => {
  return (dispatch, getState) => {
    if (override) {
      return true;
    }
    const lastFetch = getState().selects?.[key]?.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const now = new Date();
    return isAfter(subMinutes(now, 10), lastFetch);
  };
};

const actions = {
  fetchData,
  setDisplayOptions,
  setData,
};

export default actions;
