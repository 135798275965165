import React, { useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/lib/Async';
import Axios from 'axios/index';
import getByStringPath from '../../../helpers/getByStringPath';

const { CancelToken } = Axios;

const SelectMenuAsync = ({
  id,
  input,
  meta,
  dispatch,
  placeholder,
  showError,
  errorMessage,
  onChangeCallback,
  url,
  filters,
  preSelectedOptionValue,
  preSelectedOptionLabel,
  filterName = 'name',
  sort,
  labelPath = 'attributes.name',
  optionValuePath,
  includes,
  ...props
}) => {
  const getOptionLabel = (option) => {
    return labelPath ? getByStringPath(option, labelPath) ?? '' : '';
  };

  const getOptionValue = (option) => {
    return optionValuePath ? getByStringPath(option, optionValuePath) ?? option : option;
  };

  const compareOptions = (optionA, optionB) => {
    if (optionValuePath) {
      return getOptionValue(optionA) === getOptionValue(optionB);
    }
    return optionA.id === optionB.id;
  };

  const [options, setOptions] = useState(
    preSelectedOptionValue
      ? [
          {
            label: getOptionLabel(preSelectedOptionLabel ?? preSelectedOptionValue),
            value: preSelectedOptionValue,
          },
        ]
      : null,
  );
  const [selectedOption, setSelectedOption] = useState();
  const [cancelTokens, setCancelTokens] = useState({});

  const handleOnBlur = () => {
    if (input && input.onBlur) {
      input.onBlur(input.value);
    }
  };

  const handleOnChange = (option) => {
    if (option) {
      setSelectedOption(option.value);
      if (input) {
        input.onChange(option.value);
      }
      if (onChangeCallback) {
        onChangeCallback(option);
      }
    } else {
      input ? input.onChange(null) : null;
      if (onChangeCallback) {
        onChangeCallback(null);
      }
    }
  };

  const getSuggestions = (
    searchTerm,
    queryUrl,
    id,
    dispatch,
    filterName,
    sort,
    labelKeys,
    includes = '',
  ) => {
    let token = cancelTokens[id];

    if (token) {
      token();
    }

    const requestCancelToken = new CancelToken((c) => {
      token = c;
    });
    setCancelTokens({
      ...cancelTokens,
      [id]: token,
    });

    let searchUrl = queryUrl;
    const joinChar = (url) => {
      return url.includes('?') ? '&' : '?';
    };

    searchUrl += includes;
    if (filterName && searchTerm) {
      searchUrl += `${joinChar(searchUrl)}&filter[${filterName}]=${searchTerm}`;
    }
    return Axios.get(searchUrl, { cancelToken: requestCancelToken })
      .then((response) => {
        if (!response) {
          return null;
        }
        const newOptions = response.data.data.map((source) => {
          return {
            label: getOptionLabel(source),
            value: getOptionValue(source),
          };
        });
        if (preSelectedOptionValue) {
          newOptions.push({
            label: getOptionLabel(preSelectedOptionLabel ?? preSelectedOptionValue),
            value: getOptionValue(preSelectedOptionValue),
          });
        }
        setOptions(newOptions);
        return newOptions;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const getUrl = () => {
    const joinChar = (url) => {
      return url.includes('?') ? '&' : '?';
    };

    const uri = filters
      ? filters.reduce((uri, filter) => {
          const filterName = filter.type;
          const filterValue = filter.match;
          uri += `${joinChar(uri)}filter[${filterName}]=${filterValue}`;
          return uri;
        }, url)
      : url;
    return uri + (sort ? `${joinChar(uri)}sort=${sort}` : '');
  };

  const getDefaultValue = () => {
    if (input) {
      if (selectedOption) {
        const alreadyExists = options.find((opt) =>
          compareOptions(opt.value, selectedOption),
        );
        if (!alreadyExists) {
          options.push({
            value: selectedOption,
            label: getOptionLabel(selectedOption),
          });
        }
      }

      const defaultOption =
        getOptionValue(input.value) && options
          ? options.find((option) => compareOptions(option.value, input.value))
          : null;

      return defaultOption || null;
    }
    return undefined;
  };

  const error = meta && meta.error;
  const touched = meta && meta.touched;
  const showErrorMsg = showError || (touched && meta);
  const errorMsg = errorMessage || error;
  return (
    <div className="select-menu">
      <AsyncSelect
        defaultOptions
        loadOptions={(inputValue) =>
          getSuggestions(
            inputValue,
            getUrl(),
            id,
            dispatch,
            filterName,
            sort,
            labelPath,
            includes,
          )
        }
        onBlur={handleOnBlur}
        onChange={(option) => handleOnChange(option)}
        placeholder={placeholder}
        value={getDefaultValue()}
        {...props}
      />
      {showErrorMsg && errorMsg && <span className="input-error">{errorMsg}</span>}
    </div>
  );
};

export default connect()(SelectMenuAsync);
