import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AppContainer from './modules/layout/containers/App';
import { toastr } from 'react-redux-toastr';
import { hasPermission } from './modules/auth/redux/authActions';

// Public
import HomepageContainer from './modules/brochure/containers/Homepage';
import PasswordReminderContainer from './modules/auth/containers/PasswordReminderContainer';
import ResetPasswordContainer from './modules/auth/containers/ResetPasswordContainer';

// Private
import DashboardContainer from './modules/dashboard/containers/Dashboard';
import SetupContainer from './modules/screening/containers/SetupContainer';
import ScreeningContainer from './modules/screening/containers/ScreeningContainer';
import ViewScreeningContainer from './modules/screening/containers/ViewScreeningContainer';
import RoleNewContainer from './modules/role/containers/RoleNewContainer';
import RoleShowContainer from './modules/role/containers/RoleShowContainer';
import RoleEditContainer from './modules/role/containers/RoleEditContainer';
import RoleListContainer from './modules/role/containers/RoleListContainer';
import OrganisationNewContainer from './modules/organisation/containers/OrganisationNewContainer';
import OrganisationShowContainer from './modules/organisation/containers/OrganisationShowContainer';
import OrganisationEditContainer from './modules/organisation/containers/OrganisationEditContainer';
import OrganisationListContainer from './modules/organisation/containers/OrganisationListContainer';
import UserNewContainer from './modules/user/containers/UserNewContainer';
import UserShowContainer from './modules/user/containers/UserShowContainer';
import UserEditContainer from './modules/user/containers/UserEditContainer';
import UserListContainer from './modules/user/containers/UserListContainer';

import MedicalQuestionNewContainer from './modules/library/containers/MedicalQuestionNewContainer';
import MedicalQuestionShowContainer from './modules/library/containers/MedicalQuestionShowContainer';
import MedicalQuestionEditContainer from './modules/library/containers/MedicalQuestionEditContainer';
import MedicalQuestionListContainer from './modules/library/containers/MedicalQuestionListContainer';
import MedicalAnswerSetNewContainer from './modules/library/containers/MedicalAnswerSetNewContainer';
import MedicalAnswerSetShowContainer from './modules/library/containers/MedicalAnswerSetShowContainer';
import MedicalAnswerSetEditContainer from './modules/library/containers/MedicalAnswerSetEditContainer';
import MedicalAnswerSetListContainer from './modules/library/containers/MedicalAnswerSetListContainer';
import MedicalQuestionSetNewContainer from './modules/library/containers/MedicalQuestionSetNewContainer';
import MedicalQuestionSetShowContainer from './modules/library/containers/MedicalQuestionSetShowContainer';
import MedicalQuestionSetEditContainer from './modules/library/containers/MedicalQuestionSetEditContainer';
import MedicalQuestionSetListContainer from './modules/library/containers/MedicalQuestionSetListContainer';
import MedicalConditionNewContainer from './modules/library/containers/MedicalConditionNewContainer';
import MedicalConditionShowContainer from './modules/library/containers/MedicalConditionShowContainer';
import MedicalConditionEditContainer from './modules/library/containers/MedicalConditionEditContainer';
import MedicalConditionListContainer from './modules/library/containers/MedicalConditionListContainer';
import StatsContainer from './modules/library/containers/StatsContainer';

import VersionListContainer from './modules/library/containers/VersionListContainer';
import VersionShowContainer from './modules/library/containers/VersionShowContainer';
import VersionEditContainer from './modules/library/containers/VersionEditContainer';

const PrivateRoute = ({ path, children, store, permission, exact }) => {
  const { authenticated } = store.getState().auth;
  const userHasPermission = !!permission ? hasPermission(permission) : true;
  return (
    <Route
      path={path}
      exact={exact}
      store={store}
      render={({ staticContext, location }) => {
        if (authenticated) {
          if (userHasPermission) {
            return <div key={location.pathname}>{children}</div>;
          }
          toastr.error("You don't have permission to perform the requested action");
          if (staticContext) {
            return <Redirect to="/" />;
          }
          return <Redirect to="/dashboard" />;
        }
        if (staticContext) {
          return <Redirect to="/" />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
};

const RedirectIfAuth = ({ path, exact, children, store }) => {
  const { authenticated } = store.getState().auth;

  return (
    <Route
      path={path}
      exact={exact}
      store={store}
      render={({ staticContext }) => {
        if (!authenticated) {
          return children;
        }
        if (staticContext) {
          return <Redirect to="/dashboard" />;
        }
        return <Redirect to="/dashboard" />;
      }}
    />
  );
};

// eslint-disable-next-line import/prefer-default-export
export const routes = (store) => {
  return (
    <AppContainer>
      <Switch>
        <RedirectIfAuth exact path="/password/remind" store={store}>
          <PasswordReminderContainer />
        </RedirectIfAuth>
        <RedirectIfAuth path="/password/reset/:token" store={store}>
          <ResetPasswordContainer />
        </RedirectIfAuth>
        <RedirectIfAuth path="/" exact store={store}>
          <HomepageContainer store={store} />
        </RedirectIfAuth>
        <RedirectIfAuth path="/login" exact store={store}>
          <HomepageContainer store={store} />
        </RedirectIfAuth>

        {/* Private Routes */}
        <PrivateRoute path="/dashboard" store={store}>
          <DashboardContainer store={store} />
        </PrivateRoute>

        {/* Screening Routes */}
        <PrivateRoute path="/setup/:previousId" store={store}>
          <SetupContainer />
        </PrivateRoute>

        <PrivateRoute path="/setup" store={store}>
          <SetupContainer />
        </PrivateRoute>

        <PrivateRoute path="/screening/:screeningId" store={store}>
          <ViewScreeningContainer />
        </PrivateRoute>

        <PrivateRoute path="/screening" store={store}>
          <ScreeningContainer />
        </PrivateRoute>

        {/* Admin - Role Routes */}
        <PrivateRoute path="/admin/roles/new" permission="role.create" store={store}>
          <RoleNewContainer />
        </PrivateRoute>
        <PrivateRoute exact path="/admin/roles/:id" permission="role.view" store={store}>
          <RoleShowContainer />
        </PrivateRoute>
        <PrivateRoute path="/admin/roles/:id/edit" permission="role.edit" store={store}>
          <RoleEditContainer />
        </PrivateRoute>
        <PrivateRoute exact path="/admin/roles" permission="role.view" store={store}>
          <RoleListContainer />
        </PrivateRoute>

        {/* Organisation Routes */}
        <PrivateRoute
          path="/admin/organisations/new"
          permission="organisation.create"
          store={store}
        >
          <OrganisationNewContainer />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/organisations/:id"
          permission="organisation.view"
          store={store}
        >
          <OrganisationShowContainer />
        </PrivateRoute>
        <PrivateRoute
          path="/admin/organisations/:id/edit"
          permission="organisation.edit"
          store={store}
        >
          <OrganisationEditContainer />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/admin/organisations"
          permission="organisation.view"
          store={store}
        >
          <OrganisationListContainer />
        </PrivateRoute>

        {/* User Routes */}
        <PrivateRoute path="/admin/users/new" permission="user.create" store={store}>
          <UserNewContainer />
        </PrivateRoute>
        <PrivateRoute exact path="/admin/users/:id" permission="user.view" store={store}>
          <UserShowContainer />
        </PrivateRoute>
        <PrivateRoute path="/admin/users/:id/edit" permission="user.edit" store={store}>
          <UserEditContainer />
        </PrivateRoute>
        <PrivateRoute exact path="/admin/users" permission="user.view" store={store}>
          <UserListContainer />
        </PrivateRoute>

        {/* Admin - Stats */}
        <PrivateRoute
          exact
          path="/admin/reports"
          permission="screening.stats"
          store={store}
        >
          <StatsContainer />
        </PrivateRoute>

        {/* MedicalQuestion Routes */}
        <PrivateRoute
          path="/library/questions/new"
          permission="medical_library.modify"
          store={store}
        >
          <MedicalQuestionNewContainer />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/library/questions/:id"
          permission="medical_library.view"
          store={store}
        >
          <MedicalQuestionShowContainer />
        </PrivateRoute>
        <PrivateRoute
          path="/library/questions/:id/edit"
          permission="medical_library.modify"
          store={store}
        >
          <MedicalQuestionEditContainer />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/library/questions"
          permission="medical_library.view"
          store={store}
        >
          <MedicalQuestionListContainer />
        </PrivateRoute>

        {/* MedicalAnswerSet Routes */}
        <PrivateRoute
          path="/library/answer-sets/new"
          permission="medical_library.modify"
          store={store}
        >
          <MedicalAnswerSetNewContainer />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/library/answer-sets/:id"
          permission="medical_library.view"
          store={store}
        >
          <MedicalAnswerSetShowContainer />
        </PrivateRoute>
        <PrivateRoute
          path="/library/answer-sets/:id/edit"
          permission="medical_library.modify"
          store={store}
        >
          <MedicalAnswerSetEditContainer />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/library/answer-sets"
          permission="medical_library.view"
          store={store}
        >
          <MedicalAnswerSetListContainer />
        </PrivateRoute>

        {/* MedicalQuestionSet Routes */}
        <PrivateRoute
          path="/library/question-sets/new"
          permission="medical_library.modify"
          store={store}
        >
          <MedicalQuestionSetNewContainer />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/library/question-sets/:id"
          permission="medical_library.view"
          store={store}
        >
          <MedicalQuestionSetShowContainer />
        </PrivateRoute>
        <PrivateRoute
          path="/library/question-sets/:id/edit"
          permission="medical_library.modify"
          store={store}
        >
          <MedicalQuestionSetEditContainer />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/library/question-sets"
          permission="medical_library.view"
          store={store}
        >
          <MedicalQuestionSetListContainer />
        </PrivateRoute>

        {/* MedicalCondition Routes */}
        <PrivateRoute
          path="/library/conditions/new"
          permission="medical_library.modify"
          store={store}
        >
          <MedicalConditionNewContainer />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/library/conditions/:id"
          permission="medical_library.view"
          store={store}
        >
          <MedicalConditionShowContainer />
        </PrivateRoute>
        <PrivateRoute
          path="/library/conditions/:id/edit"
          permission="medical_library.modify"
          store={store}
        >
          <MedicalConditionEditContainer />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/library/conditions"
          permission="medical_library.view"
          store={store}
        >
          <MedicalConditionListContainer />
        </PrivateRoute>

        {/* MedicalCondition Routes */}
        <PrivateRoute
          exact
          path="/library/versions/:id"
          permission="medical_library.version"
          store={store}
        >
          <VersionShowContainer />
        </PrivateRoute>
        <PrivateRoute
          path="/library/versions/:id/edit"
          permission="medical_library.version"
          store={store}
        >
          <VersionEditContainer />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/library/versions"
          permission="medical_library.version"
          store={store}
        >
          <VersionListContainer />
        </PrivateRoute>
      </Switch>
    </AppContainer>
  );
};
