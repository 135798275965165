export const SET_ACTIVE_TAB = 'cega/tabs/SET_ACTIVE_TAB';

const initialState = {
  activeTabs: {
    screeningTabs: 1,
  },
};

export default function reducer(state = initialState, action = {}) {
  if (action.type === SET_ACTIVE_TAB) {
    const { activeTabs } = state;
    activeTabs[action.name] = action.number;
    return {
      ...state,
      activeTabs,
    };
  }
  return state;
}

const setActiveTab = (name, number) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_TAB,
      name,
      number,
    });
  };
};

const resetTabs = (name) => {
  return setActiveTab(name, 1);
};

export const actions = {
  setActiveTab,
  resetTabs,
};
