import React from 'react';
import { goBack, push } from 'connected-react-router';
import { connect } from 'react-redux';
import Button from '../Button/Button';

const BackButton = ({ dispatch, to, key }) => {
  const handleBackButton = () => {
    if (to) {
      dispatch(push(to));
    } else {
      dispatch(goBack());
    }
  };

  return (
    <Button
      key={key ?? 'back'}
      bsStyle="primary"
      label="Back"
      handleClick={handleBackButton}
      leftIcon="previous"
    />
  );
};

export default connect(null, null)(BackButton);
