export const user = {
  data: {
    type: 'user',
    attributes: {
      first_name: '',
      last_name: '',
      email: '',
    },
    relationships: {
      organisations: {
        data: [],
      },
      roles: {
        data: [],
      },
    },
  },
};

export const userStatuses = {
  pending: 'pending',
  active: 'active',
  inactive: 'inactive',
};

export default user;
