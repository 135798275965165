import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import MedicalConditionForm from '../components/MedicalConditionForm/MedicalConditionForm';
import conditionSchema from '../../../schemas/medicalCondition';
import actions from '../redux/medicalConditionActions';

const MedicalConditionNewContainer = ({ createMedicalCondition, goTo }) => {
  const saveCondition = (values) => {
    return Promise.resolve(
      createMedicalCondition(values).then((resp) => {
        goTo(`/library/conditions/${resp.data.id}`);
      }),
    );
  };

  return (
    <div className="container">
      <div className="content-block">
        <MedicalConditionForm resource={conditionSchema} saveCondition={saveCondition} />
      </div>
    </div>
  );
};

export default connect(null, {
  createMedicalCondition: actions.createMedicalCondition,
  goTo: push,
})(MedicalConditionNewContainer);
