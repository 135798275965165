import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from '../redux/organisationActions';
import { Loader } from '../../../common/components';
import OrganisationDetails from '../components/OrganisationDetails/OrganisationDetails';

const OrganisationShowContainer = ({ getOrganisation }) => {
  const { id } = useParams();
  useEffect(() => {
    getOrganisation(id);
  }, [getOrganisation, id]);

  const resource = useSelector((state) => state.organisation.resources?.[id]);

  return (
    <div className="container">
      <div className="content-block">
        {resource ? <OrganisationDetails resource={resource} /> : <Loader />}
      </div>
    </div>
  );
};

export default connect(null, {
  getOrganisation: actions.getOrganisation,
})(OrganisationShowContainer);
