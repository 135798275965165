import React, { useState } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { Button } from '../../../../common/components';

const ViewScreening = () => {
  const [sessionId, setSessionId] = useState('');

  return (
    <div className="content-block header-block">
      <h2>View Screening</h2>
      <hr />
      <InputGroup>
        <FormControl type="text" onChange={(event) => setSessionId(event.target.value)} />
        <InputGroup.Append>
          <Button variant="primary" size="md" block to={`/screening/${sessionId}`}>
            View
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
};

export default ViewScreening;
