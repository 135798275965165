import React from 'react';
import VersionList from '../components/VersionList/VersionList';

const VersionListContainer = () => {
  return (
    <div className="container">
      <div className="content-block">
        <h1>Versions</h1>
        <VersionList />
      </div>
    </div>
  );
};

export default VersionListContainer;
