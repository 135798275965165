import Axios from 'axios';
import * as dataTableActions from './dataTableReducer';
import debounce from '../../../../helpers/debounce';

const DEBOUNCE = 300;

const { CancelToken } = Axios;
let cancel;

const clearData = (name) => {
  return {
    type: dataTableActions.CLEAR_DATA,
    table: name,
  };
};

const initTable = (name, source, perPage = 10) => {
  return {
    type: dataTableActions.INIT_TABLE,
    table: name,
    perPage,
    source,
  };
};

const setSource = (name, source) => {
  return {
    type: dataTableActions.SET_SOURCE,
    table: name,
    source,
  };
};

const setData = (name, data, source, currentPage = 1) => {
  return {
    type: dataTableActions.SET_DATA,
    table: name,
    currentPage,
    source,
    data,
  };
};

const setPreloadedData = (name, data, source = '', currentPage = 1) => {
  return {
    type: dataTableActions.SET_DATA,
    table: name,
    currentPage,
    source,
    data,
  };
};

const getFilter = (state, name, type) => {
  const filter =
    state.tables[name] && state.tables[name].filters[type]
      ? state.tables[name].filters[type]
      : '';
  return filter || '';
};

const getFilterValue = (value) => {
  if (value === false) {
    return 'false';
  }

  if (value === true) {
    return 'true';
  }
  return value;
};

const setFilters = (name, type, value) => {
  const filterValue = getFilterValue(value);

  return {
    type: dataTableActions.SET_FILTERS,
    table: name,
    filterType: type,
    value: filterValue,
  };
};

const resetFilters = (name) => {
  return (dispatch) => {
    dispatch({
      type: dataTableActions.RESET_FILTERS,
      table: name,
    });
  };
};

const beginFetch = (name) => {
  return {
    type: dataTableActions.FETCH_DATA,
    table: name,
  };
};

const fetchData = (name, customFilter, dataFetchedCallback) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(beginFetch(name));
    dispatch(clearData(name));

    const dataTable = state.dataTables.tables[name];
    const { source } = dataTable;

    if (!source) {
      return null;
    }

    const filterKeys = dataTable.filters ? Object.keys(dataTable.filters) : [];
    const filterString = filterKeys
      .map((key) => `${key}=${dataTable.filters[key]}`)
      .join('&');
    const join = source.includes('?') ? '&' : '?';

    // add filters to the source url

    const url = filterString ? `${source}${join}${filterString}` : source;

    const doPagination =
      dataTable.filters['page[offset]'] && dataTable.filters['page[limit]'];

    const currentPage = doPagination
      ? 1 +
        parseInt(dataTable.filters['page[offset]'], 10) /
          parseInt(dataTable.filters['page[limit]'], 10)
      : 1;

    const endpoint = customFilter ? url + customFilter : url;

    return Axios.get(endpoint, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    }).then((response) => {
      if (dataFetchedCallback) {
        dataFetchedCallback();
      }
      return dispatch(setData(name, response.data, source, currentPage));
    });
  };
};

const debounced = debounce((dispatch, name) => dispatch(fetchData(name)), DEBOUNCE);

const fetchDataDebounced = (name) => (dispatch) => {
  return debounced(dispatch, name);
};

const removeFilter = (name, type) => {
  return (dispatch) => {
    dispatch({
      type: dataTableActions.REMOVE_FILTER,
      table: name,
      filterType: type,
    });

    dispatch(fetchDataDebounced(name));
  };
};

const applyFilter = (name, type, value) => {
  if (!value) {
    return removeFilter(name, type);
  }

  return (dispatch, getState) => {
    const state = getState().dataTables;
    if (getFilter(state, name, type) !== value) {
      const promises = [];

      promises.push(dispatch(setFilters(name, type, value)));

      const offsetFilter = 'page[offset]';
      if (type !== offsetFilter && getFilter(state, name, offsetFilter)) {
        promises.push(dispatch(setFilters(name, offsetFilter, 0)));
      }

      Promise.all(promises).then(() => dispatch(fetchDataDebounced(name)));
    }
  };
};

const refreshData = (name) => {
  return (dispatch) => {
    dispatch(fetchData(name));
  };
};

const actions = {
  clearData,
  initTable,
  setSource,
  setData,
  setPreloadedData,
  removeFilter,
  getFilter,
  setFilters,
  applyFilter,
  resetFilters,
  beginFetch,
  fetchData,
  refreshData,
};

export default actions;
