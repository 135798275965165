import React from 'react';
import { Field } from 'redux-form';
import { InputField, SelectMenuAsyncRow } from '../../../../common/components';

const Answer = ({ index, input, answer, preloadedForwardConditions }) => (
  <tr key={index}>
    <td>{answer.text}</td>
    <td>
      <InputField name={`${input.name}.severityScore`} type="number" placeholder="0.00" />
    </td>
    <td>
      <InputField name={`${input.name}.nextQuestionIndex`} type="number" />
    </td>
    <td>
      <SelectMenuAsyncRow
        name={`${input.name}.forwardConditionId`}
        fieldSizes={[12, 12, 12, 12, 12]}
        url="library/conditions"
        optionValuePath="id"
        preSelectedOptionValue={answer.forwardConditionId ?? ''}
        preSelectedOptionLabel={
          preloadedForwardConditions[answer.forwardConditionId] ?? ''
        }
      />
    </td>
  </tr>
);

const QuestionAnswersSection = ({ fields, answers, preloadedForwardConditions }) => {
  return (
    <tbody>
      {fields.length > 0 ? (
        fields.map((answer, index) => (
          <Field
            key={index}
            index={index}
            name={answer}
            answer={answers[index]}
            component={Answer}
            preloadedForwardConditions={preloadedForwardConditions}
          />
        ))
      ) : (
        <tr>
          <td colSpan={4}>No answers to configure</td>
        </tr>
      )}
    </tbody>
  );
};

export default QuestionAnswersSection;
