import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import * as medicalConditionActions from './medicalConditionReducer';
import { push } from 'connected-react-router';

const defaultIncludes = [
  'questions',
  'questions.question',
  'questions.answerSet',
  'questions.answers',
  'questions.answers.forwardCondition',
  'children',
  'parent',
  'questionSet',
  'childAssociatedEvents',
  'parentAssociatedEvents',
];

const isSubmitting = (status) => {
  return {
    type: medicalConditionActions.IS_SUBMITTING,
    status,
  };
};

const getMedicalCondition = (id, includes = defaultIncludes) => {
  return (dispatch) => {
    const endpoint = `library/conditions/${id}${queryString(includes)}`;

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: medicalConditionActions.SET_CONDITION,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        dispatch(push('/dashboard'));
        handleErrorResponse(error, 'Unable to load condition');
      });
  };
};

const createMedicalCondition = (resource) => {
  return () => {
    return Axios.post('/library/conditions', resource)
      .then((response) => {
        toastr.success('Success', 'Condition has been created successfully');
        return response.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error creating the condition');
      });
  };
};

const updateMedicalCondition = (id, resource, includes = defaultIncludes) => {
  return () => {
    const endpoint = `/library/conditions/${id}${queryString(includes)}`;

    return Axios.patch(endpoint, resource)
      .then((response) => {
        toastr.success('Success', 'Condition has been updated successfully');
        return response.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error updating the condition');
      });
  };
};

const actions = {
  isSubmitting,
  getMedicalCondition,
  createMedicalCondition,
  updateMedicalCondition,
};
export default actions;
