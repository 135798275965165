import React from 'react';
import isEmpty from '../../../../helpers/isEmpty';
import displayName from '../../../../helpers/displayName';
import { Card, Table } from 'react-bootstrap';
import './VersionComparison.scss';

const VersionComparison = ({ comparison, noChanges }) => {
  if (!comparison) {
    return null;
  }

  const parseAttributeValue = (value) => {
    if (value === false) {
      return 'FALSE';
    }

    if (value === true) {
      return 'TRUE';
    }

    if (value === null) {
      return '';
    }

    return value;
  };

  const renderChangeSet = (data, type) => {
    const skipAttributes = ['created_at', 'updated_at'];

    const modified = data.modified ?? [];

    const attributes = data.after ? Object.keys(data.after) : Object.keys(data.before);

    let containsModification = 'new' === type || 'deleted' === type;
    attributes.forEach((attribute) => {
      if (modified.indexOf(attribute) > -1 && !skipAttributes.includes(attribute)) {
        containsModification = true;
      }
    });
    if (!containsModification) {
      return null;
    }

    return (
      <div>
        <Table className={`changeset ${type}`}>
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Before</th>
              <th>After</th>
            </tr>
          </thead>
          <tbody>
            {attributes.map((attribute, key) => {
              if (
                skipAttributes.includes(attribute) ||
                (data.before &&
                  data.after &&
                  data.before[attribute] === data.after[attribute] &&
                  'name' !== attribute &&
                  'id' !== attribute)
              ) {
                return null;
              }

              return (
                <tr
                  key={key}
                  className={modified.indexOf(attribute) > -1 ? 'changed' : ''}
                >
                  <td>{attribute}</td>
                  <td className="before">
                    {data.before ? parseAttributeValue(data.before[attribute]) : ''}
                  </td>
                  <td className="after">
                    {data.after ? parseAttributeValue(data.after[attribute]) : ''}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderCollection = (data, type, name) => {
    if (isEmpty(data)) {
      return null;
    }

    return (
      <Card className="permissions-container">
        <Card.Title className="permissions-title">{name}</Card.Title>
        <Card.Body className="permissions-body">
          {Object.keys(data).map((key) => {
            const entity = data[key];
            return (
              <div key={key}>
                <h4>{displayName(key.split('\\').pop())}:</h4>
                {Object.keys(entity).map((key2) => {
                  return <div key={key2}>{renderChangeSet(entity[key2], type)}</div>;
                })}
              </div>
            );
          })}
        </Card.Body>
      </Card>
    );
  };

  if (noChanges) {
    return (
      <div>
        <p>There are no differences between this and the previous versions.</p>
      </div>
    );
  }

  return (
    <>
      {renderCollection(comparison.new, 'new', 'New')}
      {renderCollection(comparison.deleted, 'deleted', 'Deleted')}
      {renderCollection(comparison.changed, 'changed', 'Changed')}
    </>
  );
};

export default VersionComparison;
