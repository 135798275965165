import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { reduxForm, getFormValues, FieldArray } from 'redux-form';
import { Form } from 'react-bootstrap';
import { compose } from 'redux';
import actions from '../../redux/statsActions';
import { subYears } from 'date-fns';
import { required } from 'redux-form-validators';
import {
  Button,
  ButtonBar,
  FullRow,
  SelectMenuAsyncRow,
  SelectReduxRow,
  YearDatePickerRow,
} from '../../../../common/components';

const FORM_NAME = 'statsForm';

const LABEL_SIZES = [4, 4, 3, 3, 3];
const FIELD_SIZES = [8, 8, 9, 9, 9];

const StatsForm = ({ handleSubmit, statsReport }) => {
  const isGenerating = useSelector((state) => state.stats.isSubmitting);

  const handleStats = (values) => {
    statsReport(
      values?.meta?.start_date,
      values?.meta?.end_date,
      values?.meta?.report,
      values?.meta?.client?.id,
    );
  };

  return (
    <>
      <Form className="stats-form" onSubmit={handleSubmit(handleStats)}>
        <YearDatePickerRow
          label="From Date"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          maxDate={new Date()}
          minDate={subYears(new Date(), 10)}
          validate={required({ msg: 'From date is required' })}
          name="meta.start_date"
          showTime={true}
        />
        <YearDatePickerRow
          label="To Date"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          maxDate={new Date()}
          minDate={subYears(new Date(), 10)}
          validate={required({ msg: 'To date is required' })}
          name="meta.end_date"
          showTime={true}
        />
        <SelectMenuAsyncRow
          name="meta.client"
          label="Client"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          url="oauth/clients"
          sort="name"
          isClearable
        />
        <SelectReduxRow
          label="Report"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          name="meta.report"
          options={[
            { value: 'stats', label: 'Stats' },
            { value: 'conditions', label: 'Condition Activity' },
          ]}
          validate={required({ msg: 'Report is required' })}
          showValidationError={true}
        />
        <FullRow>
          <ButtonBar>
            <Button
              id="login-button"
              type="submit"
              className="pull-right"
              variant="primary"
              isLoading={isGenerating}
              disabled={isGenerating}
            >
              Generate Report
            </Button>
          </ButtonBar>
        </FullRow>
      </Form>
    </>
  );
};

export default compose(
  connect(
    (state) => ({
      formValues: getFormValues(FORM_NAME)(state),
    }),
    {
      statsReport: actions.statsReport,
    },
  ),
  reduxForm({ form: FORM_NAME }),
)(StatsForm);
