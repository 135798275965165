import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import RoleForm from '../components/RoleForm/RoleForm';
import roleSchema from '../../../schemas/role';
import { Loader } from '../../../common/components';
import actions from '../redux/roleActions';

const RoleNewContainer = ({ getPermissions, createRole, goTo }) => {
  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  const saveRole = (values) => {
    return Promise.resolve(
      createRole(values).then((resp) => {
        goTo(`/admin/roles/${resp.data.id}`);
      }),
    );
  };

  const permissions = useSelector((state) => state.role.permissions);

  return (
    <div className="container">
      <div className="content-block">
        {permissions ? (
          <RoleForm resource={roleSchema} permissions={permissions} saveRole={saveRole} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default connect(null, {
  createRole: actions.createRole,
  getPermissions: actions.getPermissions,
  goTo: push,
})(RoleNewContainer);
