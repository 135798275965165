import React from 'react';
import { Field } from 'redux-form';
import { ButtonBar, Button, FullRow, InputRow } from '../../../../common/components';
import { FaTrash } from 'react-icons/fa';

const SYNONYM_LIMIT = 100;

const Synonym = ({ input, handleRemove }) => (
  <div className="removable-item">
    <div className="item-section">
      <InputRow fieldSize={12} name={`${input.name}.name`} />
    </div>
    <div className="remove-section">
      <a className="remove-btn" onClick={handleRemove}>
        <FaTrash />
      </a>
    </div>
  </div>
);

const SynonymsSection = ({ fields }) => {
  const handleAdd = () => {
    if (fields.length < SYNONYM_LIMIT) {
      fields.push({ id: null, name: '' });
    }
  };

  const handleRemove = (index) => {
    fields.remove(index);
  };

  return (
    <div>
      {fields.length <= 0 && <p>No synonyms defined</p>}
      {fields.map((synonym, index) => {
        return (
          <div key={index}>
            <Field
              name={synonym}
              component={Synonym}
              index={index}
              handleRemove={() => handleRemove(index)}
            />
          </div>
        );
      })}
      {fields.length < SYNONYM_LIMIT && (
        <FullRow>
          <ButtonBar>
            <Button label="Add Synonym" variant={'default'} handleClick={handleAdd} />
          </ButtonBar>
        </FullRow>
      )}
    </div>
  );
};

export default SynonymsSection;
