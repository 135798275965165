import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from '../redux/userActions';
import { Loader } from '../../../common/components';
import UserDetails from '../components/UserDetails/UserDetails';

const UserShowContainer = ({
  getUser,
  deactivateUser,
  reactivateUser,
  passwordReminder,
}) => {
  const { id } = useParams();
  useEffect(() => {
    getUser(id);
  }, [getUser, id]);

  const disableUser = () => {
    if (resource?.data?.id) {
      return Promise.resolve(
        deactivateUser(resource.data.id).then(() => {
          getUser(resource.data.id);
        }),
      );
    }
  };

  const enableUser = () => {
    if (resource?.data?.id) {
      return Promise.resolve(
        reactivateUser(resource.data.id).then(() => {
          getUser(resource.data.id);
        }),
      );
    }
  };

  const sendResetEmail = () => {
    if (resource?.data?.id) {
      passwordReminder(resource.data.id);
    }
  };

  const resource = useSelector((state) => state.user.resources?.[id]);

  return (
    <div className="container">
      <div className="content-block">
        {resource ? (
          <UserDetails
            resource={resource}
            disableUser={disableUser}
            enableUser={enableUser}
            sendResetEmail={sendResetEmail}
          />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default connect(null, {
  getUser: actions.getUser,
  deactivateUser: actions.deactivateUser,
  reactivateUser: actions.reactivateUser,
  passwordReminder: actions.passwordReminder,
})(UserShowContainer);
