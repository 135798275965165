import React from 'react';
import { BackButton, Button, FullRow, LabelRow } from '../../../../common/components';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';

const LABEL_SIZES = 2;
const FIELD_SIZES = 10;

const MedicalAnswerSetDetails = ({ resource, answers }) => {
  const resourceAttributes = resource.data.attributes;

  return (
    <>
      <h2 className="resource-name">{resourceAttributes.name}</h2>

      <LabelRow label="Name" labelSizes={LABEL_SIZES} fieldSizes={FIELD_SIZES}>
        {resourceAttributes.name}
      </LabelRow>

      <LabelRow
        label="Possible Answers"
        labelSizes={LABEL_SIZES}
        fieldSizes={FIELD_SIZES}
      >
        <ul className="unmarked-list">
          {answers.map((answer) => (
            <li key={answer.id}>
              {answer.attributes.answerIndex}. {answer.attributes.answer}
            </li>
          ))}
        </ul>
      </LabelRow>

      <FullRow>
        <InternalButtonBar
          buttons={[
            {
              permissions: ['medical_library.modify'],
              button: (
                <Button
                  key="edit-answer-set"
                  to={`/library/answer-sets/${resource.data.id}/edit`}
                  className="pull-right"
                  bsStyle="primary"
                  rightIcon="edit"
                  label="Edit Answer Set"
                />
              ),
            },
            {
              button: <BackButton key="back" to="/library/answer-sets" />,
            },
          ]}
        />
      </FullRow>
    </>
  );
};

export default MedicalAnswerSetDetails;
