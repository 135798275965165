export const LOGIN = 'cega/auth/LOGIN';
export const LOGIN_ERROR = 'cega/auth/LOGIN_ERROR';
export const LOGIN_SUCCESS = 'cega/auth/LOGIN_SUCCESS';
export const SET_USER = 'cega/auth/SET_USER';
export const LOGOUT = 'cega/auth/LOGOUT';
export const UNAUTHENTICATE = 'socrates-online/auth/UNAUTHENTICATE';

export const initialState = {
  accessToken: '',
  isWaiting: false,
  authenticated: false,
  user: {
    data: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isWaiting: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        authenticated: true,
        accessToken: action.token,
      };

    case SET_USER:
      return {
        ...state,
        user: action.user,
      };

    case UNAUTHENTICATE:
      return {
        ...state,
        authenticated: false,
      };

    case LOGOUT:
    case LOGIN_ERROR:
      return initialState;

    default:
      return state;
  }
}
