import { Field } from 'redux-form';
import React from 'react';
import SelectMenuMulti from './SelectMenuMulti';
import Row from '../Layout/Row';

const SelectMenuMultiRow = ({
  labelSizes,
  label,
  fieldSizes,
  fieldOffsets,
  ...props
}) => {
  return (
    <Row
      labelSizes={labelSizes}
      label={label}
      fieldSizes={fieldSizes}
      fieldOffsets={fieldOffsets}
    >
      <Field component={SelectMenuMulti} {...props} />
    </Row>
  );
};

export default SelectMenuMultiRow;
