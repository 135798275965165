import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import ViewScreening from '../components/ViewScreening/ViewScreening';
import QuickScreeningTest from '../components/QuickScreeningTest/QuickScreeningTest';

const DashboardContainer = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="container">
      <Row className="content-block header-block">
        <h1>{user?.data.attributes?.first_name ?? ''}'s Dashboard</h1>
      </Row>
      <Row>
        <Col xs={6}>
          <ViewScreening />
        </Col>
        <Col xs={6}>
          <QuickScreeningTest />
        </Col>
      </Row>
    </div>
  );
};

export default connect()(DashboardContainer);
