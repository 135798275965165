import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import * as medicalQuestionActions from './medicalQuestionReducer';
import { push } from 'connected-react-router';

const defaultIncludes = ['conditions'];

const isSubmitting = (status) => {
  return {
    type: medicalQuestionActions.IS_SUBMITTING,
    status,
  };
};

const getMedicalQuestion = (id, includes = defaultIncludes) => {
  return (dispatch) => {
    const endpoint = `library/questions/${id}${queryString(includes)}`;

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: medicalQuestionActions.SET_QUESTION,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        dispatch(push('/dashboard'));
        handleErrorResponse(error, 'Unable to load medical question');
      });
  };
};

const createMedicalQuestion = (resource) => {
  return () => {
    return Axios.post('/library/questions', resource)
      .then((response) => {
        toastr.success('Success', 'Question has been created successfully');
        return response.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error creating the question');
      });
  };
};

const updateMedicalQuestion = (id, resource, includes = defaultIncludes) => {
  return () => {
    const endpoint = `/library/questions/${id}${queryString(includes)}`;

    return Axios.patch(endpoint, resource)
      .then((response) => {
        toastr.success('Success', 'Question has been updated successfully');
        return response.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error updating the question');
      });
  };
};

const actions = {
  isSubmitting,
  getMedicalQuestion,
  createMedicalQuestion,
  updateMedicalQuestion,
};
export default actions;
