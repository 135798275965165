import 'core-js';
import 'regenerator-runtime/runtime';
import 'react-hot-loader';
import React from 'react';
import { render } from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { createBrowserHistory } from 'history';
import axios from 'axios';
import { IntlProvider } from 'react-intl';
import configureStore from './redux/configureStore';
import AuthInterceptor from './api/authInterceptor';
import { logoutTimer } from './api/timer';
import App from './app';

// Grab the state from a global injected into
// server-generated HTML
const initialState = window.__INITIAL_STATE__;
const apiUrl = window.__API_URL__;

axios.defaults.baseURL = apiUrl;

if (initialState.auth && initialState.auth.accessToken) {
  axios.defaults.headers.common.Authorization = `Bearer ${initialState.auth.accessToken}`;
}

const authInterceptor = new AuthInterceptor();
authInterceptor.registerInterceptor();

axios.interceptors.request.use((request) => {
  // Do not modify requests made to the local node server
  if (request.baseURL === '') {
    return request;
  }

  if (request.data && request.headers['Content-Type'] !== 'application/vnd.api+json') {
    request.headers['Content-Type'] = 'application/vnd.api+json';
  }

  return request;
});
const history = createBrowserHistory();
const store = configureStore(initialState, history);

logoutTimer(store);

render(
  <Provider store={store} context={ReactReduxContext}>
    <IntlProvider locale="en-GB">
      <App store={store} history={history} context={ReactReduxContext} />
    </IntlProvider>
  </Provider>,
  document.getElementById('app'),
);
