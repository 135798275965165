import React from 'react';
import { Field } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Icon,
  InputField,
  Button,
  FullRow,
  ButtonBar,
} from '../../../../common/components';
import { Row, Col } from 'react-bootstrap';
import uuid from 'uuid';

const getItemStyle = (isDragging, draggableStyle) => ({
  boxShadow: isDragging && '0 4px 28px rgba(0,0,0,0.3)',
  margin: '0 0 15px 0',
  borderRadius: '4px',
  ...draggableStyle,
});

const ANSWER_LIMIT = 100;
const LABEL_SIZES = [4, 4, 3, 3, 3];
const FIELD_SIZES = [8, 8, 9, 9, 9];

const Answer = ({ index, input, handleRemove }) => {
  return (
    <div className="dnd-item-container">
      <Row>
        <Col sm={1} className="dnd-item-icon">
          <Icon iconName="bars" />
        </Col>
        <Col sm={10}>
          <InputField name={`${input.name}.answer`} />
        </Col>
        <Col sm={1}>
          <div onClick={() => handleRemove(index)}>
            <Icon iconName="trash" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const AnswersSection = ({ fields, reorderAnswers }) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    reorderAnswers(fields.name, result.source.index, result.destination.index);
  };

  const handleAdd = () => {
    if (fields.length < ANSWER_LIMIT) {
      fields.push({ id: uuid.v4(), answer: '' });
    }
  };

  const handleRemove = (index) => {
    fields.remove(index);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map((answer, index) => {
                return (
                  <Draggable key={index} draggableId={`${index}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        <Field
                          key={index}
                          index={index}
                          name={answer}
                          component={Answer}
                          handleRemove={handleRemove}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {fields.length < ANSWER_LIMIT && (
        <FullRow>
          <ButtonBar>
            <Button label="Add Answer" variant={'default'} handleClick={handleAdd} />
          </ButtonBar>
        </FullRow>
      )}
    </>
  );
};

export default AnswersSection;
