import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { Form } from 'react-bootstrap';
import { compose } from 'redux';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import {
  InputRow,
  Button,
  FullRow,
  BackButton,
  SelectReduxRow,
} from '../../../../common/components';

const FORM_NAME = 'organisationForm';

const LABEL_SIZES = [4, 4, 3, 3, 3];
const FIELD_SIZES = [8, 8, 9, 9, 9];

const OrganisationForm = ({
  handleSubmit,
  saveOrganisation,
  formValues,
  submitting,
  resource,
}) => {
  const organisationName = formValues?.data?.attributes?.name
    ? formValues.data.attributes.name
    : 'New Organisation';

  const typesList = [
    {
      label: 'Owner',
      value: 'owner',
    },
    {
      label: 'Client',
      value: 'client',
    },
  ];

  return (
    <>
      <h2 className="resource-name">{organisationName}</h2>
      <Form className="organisation-form" onSubmit={handleSubmit(saveOrganisation)}>
        <InputRow
          name="data.attributes.name"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          label="Name"
        />
        <SelectReduxRow
          label="Type"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          name="data.attributes.type"
          options={typesList}
        />
        <FullRow>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['organisation.edit'],
                button: (
                  <Button
                    key="edit-organisation"
                    className="pull-right"
                    type="submit"
                    variant="primary"
                    isLoading={submitting}
                    label="Save Organisation"
                  />
                ),
              },
              {
                button: (
                  <BackButton
                    key="back"
                    to={
                      resource.data?.id
                        ? `/admin/organisations/${resource.data.id}`
                        : '/admin/organisations'
                    }
                  />
                ),
              },
            ]}
          />
        </FullRow>
      </Form>
    </>
  );
};

const getInitialValues = (resource) => () => resource;
export default compose(
  connect(
    (state, ownProps) => ({
      initialValues: getInitialValues(ownProps.resource)(state),
      formValues: getFormValues(FORM_NAME)(state),
    }),
    null,
  ),
  reduxForm({ form: FORM_NAME }),
)(OrganisationForm);
