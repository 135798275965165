import React from 'react';
import ReactToggle from 'react-toggle';

const Toggle = ({ input }) => (
  <>
    <ReactToggle
      {...input}
      checked={!!input.value}
      onChange={(event) => input.onChange(event.target.checked)}
      onBlur={(event) => input.onBlur(event.target.checked)}
      value={void 0}
    />
  </>
);

export default Toggle;
