import React from 'react';
import StatsForm from '../components/StatsForm/StatsForm';

const StatsContainer = () => {
  return (
    <div className="container">
      <div className="content-block">
        <h1>Reports</h1>
        <StatsForm />
      </div>
    </div>
  );
};

export default StatsContainer;
