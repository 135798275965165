import React from 'react';
import { Field } from 'redux-form';
import SelectRedux from './SelectRedux';
import Row from '../Layout/Row';

const SelectReduxRow = ({
  labelSizes,
  label,
  fieldSizes,
  fieldOffsets,
  name,
  ...props
}) => (
  <Row
    labelSizes={labelSizes}
    label={label}
    fieldSizes={fieldSizes}
    fieldOffsets={fieldOffsets}
  >
    <Field name={name} component={SelectRedux} {...props} />
  </Row>
);
export default SelectReduxRow;
