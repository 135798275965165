export const medicalAnswerSet = {
  data: {
    type: 'medical-answer-set',
    attributes: {
      name: '',
    },
    meta: {
      answers: [],
    },
  },
};

export default medicalAnswerSet;
