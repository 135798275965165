import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from '../redux/versionActions';
import { Loader } from '../../../common/components';
import VersionDetails from '../components/VersionDetails/VersionDetails';

const VersionShowContainer = ({ getVersion, getComparison }) => {
  const { id } = useParams();
  useEffect(() => {
    getVersion(id);
  }, [getVersion, id]);
  // //   // dispatch(versionActions.loadComparison('previous', versionId)),
  useEffect(() => {
    getComparison('previous', id);
  }, [getComparison, id]);

  const resource = useSelector((state) => state.version?.data);
  const comparison = useSelector((state) => state.version?.comparison);

  return (
    <div className="container">
      <div className="content-block">
        {resource && comparison ? (
          <VersionDetails resource={resource} comparison={comparison} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default connect(null, {
  getVersion: actions.getVersion,
  getComparison: actions.getComparison,
})(VersionShowContainer);
