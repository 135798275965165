import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button as BootstrapButton } from 'react-bootstrap';
import Icon from '../Icon/Icon';

const Button = ({
  variant,
  style,
  size,
  block,
  type,
  active,
  label,
  className,
  children,
  handleClick,
  href,
  rightIcon,
  leftIcon,
  isLoading,
  to,
  target,
  disabled,
}) => {
  const firstIcon = isLoading ? (
    <Icon iconName="spinner" className="spin" />
  ) : (
    <Icon iconName={leftIcon} />
  );
  const secondIcon = rightIcon ? <Icon iconName={rightIcon} /> : undefined;

  if (to) {
    return (
      <RouterLink to={to}>
        <BootstrapButton
          variant={variant}
          disabled={disabled}
          type={type}
          style={style}
          size={size || 'sm'}
          block={block}
          className={className}
          onClick={handleClick}
          href={href}
          active={active}
        >
          {firstIcon}
          <span>{children || label}</span>
          {secondIcon}
        </BootstrapButton>
      </RouterLink>
    );
  }
  return (
    <BootstrapButton
      variant={variant}
      disabled={disabled}
      type={type}
      style={style}
      size={size || 'sm'}
      block={block}
      className={className}
      onClick={handleClick}
      href={href}
      active={active}
      target={target}
    >
      {firstIcon}
      <span>{children || label}</span>
      {secondIcon}
    </BootstrapButton>
  );
};

export default Button;
