import React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import Icon from '../Icon/Icon';

const Input = ({
  input,
  meta,
  addOnBefore,
  addOnAfter,
  isCalc,
  currency,
  percent,
  type,
  placeholder,
  as,
  handleOnChange,
  handleOnBlur,
  lpignore,
  className,
  disabled,
}) => {
  const onChange = (event) => {
    if (handleOnChange) {
      handleOnChange(event.target.value);
    }
    if (input) {
      input.onChange(event.target.value);
    }
  };
  const onBlur = (value) => {
    if (handleOnBlur) {
      handleOnBlur(value);
    }
  };
  const currencyPrepend = <Icon iconName={isCalc ? 'spinner' : 'gbp'} />;
  const percentAppend = <Icon iconName={isCalc ? 'spinner' : 'percent'} />;
  const before = currency ? currencyPrepend : addOnBefore;
  const after = percent ? percentAppend : addOnAfter;
  const hasError = meta && meta.touched && meta.error;
  return (
    <>
      <InputGroup>
        {before && (
          <InputGroup.Prepend>
            <InputGroup.Text className={className}>{before}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <FormControl
          {...input}
          as={as}
          type={type}
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
          onBlur={onBlur}
          lpignore={lpignore}
          disabled={disabled}
        />
        {after && (
          <InputGroup.Append>
            <InputGroup.Text className={className}>{after}</InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
      {hasError && <span className="input-error">{meta.error}</span>}
    </>
  );
};

export default Input;
