import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import MedicalQuestionSetForm from '../components/MedicalQuestionSetForm/MedicalQuestionSetForm';
import questionSetSchema from '../../../schemas/medicalQuestionSet';
import actions from '../redux/medicalQuestionSetActions';

const MedicalQuestionSetNewContainer = ({ createMedicalQuestionSet, goTo }) => {
  const saveQuestionSet = (values) => {
    return Promise.resolve(
      createMedicalQuestionSet(values).then((resp) => {
        goTo(`/library/question-sets/${resp.data.id}`);
      }),
    );
  };

  return (
    <div className="container">
      <div className="content-block">
        <MedicalQuestionSetForm
          resource={questionSetSchema}
          saveQuestionSet={saveQuestionSet}
        />
      </div>
    </div>
  );
};

export default connect(null, {
  createMedicalQuestionSet: actions.createMedicalQuestionSet,
  goTo: push,
})(MedicalQuestionSetNewContainer);
