import React from 'react';
import ReactSelect from 'react-select';

const Select = ({ onChange, options, isClearable, isMulti, value, variant }) => {
  return (
    <ReactSelect
      onChange={onChange}
      options={options}
      isClearable={isClearable}
      isMulti={isMulti}
      value={value}
      variant={variant}
    />
  );
};

export default Select;
