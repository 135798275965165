import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, FieldArray } from 'redux-form';
import { Form } from 'react-bootstrap';
import { compose } from 'redux';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { InputRow, Button, FullRow, BackButton } from '../../../../common/components';
import getIncludedResource from '../../../../helpers/getIncludedResource';

const FORM_NAME = 'versionForm';

const LABEL_SIZES = [4, 4, 3, 3, 3];
const FIELD_SIZES = [8, 8, 9, 9, 9];

const VersionForm = ({
  handleSubmit,
  saveVersion,
  submitting,
  resource,
  formValues,
  array,
  change,
  dispatch,
}) => {
  const versionName = formValues?.data?.attributes?.version;

  return (
    <>
      <h2 className="resource-name">{versionName}</h2>
      <Form className="version-form" onSubmit={handleSubmit(saveVersion)}>
        <InputRow
          name="data.attributes.description"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          label="Description"
        />
        <FullRow>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['medical_library.version'],
                button: (
                  <Button
                    key="edit-version"
                    className="pull-right"
                    type="submit"
                    variant="primary"
                    isLoading={submitting}
                    label="Save Version"
                  />
                ),
              },
              {
                button: (
                  <BackButton
                    key="back"
                    to={
                      resource.data?.id
                        ? `/library/versions/${resource.data.id}`
                        : '/library/versions'
                    }
                  />
                ),
              },
            ]}
          />
        </FullRow>
      </Form>
    </>
  );
};

const getInitialValues = (resource) => () => resource;
export default compose(
  connect(
    (state, ownProps) => ({
      initialValues: getInitialValues(ownProps.resource)(state),
      formValues: getFormValues(FORM_NAME)(state),
    }),
    null,
  ),
  reduxForm({ form: FORM_NAME }),
)(VersionForm);
