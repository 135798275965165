import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Button, ButtonBar, SelectMenuAsyncRow } from '../../../../common/components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form, getFormValues, reduxForm } from 'redux-form';
import isEmpty from '../../../../helpers/isEmpty';

const FORM_NAME = 'AddQuestionForm';
const LABEL_SIZES = [3, 3, 3, 3, 3];
const FIELD_SIZES = [9, 9, 9, 9, 9];

const QuestionModal = ({
  show,
  onHide,
  handleAdd,
  handleSubmit,
  questionValues,
  dispatch,
  change,
  formValues,
}) => {
  useEffect(() => {
    dispatch(change('question', questionValues.question));
    dispatch(change('answerSet', questionValues.answerSet));
  }, [questionValues]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{isEmpty(questionValues) ? 'Add' : 'Edit'} Question</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(handleAdd)}>
          <SelectMenuAsyncRow
            name="question"
            label="Question"
            labelSizes={LABEL_SIZES}
            fieldSizes={FIELD_SIZES}
            url="library/questions"
            labelPath="attributes.question"
            filterName="question"
          />
          <SelectMenuAsyncRow
            name="answerSet"
            label="Answer Set"
            labelSizes={LABEL_SIZES}
            fieldSizes={FIELD_SIZES}
            url="library/answer-sets"
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonBar>
          {formValues?.question && formValues?.answerSet && (
            <Button
              type="submit"
              key="create"
              variant="primary"
              className="pull-right"
              handleClick={handleSubmit(handleAdd)}
              label="Add"
            />
          )}
          <Button
            key="cancel"
            type="button"
            variant="default"
            className="pull-right"
            handleClick={onHide}
            label="Cancel"
          />
        </ButtonBar>
      </Modal.Footer>
    </Modal>
  );
};

export default compose(
  connect(
    (state) => ({
      formValues: getFormValues(FORM_NAME)(state),
    }),
    {},
  ),
  reduxForm({ form: FORM_NAME }),
)(QuestionModal);
