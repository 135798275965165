import React from 'react';
import { DataTable } from '../../../../common/components';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import formatDateTime from '../../../../helpers/formatDateTime';
import { Form, Row, Col } from 'react-bootstrap';
import dataTableActions from '../../../../common/components/DataTable/redux/dataTableActions';

const MedicalAnswerSetList = ({ applyFilter, goTo }) => {
  const handleRowClick = (row) => {
    return goTo(`/library/answer-sets/${row.id}`);
  };

  const handleSearchUpdate = (event) => {
    applyFilter('answer-sets', 'filter[name]', event.target.value);
  };

  return (
    <>
      <Row className="bottom-margin">
        <Col sm={12}>
          <Form.Label column={false}>Search</Form.Label>
          <Form.Control onChange={handleSearchUpdate} />
        </Col>
      </Row>
      <DataTable
        source="/library/answer-sets"
        name="answer-sets"
        onRowSelect={handleRowClick}
        columns={[
          {
            dataField: 'id',
            hidden: true,
          },
          {
            dataField: 'name',
            text: 'Name',
          },
          {
            dataField: 'created_at',
            text: 'Created',
            formatter: formatDateTime,
          },
        ]}
      />
    </>
  );
};

export default connect(null, {
  applyFilter: dataTableActions.applyFilter,
  goTo: push,
})(MedicalAnswerSetList);
