import React from 'react';
import { FullRow, Button } from '../../../common/components';
import InternalButtonBar from '../../layout/components/InternalButtonBar/InternalButtonBar';
import MedicalConditionList from '../components/MedicalConditionList/MedicalConditionList';

const MedicalConditionListContainer = () => {
  return (
    <div className="container">
      <div className="content-block">
        <h1>Condition List</h1>
        <FullRow>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['medical_library.modify'],
                button: (
                  <Button
                    key="new-medical-condition"
                    to="/library/conditions/new"
                    className="pull-right"
                    variant="primary"
                    label="New Condition"
                  />
                ),
              },
            ]}
          />
        </FullRow>
        <MedicalConditionList />
      </div>
    </div>
  );
};

export default MedicalConditionListContainer;
