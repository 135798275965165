import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { reduxForm, getFormValues, FieldArray } from 'redux-form';
import { Form } from 'react-bootstrap';
import { compose } from 'redux';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { InputRow, Button, FullRow, BackButton } from '../../../../common/components';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import QuestionsSection from './QuestionsSection';
import actions from '../../redux/medicalAnswerSetActions';
import medicalQuestionDataToFormData from './medicalQuestionDataToFormData';

const FORM_NAME = 'medicalQuestionSetForm';

const LABEL_SIZES = [4, 4, 3, 3, 3];
const FIELD_SIZES = [8, 8, 9, 9, 9];

const MedicalQuestionSetForm = ({
  handleSubmit,
  saveQuestionSet,
  submitting,
  resource,
  formValues,
  array,
  dispatch,
  change,
  returnMedicalAnswerSetDetails,
}) => {
  const [preloadedForwardConditions, setPreloadedForwardConditions] = useState({});

  const questionSetName = formValues?.data?.attributes?.name
    ? formValues.data.attributes.name
    : 'New Question Set';

  const questions = resource
    ? getIncludedResource(resource.data, resource.included, 'questions')
    : [];

  useEffect(() => {
    if (Array.isArray(questions)) {
      const questionData = medicalQuestionDataToFormData(questions, resource.included);
      dispatch(change('data.meta.questions', questionData));

      const forwardConditions = {};
      questionData.forEach((question) => {
        if (question.answers && question.answers.length > 0) {
          question.answers.forEach((answer) => {
            if (answer.forwardConditionId) {
              const tempResource = {
                relationships: {
                  forward_condition: {
                    data: {
                      id: answer.forwardConditionId,
                      type: 'medical-condition',
                    },
                  },
                },
              };
              const includedCondition = getIncludedResource(
                tempResource,
                resource.included,
                'forward_condition',
              );
              if (includedCondition) {
                forwardConditions[includedCondition.data.id] =
                  includedCondition.data.attributes.name;
              }
            }
          });
        }
      });
      setPreloadedForwardConditions(forwardConditions);
    } else {
      dispatch(change('data.meta.questions', []));
    }
  }, []);

  const reorderQuestions = (path, startIndex, endIndex) => {
    array.move(path, startIndex, endIndex);
  };

  return (
    <>
      <h2 className="resource-name">{questionSetName}</h2>
      <Form className="question-set-form" onSubmit={handleSubmit(saveQuestionSet)}>
        <InputRow
          name="data.attributes.name"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          label="Name"
        />

        <h3>Questions</h3>
        <FieldArray
          name="data.meta.questions"
          component={QuestionsSection}
          reorderQuestions={reorderQuestions}
          returnMedicalAnswerSetDetails={returnMedicalAnswerSetDetails}
          questions={formValues?.data?.meta?.questions ?? []}
          preloadedForwardConditions={preloadedForwardConditions}
        />

        <FullRow>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['medical_library.modify'],
                button: (
                  <Button
                    key="edit-question-set"
                    className="pull-right"
                    type="submit"
                    variant="primary"
                    isLoading={submitting}
                    label="Save Question Set"
                  />
                ),
              },
              {
                button: (
                  <BackButton
                    key="back"
                    to={
                      resource.data?.id
                        ? `/library/question-sets/${resource.data.id}`
                        : '/library/question-sets'
                    }
                  />
                ),
              },
            ]}
          />
        </FullRow>
      </Form>
    </>
  );
};

const getInitialValues = (resource) => () => resource;
export default compose(
  connect(
    (state, ownProps) => ({
      initialValues: getInitialValues(ownProps.resource)(state),
      formValues: getFormValues(FORM_NAME)(state),
    }),
    {
      returnMedicalAnswerSetDetails: actions.returnMedicalAnswerSetDetails,
    },
  ),
  reduxForm({ form: FORM_NAME }),
)(MedicalQuestionSetForm);
