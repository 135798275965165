import React, { useEffect } from 'react';
import Select from 'react-select';
import { connect, useSelector } from 'react-redux';
import getByStringPath from '../../../helpers/getByStringPath';
import isEmpty from '../../../helpers/isEmpty';
import actions from './redux/SelectRedux';

const SelectMenuMulti = ({
  fetchData,
  setDisplayOptions,
  source,
  sourceKey = '',
  filter,
  labelPath,
  optionValuePath,
  includes,
  filterUrl,
  meta,
  showValidationError,
  errorMessage,
  placeholder,
  input,
  isObject,
  autoFilters,
  onChangeCallback,
  ...props
}) => {
  useEffect(() => {
    Promise.resolve(
      fetchData(
        source,
        sourceKey,
        filter,
        labelPath,
        optionValuePath,
        true,
        includes,
        filterUrl,
      ),
    ).then((r) => {
      if (r) {
        filterOptions(r.options);
      }
    });
  }, []);

  const allOptions = useSelector(
    (state) => state.selects[source + (sourceKey || '')]?.options ?? [],
  );
  const displayOptions = useSelector(
    (state) => state.selects[source + (sourceKey || '')]?.displayOptions ?? [],
  );
  const allData = useSelector(
    (state) => state.selects[source + (sourceKey || '')]?.data ?? [],
  );

  const filterOptions = (options) => {
    const matchFilter = (filter, option) => {
      const exactMatch = filter.match
        ? getByStringPath(option, `attributes.${filter.type}`) === filter.match
        : true;
      const appendMatch = filter.append
        ? getByStringPath(option, `attributes.${filter.type}`, '')
            .toLowerCase()
            .includes(filter.append.toLowerCase())
        : true;
      return exactMatch && appendMatch;
    };
    if (autoFilters && isObject) {
      const filteredOptions = options.filter((option) => {
        return autoFilters.reduce((carry, filter) => {
          return carry && matchFilter(filter, JSON.parse(option.value));
        }, true);
      });
      setDisplayOptions(source + sourceKey, filteredOptions);
    }

    if (autoFilters && !isObject) {
      // need to go to original data to get filtered options
      const filteredOptions = allData.reduce((carry, option) => {
        const matchFound = autoFilters.reduce((carry, filter) => {
          return carry && matchFilter(filter, option);
        }, true);
        if (matchFound) {
          carry.push({
            label: getByStringPath(option, labelPath || 'attributes.name'),
            value: optionValuePath ? getByStringPath(option, optionValuePath) : option,
          });
        }
        return carry;
      }, []);
      setDisplayOptions(source + sourceKey, filteredOptions);
    }

    if (!autoFilters) {
      setDisplayOptions(source + sourceKey, options);
    }
  };

  const getValues = () => {
    if (!input || isEmpty(input.value)) {
      return undefined;
    }
    if (!isEmpty(allOptions)) {
      if (isObject) {
        return allOptions.filter((option) => {
          const optionValue = JSON.parse(option.value).id;
          return (
            input.value.filter((value) => {
              return value.id === optionValue;
            }).length > 0
          );
        });
      }
      return allOptions.filter((option) => {
        return input.value.indexOf(JSON.parse(option.value)) >= 0;
      });
    }
    return undefined;
  };

  const handleOnChange = (selectedOptions) => {
    input.onChange(selectedOptions.map((s) => JSON.parse(s.value)));
    if (onChangeCallback) {
      onChangeCallback(selectedOptions.map((s) => JSON.parse(s.value)));
    }
  };

  const handleOnBlur = () => {
    input.onBlur(input.value);
  };

  const placeholderText = placeholder;
  const error = meta && meta.error;
  const touched = meta && meta.touched;
  const showErrorMsg = showValidationError || (touched && meta);
  const errorMsg = errorMessage || error;
  return (
    <div className="select-menu">
      <Select
        options={displayOptions}
        value={getValues()}
        isMulti
        placeholder={placeholderText}
        onBlur={() => handleOnBlur()}
        onChange={(values) => handleOnChange(values)}
        {...props}
      />
      {showErrorMsg && errorMsg && <span className="input-error">{errorMsg}</span>}
    </div>
  );
};

SelectMenuMulti.defaultProps = {
  labelPath: 'attributes.name',
  append: '',
  optionName: 'name',
};

export default connect(null, {
  fetchData: actions.fetchData,
  setDisplayOptions: actions.setDisplayOptions,
})(SelectMenuMulti);
