import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import cx from 'classnames';
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import { bindActionCreators } from 'redux';
import dataTableActions from './redux/dataTableActions';
import Icon from '../Icon/Icon';
import FullRow from '../Layout/FullRow';
import ButtonBar from '../ButtonBar/ButtonBar';
import './DataTable.scss';

const MAX_ATTEMPTS = 30;
const INTERVAL = 2000;

const DataTable = ({
  name,
  source,
  perPage,
  autoFilterType,
  autoFilter,
  autoFilters,
  refreshData,
  onRowSelect,
  keyField = 'id',
  columns,
  waitForItems,
  waitForItemsMessage,
  dataFetchedCallback,
  actions,
}) => {
  const [attempts, setAttempts] = useState(0);
  const [timer, setTimer] = useState();

  const dataTables = useSelector((state) => state.dataTables);

  useEffect(() => {
    const promises = [];
    promises.push(actions.initTable(name, source, perPage));
    if (source) {
      if (autoFilter) {
        promises.push(actions.setSource(name, source));
        promises.push(actions.setFilters(name, autoFilterType, autoFilter));
      }
      if (autoFilters) {
        // promises.push(dispatch(setSource(name, source)));
        autoFilters.forEach((filter) => {
          promises.push(actions.setFilters(name, filter.type, filter.value));
        });
      }

      if (refreshData) {
        promises.push(actions.setFilters(name, 'refresh', true));
      }
    }
    Promise.all(promises).then(() => {
      actions.fetchData(name, null, dataFetchedCallback);
    });

    const waitForItemsInterval = waitForItems
      ? setInterval(() => {
          setAttempts(attempts + 1);
          actions.fetchData(name);
        }, INTERVAL)
      : undefined;
    setTimer(waitForItemsInterval);

    return () => {
      clearInterval(waitForItemsInterval);
      actions.clearData(name);
    };
  }, []);

  const formatData = (jsonApiData = []) => {
    return jsonApiData.map((data) => {
      return {
        id: data.id,
        relationships: data.relationships,
        ...data.attributes,
      };
    });
  };

  const onPageChange = (page, sizePerPage) => {
    const nextPage = page || 1;
    const offset = (nextPage - 1) * sizePerPage;
    actions.setFilters(name, 'page[offset]', offset || 0);
    actions.setFilters(name, 'page[limit]', sizePerPage);
    actions.fetchData(name);
  };

  const onSizePerPageChange = (sizePerPage) => {
    const { currentPage } = dataTables.tables[name];
    const offset = (currentPage - 1) * sizePerPage;
    actions.setFilters(name, 'page[offset]', offset || 0);
    actions.setFilters(name, 'page[limit]', sizePerPage);
    actions.fetchData(name);
    return sizePerPage;
  };

  if (!dataTables.tables[name]) {
    return null;
  }

  if (
    waitForItems &&
    (attempts > MAX_ATTEMPTS || dataTables.tables[name].data.length >= waitForItems)
  ) {
    clearInterval(timer);
  }

  const { currentPage, data, filters, isFetching, total } = dataTables.tables[name];

  const limit = filters?.['page[limit]'] ?? 10;

  const selectRowProps = onRowSelect
    ? {
        mode: 'radio',
        className: 'selected',
        clickToSelect: true,
        hideSelectColumn: true,
        onSelect: onRowSelect,
      }
    : undefined;

  const formattedData = data ? formatData(data) : [];

  const noData = () => {
    if (isFetching) {
      return (
        <div className="text-center bs-table-placeholder">
          <Icon name="spinner" spin size="fa-1x" /> Loading results...
        </div>
      );
    }
    return <div className="text-center bs-table-placeholder">No results found</div>;
  };

  const onSortChange = (type, newState) => {
    if (type === 'sort') {
      const sortBy =
        newState.sortOrder === 'asc' ? `-${newState.sortField}` : newState.sortField;
      actions.applyFilter(name, 'sort', sortBy);
    }
  };

  const sortOption = {
    // No need to configure sortFunc per column
    sortFunc: onSortChange,
  };

  if (
    waitForItems &&
    attempts < MAX_ATTEMPTS &&
    dataTables.tables[name].data.length < waitForItems
  ) {
    return (
      <div className="text-center bs-table-placeholder">
        <Icon name="spinner" spin size="fa-1x" /> {waitForItemsMessage}
      </div>
    );
  }

  return (
    <>
      <div
        className={cx('data-table', {
          'select-enabled': !!onRowSelect,
          'select-disabled': !onRowSelect,
        })}
      >
        <PaginationProvider
          pagination={paginationFactory({
            page: currentPage,
            custom: true,
            sizePerPage: limit,
            totalSize: total,
            pageStartIndex: 1,
            // paginationSize: limit,
            sizePerPageList: [
              {
                text: '10',
                value: 10,
              },
              {
                text: '20',
                value: 20,
              },
              {
                text: '30',
                value: 30,
              },
              {
                text: '40',
                value: 40,
              },
              {
                text: '50',
                value: 50,
              },
              {
                text: 'All',
                value: total,
              },
            ],
            firstPageText: '<<',
            prePageText: '<',
            nextPageText: '>',
            lastPageText: '>>',
            firstPageTitle: 'Next page',
            prePageTitle: 'Pre page',
            nextPageTitle: 'First page',
            lastPageTitle: 'Last page',
            onPageChange,
            onSizePerPageChange,
            showTotal: true,
          })}
        >
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                remote
                keyField={keyField}
                data={formattedData}
                columns={columns}
                striped
                hover
                bordered={false}
                selectRow={selectRowProps}
                noDataIndication={noData}
                bootstrap4={true}
                onTableChange={onSortChange}
                {...paginationTableProps}
                sort={sortOption}
              />
              <FullRow>
                <ButtonBar>
                  <SizePerPageDropdownStandalone
                    btnContextual={'btn-default btn-sm pull-left'}
                    {...paginationProps}
                  />
                  <PaginationListStandalone {...paginationProps} />
                </ButtonBar>
              </FullRow>
            </div>
          )}
        </PaginationProvider>
      </div>
    </>
  );
};

export default connect(null, (dispatch) => ({
  actions: bindActionCreators(dataTableActions, dispatch),
}))(DataTable);
