import React from 'react';
import Toggle from '../Toggle/Toggle';
import Row from '../Layout/Row';
import { Field } from 'redux-form';

const ToggleRow = ({ labelSizes, label, fieldSizes, fieldOffsets, name, ...props }) => (
  <Row
    labelSizes={labelSizes}
    label={label}
    fieldSizes={fieldSizes}
    fieldOffsets={fieldOffsets}
  >
    <Field name={name} component={Toggle} {...props} />
  </Row>
);

export default ToggleRow;
