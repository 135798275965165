import { addValidator } from 'redux-form-validators';
import {
  format,
  isAfter,
  isBefore,
  parseISO,
  endOfDay,
  startOfDay,
  isDate,
  isSameDay,
} from 'date-fns';

const dateValidator = addValidator({
  validator: (options, value) => {
    if (!value && options?.isRequired) {
      return {
        id: options?.field,
        defaultMessage: options?.message ? options?.message : 'Required',
      };
    }

    const date = parseISO(value);

    if (options.inFuture && !options.isMta && isBefore(date, startOfDay(new Date()))) {
      return {
        id: options.field,
        defaultMessage: options.message ? options.message : 'Cannot backdate cover',
      };
    }

    if (options.isSameDay) {
      const isSameDayDate = isDate(options.isSameDay)
        ? options.isSameDay
        : endOfDay(parseISO(options.isSameDay));
      if (isSameDay(isSameDayDate, date)) {
        const message = options.isSameDayMessage
          ? options.isSameDayMessage
          : options.message;
        return {
          id: options.field,
          defaultMessage:
            message ||
            `Date cannot be the same as ${format(isSameDayDate, 'dd/MM/yyyy')}`,
        };
      }
    }

    if (options.before) {
      const beforeDate = isDate(options.before)
        ? options.before
        : endOfDay(parseISO(options.before));
      if (isBefore(beforeDate, date)) {
        const message = options.beforeMessage ? options.beforeMessage : options.message;
        return {
          id: options.field,
          defaultMessage:
            message || `Date must before ${format(beforeDate, 'dd/MM/yyyy')}`,
        };
      }
    }

    if (options.after) {
      const afterDate = isDate(options.after)
        ? options.after
        : endOfDay(parseISO(options.after));
      const afterDateWithTime = options.incTime ? afterDate : startOfDay(afterDate);
      const dateFormat = options.incTime
        ? format(afterDateWithTime, 'dd/MM/yyyy HH:mm')
        : format(afterDateWithTime, 'dd/MM/yyyy');
      if (isAfter(afterDateWithTime, date)) {
        const message = options.afterMessage ? options.afterMessage : options.message;
        return {
          id: options.field,
          defaultMessage: message || `Date must after ${dateFormat}`,
        };
      }
    }
    return true;
  },
});

const passwordValidator = addValidator({
  validator: (options, value) => {
    if (value.length < 8) {
      return {
        id: options.field,
        defaultMessage: 'Password must be at least 8 characters',
      };
    }

    if (
      /[a-z]/.test(value) &&
      /[A-Z]/.test(value) &&
      /[0-9]/.test(value) &&
      /\w[^A-Za-z0-9]/.test(value)
    ) {
      return true;
    }

    return {
      id: options.field,
      defaultMessage:
        'Password must contain both upper lowercase letters, at least one number and at least one alpha numeric character ',
    };
  },
});

const phoneRegex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/;

const phoneValidator = addValidator({
  validator: (options, value) => {
    const { isRequired } = options;
    if (isRequired && !value) {
      return {
        id: options.field,
        defaultMessage: options.msg ? options.msg : 'This field is required',
      };
    }
    if (value && !phoneRegex.test(value)) {
      return {
        id: options.field,
        defaultMessage: options.msg ? options.msg : 'This field is not valid',
      };
    }
    return true;
  },
});

const conditionalRequiredValidator = addValidator({
  validator: (options, value) => {
    const { isRequired } = options;
    if (isRequired && !value) {
      return {
        id: options.field,
        defaultMessage: options.msg || ' is required',
      };
    }
    return true;
  },
});

const debitCreditValidator = addValidator({
  validator: (options, value) => {
    const reverse = options.reverse ? parseFloat(options.reverse) : options.reverse;
    const valueFloat = value ? parseFloat(value) : '';

    if (reverse && valueFloat) {
      return {
        id: options.field,
        defaultMessage: options.msg || 'Debit and Credit cannot both be set',
      };
    }
    if (!reverse && !valueFloat) {
      return {
        id: options.field,
        defaultMessage: options.msg || 'Debit or credit must be set',
      };
    }
    return true;
  },
});

/** https://emailregex.com/ * */
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const emailValidator = addValidator({
  validator: (options, value) => {
    const { isRequired } = options;
    if (isRequired && !value) {
      return {
        id: options.field,
        defaultMessage: options.requiredMessage || 'Email is required',
      };
    }
    if (!EMAIL_REGEX.test(value)) {
      return {
        id: options.field,
        defaultMessage: options.notValidMessage || 'Email must be a valid email address',
      };
    }
    return true;
  },
});

const mileageValidator = addValidator({
  validator: (options, value, allOptions) => {
    if (!value) {
      return {
        id: options.field,
        defaultMessage: 'Required',
      };
    }
    const vehicleType = allOptions?.data.attributes.metadata.vehicle_type;
    const maxMileage =
      vehicleType === 'motorbike' ? options.maxMotorbike : options.maxCar;

    if (value < 0) {
      return {
        id: options.field,
        defaultMessage: 'Mileage cannot be a negative value',
      };
    }

    if (value > maxMileage) {
      return {
        id: options.field,
        defaultMessage: `Mileage must be under ${maxMileage}`,
      };
    }

    return true;
  },
});

export {
  dateValidator,
  passwordValidator,
  phoneValidator,
  conditionalRequiredValidator,
  emailValidator,
  debitCreditValidator,
  mileageValidator,
};
