import React from 'react';
import { DataTable } from '../../../../common/components';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import formatDateTime from '../../../../helpers/formatDateTime';
import { Form, Row, Col } from 'react-bootstrap';
import dataTableActions from '../../../../common/components/DataTable/redux/dataTableActions';

const VersionList = ({ goTo }) => {
  const handleRowClick = (row) => {
    return goTo(`/library/versions/${row.id}`);
  };

  return (
    <DataTable
      source="/library/versions"
      name="versions"
      onRowSelect={handleRowClick}
      columns={[
        {
          dataField: 'id',
          hidden: true,
        },
        {
          dataField: 'version',
          text: 'Key',
        },
        {
          dataField: 'description',
          text: 'Description',
        },
        {
          dataField: 'created_at',
          text: 'Created',
          formatter: formatDateTime,
        },
        {
          dataField: 'release_date',
          text: 'Release Date',
          formatter: formatDateTime,
        },
      ]}
    />
  );
};

export default connect(null, {
  applyFilter: dataTableActions.applyFilter,
  goTo: push,
})(VersionList);
