import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import displayName from '../../../../helpers/displayName';
import './PermissionsList.scss';
import { FullRow } from '../../../../common/components';
import Permission from './Permission';

const isPermissionChecked = (permissionId, existingPermissions) => {
  if (!existingPermissions) {
    return false;
  }
  const inPermissions = existingPermissions.find(
    (permission) => permission.id === permissionId,
  );
  return !!inPermissions;
};

const PermissionsList = ({ permissions, existingPermissions, onPermissionChange }) => {
  return (
    <>
      <FullRow>
        <h3>Role Permissions</h3>
      </FullRow>
      <Row>
        {permissions &&
          Object.keys(permissions).map((category) => {
            const permissionsList = permissions[category];
            return (
              <Col key={category} sm={4}>
                <Card className="permissions-container">
                  <Card.Title className="permissions-title">
                    {displayName(category)}
                  </Card.Title>
                  <Card.Body className="permissions-body">
                    <ul className="permission-list">
                      {permissionsList.map((permission) => (
                        <li key={permission.id}>
                          <div className="checkbox">
                            <Permission
                              onPermissionChange={onPermissionChange}
                              permission={permission}
                              isChecked={isPermissionChecked(
                                permission.id,
                                existingPermissions,
                              )}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
      </Row>
    </>
  );
};

export default PermissionsList;
