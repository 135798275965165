import React from 'react';
import { FullRow, Button } from '../../../common/components';
import InternalButtonBar from '../../layout/components/InternalButtonBar/InternalButtonBar';
import MedicalAnswerSetList from '../components/MedicalAnswerSetList/MedicalAnswerSetList';

const MedicalAnswerSetListContainer = () => {
  return (
    <div className="container">
      <div className="content-block">
        <h1>Answer Set List</h1>
        <FullRow>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['medical_library.modify'],
                button: (
                  <Button
                    key="new-medical-answer-set"
                    to="/library/answer-sets/new"
                    className="pull-right"
                    variant="primary"
                    label="New Answer Set"
                  />
                ),
              },
            ]}
          />
        </FullRow>
        <MedicalAnswerSetList />
      </div>
    </div>
  );
};

export default MedicalAnswerSetListContainer;
