import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import screeningActions from '../redux/screeningActions';
import { MedicalScreening } from 'antidote-medical-screening';
import Axios from 'axios';

const ScreeningContainer = ({ actions, dispatch }) => {
  const screening = useSelector((state) => state.screening);

  useEffect(() => {
    if (!screening.sessionId) {
      return dispatch(push('/setup'));
    }
  }, []);

  const handleOnFinish = (sessionId) => {
    actions.finishSession().then(() => {
      return dispatch(push(`/screening/${sessionId}`));
    });
  };

  return (
    <div className="container">
      <div className="content-block">
        <MedicalScreening
          url={Axios.defaults.baseURL}
          sessionId={screening.sessionId}
          onFinish={handleOnFinish}
        />
      </div>
    </div>
  );
};

export default connect(null, (dispatch) => ({
  actions: bindActionCreators(screeningActions, dispatch),
  dispatch,
}))(ScreeningContainer);
