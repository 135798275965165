import React from 'react';
import { Table } from 'react-bootstrap';
import displayName from '../../../../helpers/displayName';
import displayScore from '../../../../helpers/displayScore';

const ScreeningScoreScoringSummary = ({
  showBreakdown,
  showAltitudeExclusions,
  sessionData,
}) => {
  const conditions = sessionData.conditions ?? [];
  const conditionScores = sessionData.conditionScores ?? [];
  const totalScore = sessionData.totalScore ?? 0;

  const renderCondition = (condition, key) => {
    const score = conditionScores[condition.id ?? ''] ?? {};

    return (
      <tr key={key}>
        <td>{condition.name ?? ''}</td>
        {showBreakdown && (
          <td>{score.base_score ? displayScore(score.base_score) : ''}</td>
        )}
        {showBreakdown && (
          <td>{score.severity_score ? displayScore(score.severity_score) : ''}</td>
        )}
        {showAltitudeExclusions && <td>{displayName(condition.altitudeExclusion)}</td>}
        <td>{score.score ? displayScore(score.score) : ''}</td>
      </tr>
    );
  };

  let totalScoreColumnOffset = 4;
  if (!showAltitudeExclusions) {
    totalScoreColumnOffset -= 1;
  }
  if (!showBreakdown) {
    totalScoreColumnOffset -= 2;
  }

  return (
    <>
      <h2>Scoring Summary</h2>
      <Table responsive>
        <thead>
          <tr>
            <th>Condition</th>
            {showBreakdown && <th>Score</th>}
            {showBreakdown && <th>Severity</th>}
            {showAltitudeExclusions && <th>Altitude Exclusion</th>}
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(conditions).map((key) => renderCondition(conditions[key], key))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={totalScoreColumnOffset} />
            <td>{displayScore(totalScore)}</td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default ScreeningScoreScoringSummary;
