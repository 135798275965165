import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import actions from '../redux/medicalAnswerSetActions';
import { Loader } from '../../../common/components';
import MedicalAnswerSetForm from '../components/MedicalAnswerSetForm/MedicalAnswerSetForm';

const MedicalAnswerSetEditContainer = ({
  getMedicalAnswerSet,
  updateMedicalAnswerSet,
  goTo,
}) => {
  const { id } = useParams();

  useEffect(() => {
    getMedicalAnswerSet(id);
  }, [getMedicalAnswerSet, id]);

  const saveAnswerSet = (values) => {
    return Promise.resolve(
      updateMedicalAnswerSet(id, values).then((resp) => {
        goTo(`/library/answer-sets/${resp.data.id}`);
      }),
    );
  };
  const resource = useSelector((state) => state.medicalAnswerSet.resources?.[id]);

  return (
    <div className="container">
      <div className="content-block">
        {resource?.data?.id ? (
          <MedicalAnswerSetForm resource={resource} saveAnswerSet={saveAnswerSet} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default connect(null, {
  getMedicalAnswerSet: actions.getMedicalAnswerSet,
  updateMedicalAnswerSet: actions.updateMedicalAnswerSet,
  goTo: push,
})(MedicalAnswerSetEditContainer);
