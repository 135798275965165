import Axios from 'axios';
import * as statsActions from './statsReducer';
import handleErrorResponse from '../../../helpers/handleErrorResponse';

const isSubmitting = (status) => {
  return {
    type: statsActions.IS_SUBMITTING,
    status,
  };
};

const statsReport = (startDate, endDate, report, clientId) => {
  return (dispatch) => {
    const endpoint = '/library/stats';

    dispatch(isSubmitting(true));
    return Axios.post(endpoint, {
      meta: {
        startDate: startDate,
        endDate: endDate,
        report: report,
        clientId: clientId,
      },
    })
      .then((response) => {
        dispatch(isSubmitting(false));
        window.open(response.data.url, '_blank');
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error generating stats');
      });
  };
};

const actions = {
  statsReport,
};
export default actions;
