import getIncludedResource from '../../../../helpers/getIncludedResource';
import uuid from 'uuid';

export default function medicalQuestionDataToFormData(questions, included) {
  return Object.keys(questions).map((key) => {
    const question = getIncludedResource(questions[key], included, 'question');

    const questionAnswers = getIncludedResource(questions[key], included, 'answers');

    return {
      id: question.data?.id,
      localId: uuid.v4(),
      medicalConditionQuestionId: questions[key].id,
      text: question.data?.attributes?.question,
      answerSetId: questions[key].relationships?.answerSet?.data?.id,
      answers: Object.keys(questionAnswers).map((key2) => {
        const answer = getIncludedResource(questionAnswers[key2], included, 'answer');
        return {
          id: questionAnswers[key2].id,
          text: answer.data?.attributes?.answer,
          position: answer.data?.attributes?.answerIndex,
          forwardConditionId: questionAnswers[key2].attributes?.forwardConditionId,
          nextQuestionIndex: questionAnswers[key2].attributes?.nextQuestionIndex,
          severityScore: questionAnswers[key2].attributes?.severityScore,
        };
      }),
    };
  });
}
