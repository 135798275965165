import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import * as medicalAnswerSetActions from './medicalAnswerSetReducer';
import { push } from 'connected-react-router';

const defaultIncludes = ['answers'];

const isSubmitting = (status) => {
  return {
    type: medicalAnswerSetActions.IS_SUBMITTING,
    status,
  };
};

const returnMedicalAnswerSetDetails = (id, includes = defaultIncludes) => {
  return () => {
    const endpoint = `library/answer-sets/${id}${queryString(includes)}`;
    return Axios.get(endpoint);
  };
};

const getMedicalAnswerSet = (id, includes = defaultIncludes) => {
  return (dispatch) => {
    const endpoint = `library/answer-sets/${id}${queryString(includes)}`;

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: medicalAnswerSetActions.SET_ANSWER_SET,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        dispatch(push('/dashboard'));
        handleErrorResponse(error, 'Unable to load answer set');
      });
  };
};

const createMedicalAnswerSet = (resource) => {
  return () => {
    return Axios.post('/library/answer-sets', resource)
      .then((response) => {
        toastr.success('Success', 'Answer set has been created successfully');
        return response.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error creating the answer set');
      });
  };
};

const updateMedicalAnswerSet = (id, resource, includes = defaultIncludes) => {
  return () => {
    const endpoint = `/library/answer-sets/${id}${queryString(includes)}`;

    return Axios.patch(endpoint, resource)
      .then((response) => {
        toastr.success('Success', 'Answer set has been updated successfully');
        return response.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error updating the answer set');
      });
  };
};

const actions = {
  isSubmitting,
  returnMedicalAnswerSetDetails,
  getMedicalAnswerSet,
  createMedicalAnswerSet,
  updateMedicalAnswerSet,
};
export default actions;
