import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from '../redux/medicalQuestionActions';
import { Loader } from '../../../common/components';
import MedicalQuestionDetails from '../components/MedicalQuestionDetails/MedicalQuestionDetails';
import getIncludedResource from '../../../helpers/getIncludedResource';

const MedicalQuestionShowContainer = ({ getMedicalQuestion }) => {
  const { id } = useParams();
  useEffect(() => {
    getMedicalQuestion(id);
  }, [getMedicalQuestion, id]);

  const resource = useSelector((state) => state.medicalQuestion.resources?.[id]);
  const conditions = resource
    ? getIncludedResource(resource.data, resource.included, 'conditions')
    : [];
  return (
    <div className="container">
      <div className="content-block">
        {resource ? (
          <MedicalQuestionDetails resource={resource} conditions={conditions} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default connect(null, {
  getMedicalQuestion: actions.getMedicalQuestion,
})(MedicalQuestionShowContainer);
