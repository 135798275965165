import React from 'react';
import {
  BackButton,
  Button,
  FullRow,
  LabelRow,
  Link,
} from '../../../../common/components';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { Card } from 'react-bootstrap';

const LABEL_SIZES = 2;
const FIELD_SIZES = 10;

const MedicalQuestionDetails = ({ resource, conditions }) => {
  const resourceAttributes = resource.data.attributes;

  return (
    <>
      <h2 className="resource-name">Question</h2>

      <LabelRow label="Question" labelSizes={LABEL_SIZES} fieldSizes={FIELD_SIZES}>
        {resourceAttributes.question}
      </LabelRow>

      {conditions && (
        <Card className="permissions-container">
          <Card.Title className="permissions-title">This question appears in</Card.Title>
          <Card.Body className="permissions-body">
            <ul className="permission-list">
              {conditions.map((condition) => (
                <li key={condition.id}>
                  <Link to={`/library/conditions/${condition.id}`}>
                    {condition.attributes.name}
                  </Link>
                </li>
              ))}
            </ul>
          </Card.Body>
        </Card>
      )}

      <FullRow>
        <InternalButtonBar
          buttons={[
            {
              permissions: ['medical_library.modify'],
              button: (
                <Button
                  key="edit-question"
                  to={`/library/questions/${resource.data.id}/edit`}
                  className="pull-right"
                  bsStyle="primary"
                  rightIcon="edit"
                  label="Edit Question"
                />
              ),
            },
            {
              button: <BackButton key="back" to="/library/questions" />,
            },
          ]}
        />
      </FullRow>
    </>
  );
};

export default MedicalQuestionDetails;
