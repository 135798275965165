import React from 'react';
import './CheckCircle.scss';
import cx from 'classnames';
import Icon from '../Icon/Icon';

const CheckCircle = ({ onChange, inline, checked, label, size }) => {
  return (
    <div className="check-circle">
      <label className={inline ? 'inline' : ''}>
        <input type="checkbox" checked={checked} readOnly />
        <span className="label__text" onClick={onChange}>
          <span className={cx('label__check', { small: size === 'small' })}>
            <Icon iconName="check" />
          </span>
          <span className="label">{label}</span>
        </span>
      </label>
    </div>
  );
};

export default CheckCircle;
