import React from 'react';
import { ButtonBar, Input, Icon, Button } from '../../../../common/components';
import { Form, reduxForm, getFormSyncErrors, Field } from 'redux-form';
import '../LoginForm/LoginForm.scss';

import { required } from 'redux-form-validators';
import { FullRow } from '../../../../common/components';
import { connect } from 'react-redux';

const PasswordReminderForm = ({ handleSubmit, submitting }) => {
  return (
    <div className="container">
      <div className="content-block login-box-container">
        <div className="login-box">
          <h1>Forgotten your password</h1>
          <hr />
          <Form onSubmit={handleSubmit} className="login-form" method="post">
            <FullRow>
              <Field
                id="email"
                name="email"
                component={Input}
                className="white"
                validate={[required({ msg: 'Email is required' })]}
                type="email"
                addOnBefore={<Icon iconName="envelope" />}
                placeholder="Email address"
              />
            </FullRow>
            <hr />
            <FullRow>
              <ButtonBar>
                <Button
                  id="login-button"
                  type="submit"
                  className="pull-right"
                  variant="primary"
                  isLoading={submitting}
                  leftIcon="signin"
                  disabled={submitting}
                >
                  Request Reset
                </Button>
              </ButtonBar>
            </FullRow>
          </Form>
        </div>
      </div>
    </div>
  );
};

const FORM_NAME = 'PasswordReminder';
export default connect(
  (state) => ({
    errors: getFormSyncErrors(FORM_NAME)(state),
  }),
  null,
)(reduxForm({ form: FORM_NAME })(PasswordReminderForm));
