import React from 'react';
import { ButtonBar, Input, Icon, Button, InputRow } from '../../../../common/components';
import { Form, reduxForm, getFormSyncErrors, Field } from 'redux-form';
import '../LoginForm/LoginForm.scss';

import { required } from 'redux-form-validators';
import confirmation from 'redux-form-validators/src/confirmation';
import { FullRow } from '../../../../common/components';
import { passwordValidator } from '../../../../helpers/validators';
import { connect } from 'react-redux';

const FIELD_SIZE = 12;
const LABEL_SIZE = 0;

const ResetPasswordForm = ({ handleSubmit, submitting }) => {
  return (
    <div className="container">
      <div className="content-block login-box-container">
        <div className="login-box">
          <h1>Complete Password Reset</h1>
          <hr />
          <Form onSubmit={handleSubmit} className="login-form" method="post">
            <>
              <>
                <InputRow
                  fieldSize={FIELD_SIZE}
                  labelSize={LABEL_SIZE}
                  className="white"
                  name="email"
                  validate={[required({ msg: 'Email is required' })]}
                  type="email"
                  addOnBefore={<Icon iconName="envelope" />}
                  placeholder="Email address"
                />
              </>
            </>
            <div className="spacer" />
            <>
              <InputRow
                fieldSize={FIELD_SIZE}
                labelSize={LABEL_SIZE}
                className="white"
                id="password"
                name="password"
                validate={[
                  required({ msg: 'Password is required' }),
                  passwordValidator(),
                ]}
                type="password"
                addOnBefore={<Icon iconName="lock" />}
                iconName="lock"
                placeholder="New Password"
              />
            </>
            <div className="spacer" />
            <FullRow>
              <Field
                id="password"
                name="password_confirmation"
                className="white"
                component={Input}
                validate={[
                  required({ msg: 'Password confirmation is required' }),
                  confirmation({
                    field: 'password',
                    fieldLabel: 'New Password',
                    msg: 'Passwords do not match',
                  }),
                ]}
                type="password"
                addOnBefore={<Icon iconName="checked" />}
                placeholder="Confirm new password"
              />
            </FullRow>
            <hr />
            <FullRow>
              <ButtonBar>
                <Button
                  type="submit"
                  variant="primary"
                  className="pull-right"
                  leftIcon="signin"
                  disabled={submitting}
                >
                  Complete Password Reset
                </Button>
              </ButtonBar>
            </FullRow>
          </Form>
        </div>
      </div>
    </div>
  );
};

const FORM_NAME = 'ResetPassword';
export default connect(
  (state) => ({
    errors: getFormSyncErrors(FORM_NAME)(state),
  }),
  null,
)(reduxForm({ form: FORM_NAME })(ResetPasswordForm));
