import React from 'react';
import { hasAnyPermission } from '../../../auth/redux/authActions';
import { ButtonBar } from '../../../../common/components';

const InternalButtonBar = ({ buttons }) => {
  const filterMatched = (filters) => {
    return filters.reduce((carry, filter) => {
      const matched = filter.filterValues.includes(filter.value);
      return carry && matched;
    }, true);
  };
  const filteredButtons = buttons.filter((button) => {
    if (!button.button) {
      return false;
    }
    const showButton = button.filter ? filterMatched(button.filter, button) : true;
    return showButton && hasAnyPermission(button.permissions ?? []);
  });

  return <ButtonBar>{filteredButtons.map((button) => button.button)}</ButtonBar>;
};

export default InternalButtonBar;
