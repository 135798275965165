import React from 'react';
import { DataTable } from '../../../../common/components';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import formatDateTime from '../../../../helpers/formatDateTime';

const OrganisationList = ({ dispatch }) => {
  const handleRowClick = (row) => {
    return dispatch(push(`/admin/organisations/${row.id}`));
  };

  return (
    <DataTable
      source="/organisations"
      name="organisations"
      onRowSelect={handleRowClick}
      columns={[
        {
          dataField: 'id',
          hidden: true,
        },
        {
          dataField: 'name',
          text: 'Name',
        },
        {
          dataField: 'created_at',
          text: 'Created',
          formatter: formatDateTime,
        },
      ]}
    />
  );
};

export default connect()(OrganisationList);
