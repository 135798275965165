import React from 'react';
import Select from './Select';

const SelectRedux = ({
  input,
  meta,
  isMulti,
  options,
  handleOnChange,
  placeholder = 'Please Select...',
  showValidationError,
  disabled,
  ...props
}) => {
  const inputValue = input.value;
  const hasError = meta && meta.touched && meta.error;
  const getValue = (value) => {
    if (isMulti) {
      return options.reduce((carry, option) => {
        if (value.indexOf(option.value) >= 0) {
          carry.push(option);
          return carry;
        }
        return carry;
      }, []);
    }
    return options.find((opt) => {
      return opt.value === value;
    });
  };

  const handleOnSelectFieldChange = (option) => {
    if (isMulti) {
      if (option) {
        const optionValues = option.map((opt) => opt.value);
        input.onChange(optionValues);
      }
    } else {
      input.onChange(option ? option.value : '');
    }
    if (handleOnChange) {
      handleOnChange(option);
    }
  };

  return (
    <>
      <Select
        onBlur={() => (input.onBlur ? input.onBlur(input.value) : '')}
        onChange={handleOnSelectFieldChange}
        value={getValue(inputValue)}
        placeholder={placeholder}
        options={options}
        isMulti={isMulti}
        isDisabled={disabled}
        {...props}
      />
      {showValidationError && hasError && (
        <span className="input-error">{meta.error}</span>
      )}
    </>
  );
};

export default SelectRedux;
