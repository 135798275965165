import Axios from 'axios';
import {
  SET_USER,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  UNAUTHENTICATE,
} from './authReducer';
import queryString from '../../../helpers/queryString';
import { doNotUseThisStore } from '../../../redux/configureStore';
import { push } from 'connected-react-router';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { toastr } from 'react-redux-toastr';

const LOGOUT_TIMEOUT = 1000;

/**
 * Sends the set user event top the reducer
 * @param user
 * @returns {{type, user: *}}
 */
export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

/**
 * Fetches a user by the given id
 * @param id If null, will fetch currently authenticated user.
 * @param accessToken accessToken.
 * @param includes
 */
export function getUser(id = null, includes = [], accessToken = null) {
  return (dispatch) => {
    let endpoint = id ? 'users/' + id : 'user';
    endpoint += queryString(includes);

    let config = undefined;

    if (accessToken) {
      config = { headers: { Authorization: 'Bearer ' + accessToken } };
    }
    return Axios.get(endpoint, config).then((response) => {
      return dispatch(setUser(response.data));
    });
  };
}

export function setAccessToken(accessToken) {
  return {
    type: LOGIN_SUCCESS,
    token: accessToken,
  };
}

export function getUserAndSetAccessToken(accessToken) {
  return (dispatch) => {
    return dispatch(getUser(null, [], accessToken))
      .then(() => {
        return dispatch(setAccessToken(accessToken));
      })
      .catch(() => {
        return dispatch({ type: UNAUTHENTICATE });
      });
  };
}

/**
 * Checks if the user has a given permission
 * @param name
 * @returns boolean
 */
export function hasPermission(name) {
  const user = doNotUseThisStore.getState().auth.user;

  if (!user) {
    return false;
  }

  const { permissions } = user.data?.meta ?? { permissions: [] };

  return permissions.indexOf(name) > -1;
}

export function hasAnyPermission(permissions = []) {
  if (!permissions.length) {
    return true;
  }

  let granted = false;
  permissions.forEach((permission) => {
    if (hasPermission(permission)) {
      granted = true;
    }
  });
  return granted;
}

/**
 * attempt to login a user
 * @param email
 * @param password
 * @returns {function()}
 */
export function login(email, password) {
  return (dispatch) => {
    dispatch({ type: LOGIN });

    return Axios.post(
      '/auth/login',
      {
        username: email,
        password: password,
      },
      {
        baseURL: '', // This request is being made to the local node server
      },
    )
      .then((response) => {
        Axios.defaults.headers.common = {
          ...Axios.defaults.headers.common,
          Authorization: 'Bearer ' + response.data.access_token,
        };

        dispatch(getUser(null)).then(() => {
          dispatch(setAccessToken(response.data.access_token));
          dispatch(push('/dashboard'));
          return true;
        });
        return true;
      })
      .catch((error) => {
        dispatch({ type: LOGIN_ERROR });
        handleErrorResponse(null, 'Invalid email address or password');
      });
  };
}

/**
 * Logout from the current session
 * @returns {function()}
 */
export function logOut() {
  return (dispatch) => {
    delete Axios.defaults.headers.common.Authorization;
    dispatch({ type: UNAUTHENTICATE });

    // Fire and forget
    return Axios.post(
      '/auth/logout',
      {},
      {
        baseURL: '', // This request is being made to the local node server
      },
    )
      .then(() => {
        dispatch(push('/'));

        setTimeout(() => {
          dispatch({ type: LOGOUT });
        }, LOGOUT_TIMEOUT);

        toastr.success('Goodbye', 'You have successfully logged out');

        return true;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };
}

/**
 * Send a password reminder email
 * @param email
 * @returns {function()}
 */
export function passwordReminder(email) {
  return (dispatch) => {
    return Axios.post('./password/remind', { email })
      .then(() => {
        toastr.success('Email sent', 'The password reset email has been sent');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(null, 'There was an error sending the password reset email');
      });
  };
}

/**
 * Reset a users password
 * @param token
 * @param email
 * @param password
 * @param confirmation
 */
export function resetPassword(token, email, password, confirmation) {
  return (dispatch) => {
    return Axios.post('/password/reset', {
      token: token,
      email: email,
      password: password,
      password_confirmation: confirmation,
    })
      .then(() => {
        // login using the newly submitted password
        dispatch(login(email, password));
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Your password could not be reset at this time');
      });
  };
}
