import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import SetupForm from '../components/SetupForm/SetupForm';
import { bindActionCreators } from 'redux';
import screeningActions from '../redux/screeningActions';
import { useParams } from 'react-router-dom';

const SetupContainer = ({ formOnly, actions, dispatch }) => {
  const screening = useSelector((state) => state.screening);
  const { previousId } = useParams();
  const sessionSettings = screening.session.data.settings;
  const [initialValues] = useState(!!screening.sessionId ? sessionSettings : null);

  useEffect(() => {
    actions.getAllVersions();
  }, []);

  const handleSetupSubmit = (values) => {
    actions.resetSession();

    return actions.startSession(values, previousId).then(() => {
      return dispatch(push('/screening'));
    });
  };

  const form = (
    <SetupForm
      handleSetupSubmit={handleSetupSubmit}
      versions={screening.versions}
      previousSettings={initialValues}
    />
  );

  if (formOnly) {
    return form;
  }

  return (
    <div className="container">
      <div className="content-block">
        <h1>Screening Setup</h1>
        <hr />
        {form}
      </div>
    </div>
  );
};

export default connect(null, (dispatch) => ({
  actions: bindActionCreators(screeningActions, dispatch),
  dispatch,
}))(SetupContainer);
