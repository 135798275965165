import React from 'react';
import { FullRow, Button } from '../../../common/components';
import InternalButtonBar from '../../layout/components/InternalButtonBar/InternalButtonBar';
import OrganisationList from '../components/OrganisationList/OrganisationList';

const OrganisationListContainer = () => {
  return (
    <div className="container">
      <div className="content-block">
        <h1>Organisation List</h1>
        <FullRow>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['organisation.create'],
                button: (
                  <Button
                    key="new-organisation"
                    to="/admin/organisations/new"
                    className="pull-right"
                    variant="primary"
                    label="New Organisation"
                  />
                ),
              },
            ]}
          />
        </FullRow>
        <OrganisationList />
      </div>
    </div>
  );
};

export default OrganisationListContainer;
