import Axios from 'axios';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import * as versionActions from './versionReducer';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';

const defaultIncludes = [];

const isSubmitting = (status) => {
  return {
    type: versionActions.IS_SUBMITTING,
    status,
  };
};

const getVersion = (id, includes = defaultIncludes) => {
  return (dispatch) => {
    const endpoint = `library/versions/${id}${queryString(includes)}`;

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: versionActions.SET_VERSION,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        dispatch(push('/dashboard'));
        handleErrorResponse(error, 'Unable to load version');
      });
  };
};

const getComparison = (from = 'initial', to = 'working') => {
  return (dispatch) => {
    const endpoint = `library/releases/${from}/${to}`;

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: versionActions.SET_COMPARISON,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        dispatch(push('/dashboard'));
        handleErrorResponse(error, 'Unable to load version comparison');
      });
  };
};

const updateVersion = (id, resource, includes = defaultIncludes) => {
  return () => {
    const endpoint = `/library/versions/${id}${queryString(includes)}`;

    return Axios.patch(endpoint, resource)
      .then((response) => {
        toastr.success('Success', 'Version has been updated successfully');
        return response.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error updating the version');
      });
  };
};

const scheduleRelease = (id, releaseDate) => {
  return (dispatch) => {
    const endpoint = `/library/versions/${id}/actions/release`;

    return Axios.post(endpoint, { meta: { releaseDate } })
      .then((response) => {
        toastr.success(
          'Success',
          'This version has been scheduled for release and a new working version has been created.',
        );
        return response.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error scheduling the release');
      });
  };
};

const exportRelease = (id) => {
  return (dispatch) => {
    const endpoint = `/library/versions/${id}/actions/export`;

    dispatch(isSubmitting(true));
    return Axios.post(endpoint)
      .then((response) => {
        dispatch(isSubmitting(false));
        window.open(response.data.url, '_blank');
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error exporting the release');
      });
  };
};

const actions = {
  getVersion,
  getComparison,
  updateVersion,
  scheduleRelease,
  exportRelease,
};
export default actions;
