import React from 'react';
import { Image, Navbar } from 'react-bootstrap';
import logo from '../../../../images/logo.svg';
import { Link } from '../../../../common/components';
import './GuestHeader.scss';

const GuestHeader = ({}) => {
  return (
    <header className="header">
      <div className="container">
        <Link to="/">
          <Image src={logo} className="logo" />
        </Link>
        <h1 className="medical-library hidden-xs">Medical Library</h1>
      </div>
      <Navbar variant="dark" bg="primary" />
    </header>
  );
};

export default GuestHeader;
