import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import MedicalQuestionForm from '../components/MedicalQuestionForm/MedicalQuestionForm';
import questionSchema from '../../../schemas/medicalQuestion';
import actions from '../redux/medicalQuestionActions';

const MedicalQuestionNewContainer = ({ createMedicalQuestion, goTo }) => {
  const saveQuestion = (values) => {
    return Promise.resolve(
      createMedicalQuestion(values).then((resp) => {
        goTo(`/library/questions/${resp.data.id}`);
      }),
    );
  };

  return (
    <div className="container">
      <div className="content-block">
        <MedicalQuestionForm resource={questionSchema} saveQuestion={saveQuestion} />
      </div>
    </div>
  );
};

export default connect(null, {
  createMedicalQuestion: actions.createMedicalQuestion,
  goTo: push,
})(MedicalQuestionNewContainer);
