import { Col, Row as BootstrapRow } from 'react-bootstrap';
import React from 'react';

const FULL_ROW_SIZE = 12;
const FullRow = ({ children, className }) => (
  <BootstrapRow className={className}>
    <Col sm={FULL_ROW_SIZE}>{children}</Col>
  </BootstrapRow>
);

export default FullRow;
