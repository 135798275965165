import { intlFormat } from 'date-fns';

export default function formatDate(cell) {
  if (!cell) {
    return '';
  }

  return intlFormat(new Date(cell), {
    locale: 'en-GB',
  });
}
