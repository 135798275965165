import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import * as userActions from './userReducer';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { push } from 'connected-react-router';

export const defaultIncludes = ['roles', 'organisations', 'organisations.roles'];

const isResetting = (status) => {
  return {
    type: userActions.IS_RESETTING,
    status,
  };
};

const isReactivating = (status) => {
  return {
    type: userActions.IS_REACTIVATING,
    status,
  };
};

const isDeactivating = (status) => {
  return {
    type: userActions.IS_DEACTIVATING,
    status,
  };
};

const getUser = (id, includes = defaultIncludes) => {
  return (dispatch) => {
    const endpoint = `users/${id}${queryString(includes)}`;

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: userActions.SET_USER,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        dispatch(push('/dashboard'));
        handleErrorResponse(error, 'Unable to load user');
      });
  };
};

const createUser = (values) => {
  return () => {
    return Axios.post('users?include=organisations', values)
      .then((response) => {
        toastr.success('Success', 'User has been created successfully');
        return response.data;
      })
      .catch((error) => {
        return handleErrorResponse(error, 'There was an error creating the user');
      });
  };
};

const updateUser = (userId, resource, includes = defaultIncludes) => {
  const endpoint = `users/${userId}${queryString(includes)}`;

  return () => {
    return Axios.patch(endpoint, resource)
      .then((response) => {
        toastr.success('Success', 'User has been updated successfully');
        return response.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error updating the user');
      });
  };
};

const deactivateUser = (id) => {
  return (dispatch) => {
    dispatch(isDeactivating(true));
    return Axios.post(`users/${id}:disable`)
      .then(() => {
        toastr.success('User Disabled', 'User has been successfully disabled');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error disabling user');
      })
      .finally(() => {
        dispatch(isDeactivating(false));
      });
  };
};

const reactivateUser = (id) => {
  return (dispatch) => {
    dispatch(isReactivating(true));
    return Axios.post(`users/${id}:enable`)
      .then(() => {
        toastr.success('User Enabled', 'User has been successfully enabled');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error enabling user');
      })
      .finally(() => {
        dispatch(isReactivating(false));
      });
  };
};

const passwordReminder = (id) => {
  return (dispatch) => {
    dispatch(isResetting(true));
    const endpoint = `/users/${id}:reset-password`;

    return Axios.post(endpoint)
      .then(() => {
        toastr.success('Email sent', 'The password reset email has been sent');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error sending the password reset email');
      })
      .finally(() => {
        dispatch(isResetting(false));
      });
  };
};

const actions = {
  isResetting,
  isReactivating,
  isDeactivating,
  getUser,
  createUser,
  updateUser,
  reactivateUser,
  deactivateUser,
  passwordReminder,
};
export default actions;

/*


// TODO use async await
// Add cancel
// Add debounce

const searchOrganisation = (searchTerm) => {
  return (dispatch) => {
    const endPoint = `organisations?page[limit]=10&filter[name]=${searchTerm}`;
    return Axios.get(endPoint)
      .then((response) => {
        dispatch({
          type: userActions.SET_ORGANISATION,
          data: response.data
        });
        return true;
      });
  };
};

const getOrganisationListSuggestion = () => {
  return (dispatch) => {
    const endPoint = 'organisations?page[limit]=10';
    return Axios.get(endPoint)
      .then((response) => {
        dispatch({
          type: userActions.SET_ORGANISATION,
          data: response.data
        });
        return true;
      });
  };
};

// const removeUserOrganisation = (userId, orgId) => async (dispatch) => {
//   const localData = {
//     relationships: {
//       organisation: {
//         data: {
//           id: orgId,
//         },
//       },
//     },
//   };
//
//   const endpoint = `/users/${userId}:remove-organisation`;
//
//   try {
//     const response = await Axios.post(endpoint, { data: localData });
//     const data = response.data.data;
//     dispatch({
//       type: userActions.REMOVE_USER_ORGANISATION,
//       data: data,
//     });
//   } catch (e) {
//     console.error(e);
//   }
// };

const setUserOrganisationRoles = (response, organisation) => {
  return {
    type: userActions.SET_USER_ORGANISATION_ROLES,
    organisation,
    data: response.data
  };
};

const isLoading = (status) => {
  return {
    type: userActions.IS_LOADING,
    status
  };
};

const clearUser = () => {
  return {
    type: userActions.CLEAR_USER
  };
};

const isSubmitting = (status) => {
  return {
    type: userActions.IS_SUBMITTING,
    status
  };
};




const getUserOrganisationRoles = (id, organisationId) => {
  return (dispatch) => {
    const endpoint = `users/${
      id
    }/organisations/${
      organisationId ? `${organisationId}/roles` : 'roles'}`;

    return Axios.get(endpoint)
      .then((response) => {
        dispatch(
          setUserOrganisationRoles(response, organisationId || null),
        );
        return true;
      });
  };
};

const saveUserOrganisationRoles = (resource, userId, organisationId) => {
  return () => {
    const endpoint = `users/${
      userId
    }/organisations/${
      organisationId
    }/roles`;

    return Axios.patch(endpoint, resource)
      .then(() => {
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error updating the organisation roles');
      });
  };
};



const removeUserOrganisation = (userId, orgId) => {
  return (dispatch) => {
    const localData = {
      relationships: {
        organisation: {
          data: {
            id: orgId
          }
        }
      }
    };

    const endpoint = `/users/${userId}:remove-organisation`;

    dispatch(isSubmitting(true));
    Axios.post(endpoint, { data: localData }).then((response) => {
      const { data } = response.data;
      dispatch({
        type: userActions.REMOVE_USER_ORGANISATION,
        data
      });
      dispatch(isSubmitting(false));
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(isSubmitting(false));
    });
  };
};


const actions = {
  searchOrganisation,
  getOrganisationListSuggestion,
  setUser,
  removeUserOrganisation,
  setUserOrganisationRoles,
  isLoading,
  clearUser,
  isSubmitting,
  isResetting,
  getUser,
  getUserOrganisationRoles,
  updateUser,
  saveUserOrganisationRoles,
  createUser,
  passwordReminder,

};
export default actions;

*/
