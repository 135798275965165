import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import actions from '../redux/roleActions';
import { Loader } from '../../../common/components';
import RoleForm from '../components/RoleForm/RoleForm';

const RoleEditContainer = ({ getRole, getPermissions, updateRole, goTo }) => {
  const { id } = useParams();

  useEffect(() => {
    getRole(id);
    getPermissions();
  }, [getRole, getPermissions, id]);

  const saveRole = (values) => {
    return Promise.resolve(
      updateRole(id, values).then((resp) => {
        goTo(`/admin/roles/${resp.data.id}`);
      }),
    );
  };
  const resource = useSelector((state) => state.role.resources?.[id]);
  const permissions = useSelector((state) => state.role.permissions);

  return (
    <div className="container">
      <div className="content-block">
        {resource?.data?.id && permissions ? (
          <RoleForm resource={resource} permissions={permissions} saveRole={saveRole} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default connect(null, {
  getRole: actions.getRole,
  getPermissions: actions.getPermissions,
  updateRole: actions.updateRole,
  goTo: push,
})(RoleEditContainer);
