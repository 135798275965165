import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import { ConnectedRouter } from 'connected-react-router';
import { setConfig } from 'react-hot-loader';
import { hot } from 'react-hot-loader/root';
import { routes } from './routes';
// hack to get favicon into assets
// eslint-disable-next-line
import favicon from './images/favicon/favicon.ico';

const TIMEOUT = 4000;
function App({ store, context, history }) {
  // const routes = routes(store);

  // React Router does not remount components when the route changes - to force components
  // to remount we give the top level component the path as a key
  // const createElement = (Component, props) => {
  //   return <Component key={`${props.location.pathname}`} {...props}/>
  // };
  setConfig({
    reloadHooks: false,
  });
  return (
    <div>
      <ConnectedRouter
        history={history}
        context={context}
        // onUpdate={hashLinkScroll}
        // createElement={createElement}
      >
        {routes(store)}
      </ConnectedRouter>
      <ReduxToastr
        timeOut={TIMEOUT}
        newestOnTop
        progressBar
        preventDuplicates
        position="top-right"
      />
    </div>
  );
}

export default hot(App);
