import React from 'react';
import { FullRow, Button } from '../../../common/components';
import InternalButtonBar from '../../layout/components/InternalButtonBar/InternalButtonBar';
import RoleList from '../components/RoleList/RoleList';

const RoleListContainer = () => {
  return (
    <div className="container">
      <div className="content-block">
        <h1>Role List</h1>
        <FullRow>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['role.create'],
                button: (
                  <Button
                    key="new-role"
                    to="/admin/roles/new"
                    className="pull-right"
                    variant="primary"
                    label="New Role"
                  />
                ),
              },
            ]}
          />
        </FullRow>
        <RoleList />
      </div>
    </div>
  );
};

export default RoleListContainer;
