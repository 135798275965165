export const SET_VERSION = 'cega/version/SET_VERSION';
export const SET_COMPARISON = 'cega/version/SET_COMPARISON';
export const CLEAR_VERSION = 'cega/version/CLEAR_VERSION';
export const IS_SUBMITTING = 'cega/version/IS_SUBMITTING';

const initialState = {
  comparison: null,
  data: null,
  isSubmitting: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status,
      };
    case SET_VERSION:
      return {
        ...state,
        data: action.data,
      };
    case SET_COMPARISON:
      return {
        ...state,
        comparison: action.data,
      };
    case CLEAR_VERSION:
      return initialState;
    default:
      return state;
  }
}
