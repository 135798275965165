import parseISO from 'date-fns/parseISO';
import intlFormat from 'date-fns/intlFormat';

export default function formatDateTime(cell) {
  if (!cell) {
    return '';
  }
  return intlFormat(
    parseISO(cell),
    {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    {
      locale: 'en-GB',
    },
  );
}
