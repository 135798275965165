import { useEffect, useRef, useState } from 'react';
import gb from 'date-fns/locale/en-GB';
import {
  addDays,
  isAfter,
  isValid,
  parse as parseFnsDate,
  format as formatFnsDate,
  differenceInYears,
  subDays,
  isDate,
  parseISO,
  parse,
  startOfDay,
  subYears,
  addHours,
} from 'date-fns';
import React from 'react';
import * as Cleave from 'cleave.js/react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Select from 'react-select';
import { Icon } from '../';
import { Row } from '../';
import { Field } from 'redux-form';
import TimeKeeper from 'react-timekeeper';
import { InputGroup } from 'react-bootstrap';
import cx from 'classnames';

const DATE_FORMAT = 'dd/MM/yyyy HH:mm';
const DATE_ONLY_FORMAT = 'dd/MM/yyyy';
const DATE_REGEX = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/;
const DATE_ONLY_REGEX = /^\d{2}\/\d{2}\/\d{4}$/;
const YEAR_DAYS = 365;
const DEFAULT_FUTURE_DAYS_LIMIT = 90;
const DEFAULT_MIN_AGE_ADULT = 18;
const DEFAULT_MAX_AGE_ADULT = 120;

const colors = (isFocused, isSelected) => {
  if (isSelected) {
    return '#f6f6f6';
  }
  if (isFocused) {
    return '#efefef';
  }
  return null;
};

const customStyles = {
  container: (provided) => ({
    ...provided,
    display: 'inline-block',
    width: '150px',
    minHeight: '1px',
    textAlign: 'left',
    border: 'none',
    fontSize: '0.8em',
    margin: '2px',
  }),
  control: (base) => ({
    ...base,
    minHeight: '1px',
    height: '30px',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  option: (base, { isDisabled, isFocused, isSelected }) => ({
    ...base,
    backgroundColor: isDisabled ? null : colors(isFocused, isSelected),
    color: '#333',
  }),
  input: (provided) => ({
    ...provided,
    minHeight: '1px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    minHeight: '1px',
    paddingTop: '0',
    paddingBottom: '0',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    minHeight: '1px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: '1px',
    height: '30px',
    paddingTop: '0',
    paddingBottom: '0',
  }),
  singleValue: (provided) => ({
    ...provided,
    minHeight: '1px',
    paddingBottom: '1px',
  }),
};
const monthOptions = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];
const CustomOverlay = ({ children, onBlur, onFocus, classNames }) => (
  <div
    className={`DayPicker-customOverlay ${classNames.overlayWrapper}`}
    onBlur={onBlur}
    onFocus={onFocus}
  >
    <div>{children}</div>
  </div>
);

const NavBar = ({ onPreviousClick, onNextClick }) => (
  <div className="DayPicker-NavButton">
    <div className={'DayPicker-NavButton-nav-row'}>
      <div className={'DayPicker-NavButton--prev'} onClick={() => onPreviousClick()}>
        <Icon className={'DayPicker-NavButton--prev-icon'} iconName={'leftArrow'} />
      </div>
      <div onClick={() => onNextClick()} className={'DayPicker-NavButton--next'}>
        <Icon className={'DayPicker-NavButton--next-icon'} iconName={'rightArrow'} />
      </div>
    </div>
  </div>
);

const YearSelect = ({ date, inputValue, yearOptions, handleYearChange }) => {
  return (
    <div className={'DayPicker-Caption'}>
      <div>{formatFnsDate(inputValue || date, 'do LLLL yyyy')}</div>

      <div className={'DayPicker-Caption-Selects'}>
        <Select
          options={monthOptions}
          styles={customStyles}
          onChange={(e) => handleYearChange(date.getFullYear(), e.value, date.getDate())}
          value={monthOptions.find((option) => option.value === date.getMonth())}
        />

        <Select
          options={yearOptions}
          styles={customStyles}
          onChange={(e) => handleYearChange(e.value, date.getMonth(), date.getDate())}
          value={yearOptions.find((option) => option.value === date.getFullYear())}
        />
      </div>
    </div>
  );
};

const convertInputToDate = (inputValue, incTime = true) => {
  const dateRegex = incTime ? DATE_REGEX : DATE_ONLY_REGEX;
  const dateFormat = incTime ? DATE_FORMAT : DATE_ONLY_FORMAT;
  if (inputValue && !isDate(inputValue) && dateRegex.test(inputValue)) {
    return parseFnsDate(inputValue, dateFormat, new Date(), {
      locale: gb,
    });
  }
  if (isDate(inputValue)) {
    return inputValue;
  }
  // return inputValue;
  return undefined;
};

const parseDate = (str) => {
  const parsed = convertInputToDate(str);
  if (parsed && isDate(parsed) && isValid(parsed)) {
    if (parsed.getTimezoneOffset() < 0) {
      //add an hour during BST to prevent day of month becoming previous day
      const hacked = addHours(parsed, 1);
      return hacked.toISOString();
    }
    return parsed.toISOString();
  }
  return '';
};
const parseDateTime = (str) => {
  const parsed = convertInputToDate(str);
  if (parsed && isDate(parsed) && isValid(parsed)) {
    return parsed.toISOString();
  }
  return '';
};

const formatDate = (date) => {
  if (date) {
    return formatFnsDate(parseISO(date), DATE_FORMAT);
  }
  return '';
};

const parseDPDate = (str) => {
  const parsed = convertInputToDate(str);
  if (parsed && isDate(parsed) && isValid(parsed)) {
    return parsed;
  }
  return undefined;
};
const formatDPDate = (date) => {
  if (date) {
    return formatFnsDate(date, DATE_FORMAT);
  }
  return undefined;
};

const DateTimePickerInput = ({
  placeholder,
  onBlur,
  handleCloseDatePicker,
  handleToggleDatePicker,
  inputOnChange,
  inputValue,
  options,
  className,
  autoComplete,
  showTime,
  showSecond,
  timePlaceholder = 'hh:mm',
  alt,
  isDisabled,
}) => {
  const handleDateChange = (event) => {
    const value = convertInputToDate(event.target.value, false);
    if (value && handleCloseDatePicker) {
      handleCloseDatePicker();
    }
    inputOnChange(value);
  };

  const handleTimeChange = (value) => {
    inputOnChange(value);
  };

  const handleToggle = () => {
    if (!isDisabled) {
      handleToggleDatePicker();
    }
  };
  return (
    <div>
      <InputGroup>
        <Cleave
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={handleDateChange}
          value={formatDPDate(inputValue)}
          options={options}
          disabled={isDisabled}
          className={className}
          autoComplete={autoComplete}
        />
        {showTime && (
          <TimePickerInput
            inputOnChange={handleTimeChange}
            showSecond={showSecond}
            placeholder={timePlaceholder}
            inputValue={inputValue}
            className={className}
          />
        )}
        <InputGroup.Append className={'DayPickerInput-append'}>
          <InputGroup.Text className={cx(className, { alt: alt })} onClick={handleToggle}>
            <Icon iconName={'calendar'} />
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
};

const TimePickerInput = ({ inputOnChange, placeholder, inputValue }) => {
  const [showTimeKeeper, setShowTimeKeeper] = useState(false);

  const handleDone = (timeValue) => {
    if (inputValue && inputOnChange) {
      const parsedDate = isDate(inputValue)
        ? inputValue
        : parseFnsDate(inputValue, DATE_FORMAT, new Date());
      parsedDate.setHours(timeValue ? timeValue.hour : 0);
      parsedDate.setMinutes(timeValue ? timeValue.minute : 0);
      inputOnChange(parsedDate);
    }
    setShowTimeKeeper(false);
  };
  const onFocus = () => {
    setShowTimeKeeper(true);
  };

  const parsedDate = convertInputToDate(inputValue, true);
  const time = parsedDate ? formatFnsDate(parsedDate, 'HH:mm') : '00:00';
  return (
    <>
      <input
        className={cx('form-control time-input', {
          'time-input--awaiting-date': !parsedDate,
        })}
        onFocus={onFocus}
        //onChange={handleTimeChange}
        value={time}
        disabled={!parsedDate}
        placeholder={placeholder}
      />
      {showTimeKeeper && (
        <div className="TimePicker">
          <TimeKeeper
            time={time}
            hour24Mode
            onDoneClick={handleDone}
            switchToMinuteOnHourSelect={true}
            coarseMinutes={5}
            forceCoarseMinutes
          />
        </div>
      )}
    </>
  );
};

const DatePicker = ({
  input,
  placeholder,
  disabledDays,
  selectedDays,
  modifiers,
  numberOfMonths,
  className,
  onDayMouseEnter,
  handleYearChange,
  yearOptions,
  month,
  alignLeft,
  autocomplete,
  showTime,
  value,
  alt,
  isDisabled,
}) => {
  const overlayRef = useRef(null);
  const [show, setShow] = useState(false);
  useEffect(() => {
    const dateValue = input.value ? input.value : value;
    if (dateValue && !isDate(dateValue)) {
      const parsedDate = parseFnsDate(dateValue, DATE_FORMAT, new Date());
      input.onChange(formatFnsDate(parsedDate, DATE_FORMAT));
    }
  }, []);

  const handleCloseDatePicker = () => {
    setShow(false);
    overlayRef.current.hideAfterDayClick();
  };

  const handleToggleDatePicker = () => {
    if (show) {
      overlayRef.current.hideAfterDayClick();
    } else {
      overlayRef.current.showDayPicker();
    }
    setShow(!show);
  };

  const handleOnChange = (value) => {
    const startOfDate = value ? startOfDay(value) : value;
    input.onChange(startOfDate);
    if (value) {
      if (handleYearChange) {
        handleYearChange(value.getFullYear(), value.getMonth(), value.getDate());
      }
    }
  };

  const handleInputChange = (value) => {
    if (input) {
      input.onChange(value);
    }
    if (value && isDate(value)) {
      if (handleYearChange) {
        handleYearChange(value.getFullYear(), value.getMonth(), value.getDate());
      }
    }
  };

  const dateValue = input.value ? input.value : value;
  const inputValue = input.value ? convertInputToDate(dateValue, true) : '';

  return (
    <div className={'DayPicker-container'}>
      <DayPickerInput
        inputProps={{
          inputValue: inputValue,
          inputOnChange: handleInputChange,
          options: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
          className: 'form-control',
          autoComplete: autocomplete,
          showTime: showTime,
          showSecond: false,
          handleCloseDatePicker: handleCloseDatePicker,
          handleToggleDatePicker: handleToggleDatePicker,
          alt: alt,
          isDisabled: isDisabled,
        }}
        component={DateTimePickerInput}
        onDayChange={handleOnChange}
        formatDate={formatDPDate}
        format={DATE_FORMAT}
        parseDate={parseDPDate}
        ref={overlayRef}
        overlayComponent={CustomOverlay}
        classNames={{ overlay: 'overlay', overlayWrapper: alignLeft ? 'pull-left' : '' }}
        alignLeft={alignLeft}
        placeholder={placeholder}
        keepFocus={false}
        value={value}
        dayPickerProps={{
          month: month,
          className: className,
          disabledDays: disabledDays,
          modifiers: modifiers,
          selectedDays: selectedDays,
          numberOfMonths: numberOfMonths,
          onDayMouseEnter: onDayMouseEnter,
          navbarElement: NavBar,
          isDisabled: isDisabled,
          captionElement: yearOptions ? (
            <YearSelect
              handleYearChange={handleYearChange}
              yearOptions={yearOptions}
              inputValue={inputValue}
            />
          ) : (
            undefined
          ),
        }}
      />
    </div>
  );
};
const StartDatePicker = ({
  input,
  meta,
  placeholder = 'dd/mm/yyyy',
  endDate,
  alignLeft,
  showTime,
}) => {
  const [end, setEnd] = useState(endDate ? parseISO(endDate) : new Date());
  useEffect(() => {
    if (endDate) {
      setEnd(parseISO(endDate));
    }
  }, [endDate]);

  const now = new Date();
  const lastDate = addDays(now, YEAR_DAYS);
  const disabledDays = { before: end, after: lastDate };

  const selectedDate = convertInputToDate(input.value);
  const modifiers = { start: selectedDate };

  const selectedDays = [selectedDate];
  const showError = meta && meta.touched;

  return (
    <>
      <DatePicker
        input={input}
        className={'Range'}
        placeholder={placeholder}
        disabledDays={disabledDays}
        modifiers={modifiers}
        selectedDays={selectedDays}
        numberOfMonths={2}
        alignLeft={alignLeft}
        showTime={showTime}
      />

      {showError && meta.error && <span className={'input-error'}>{meta.error}</span>}
    </>
  );
};

const EndDatePicker = ({
  input,
  meta,
  placeholder = 'dd/mm/yyyy',
  startDate,
  alignLeft,
  showTime,
}) => {
  const [end, setEnd] = useState(convertInputToDate(input.value));
  const [start, setStart] = useState(parseISO(startDate));

  useEffect(() => {
    setStart(parseISO(startDate));
    if (!input.value) {
      const startDateInput = parseISO(startDate);
      if (startDateInput && isValid(startDateInput)) {
        setEnd(startDateInput);
      }
    }
  }, [startDate]);

  useEffect(() => {
    const inputValue = convertInputToDate(input.value);
    if (inputValue && isValid(inputValue)) {
      setEnd(inputValue);
    }
  }, [input.value]);

  const handleDayMouseEnter = (day) => {
    if (isAfter(day, start)) {
      setEnd(day);
    }
  };

  const endDate = convertInputToDate(input.value, 'dd/MM/yyyy');
  const selectedDays = [start, { from: start, to: end }];
  const modifiers = { start: start, end: end, endDate: endDate };
  const disabledDays = { before: start };
  const showError = meta && meta.touched;

  return (
    <>
      <DatePicker
        input={input}
        //month={end ? end : undefined}
        placeholder={placeholder}
        className={'Range'}
        disabledDays={disabledDays}
        selectedDays={selectedDays}
        modifiers={modifiers}
        onDayMouseEnter={handleDayMouseEnter}
        numberOfMonths={2}
        alignLeft={alignLeft}
        showTime={showTime}
      />
      {showError && meta.error && <span className={'input-error'}>{meta.error}</span>}
    </>
  );
};

const YearDatePicker = ({
  input,
  meta,
  placeholder,
  minDate,
  maxDate,
  autocomplete,
  showTime,
  isDisabled,
}) => {
  const [date, setDate] = useState(convertInputToDate(input.value) || maxDate);
  const disabledDays = { before: minDate, after: maxDate };
  const minYear = minDate.getFullYear();
  const numberOfYears = differenceInYears(maxDate, minDate) + 1;
  const yearRange = [...Array(numberOfYears).keys()].map((i) => i + minYear).reverse();

  const selectedDays = [convertInputToDate(input.value)];
  const yearOptions = yearRange.map((year) => ({ value: year, label: year }));
  const handleYearChange = (year, month, day) => {
    const newDate = new Date(year, month, day);
    setDate(newDate);
  };
  const showError = meta && meta.touched;
  return (
    <>
      <DatePicker
        input={input}
        month={date}
        fromMonth={date}
        className={'Year'}
        placeholder={placeholder}
        disabledDays={disabledDays}
        selectedDays={selectedDays}
        numberOfMonths={1}
        yearOptions={yearOptions}
        handleYearChange={handleYearChange}
        autocomplete={autocomplete}
        showTime={showTime}
        isDisabled={isDisabled}
      />
      {showError && meta.error && <span className={'input-error'}>{meta.error}</span>}
    </>
  );
};

const YearDatePickerField = ({
  name,
  validate,
  placeholder,
  maxDate,
  minDate,
  autocomplete,
  showTime,
  isDisabled,
}) => (
  <Field
    parse={showTime ? parseDateTime : parseDate}
    format={formatDate}
    component={YearDatePicker}
    name={name}
    validate={validate}
    placeholder={placeholder}
    maxDate={maxDate}
    minDate={minDate}
    autocomplete={autocomplete}
    showTime={showTime}
    isDisabled={isDisabled}
  />
);

const FutureDatePicker = ({
  input,
  backdateDays,
  meta,
  placeholder = 'dd/mm/yyyy',
  maxDate,
  autocomplete,
}) => {
  const [date, setDate] = useState(convertInputToDate(input.value) || new Date());
  const lastDate = maxDate ? maxDate : addDays(new Date(), YEAR_DAYS);
  const firstDate = backdateDays ? subDays(new Date(), backdateDays) : new Date();
  const disabledDays = { before: firstDate, after: lastDate };
  const minYear = firstDate.getFullYear();

  const numberOfYears = differenceInYears(firstDate, lastDate) + 1;
  const yearRange = [...Array(numberOfYears).keys()].map((i) => i + minYear).reverse();

  const selectedDays = [convertInputToDate(input.value)];
  const yearOptions = yearRange.map((year) => ({ value: year, label: year }));
  const handleYearChange = (year, month, day) => {
    const newDate = new Date(year, month, day);
    setDate(newDate);
  };
  const showError = meta && meta.touched;

  return (
    <>
      <DatePicker
        input={input}
        month={date}
        fromMonth={date}
        className={'Year'}
        placeholder={placeholder}
        disabledDays={disabledDays}
        selectedDays={selectedDays}
        numberOfMonths={1}
        yearOptions={yearOptions}
        handleYearChange={handleYearChange}
        autocomplete={autocomplete}
      />
      {showError && meta.error && <span className={'input-error'}>{meta.error}</span>}
    </>
  );
};

const FutureDatePickerField = ({
  name,
  validate,
  placeholder,
  backdateDays,
  maxDate,
  autocomplete,
  handleOnChange,
}) => (
  <Field
    parse={parseDate}
    format={formatDate}
    component={FutureDatePicker}
    name={name}
    validate={validate}
    placeholder={placeholder}
    backdateDays={backdateDays}
    autocomplete={autocomplete}
    maxDate={maxDate}
    onChange={handleOnChange}
  />
);

const FutureDatePickerRow = ({
  labelSize,
  label,
  fieldSize,
  fieldOffset,
  name,
  validate,
  placeholder,
  backdateDays,
  maxDate = addDays(new Date(), DEFAULT_FUTURE_DAYS_LIMIT),
  autocomplete,
  handleOnChange,
}) => {
  return (
    <Row
      labelSize={labelSize}
      label={label}
      fieldSize={fieldSize}
      fieldOffset={fieldOffset}
    >
      <FutureDatePickerField
        name={name}
        validate={validate}
        placeholder={placeholder}
        backdateDays={backdateDays}
        autocomplete={autocomplete}
        maxDate={maxDate}
        handleOnChange={handleOnChange}
      />
    </Row>
  );
};

const StartDatePickerField = ({ name, validate, placeholder, alignLeft, showTime }) => (
  <Field
    parse={showTime ? parseDateTime : parseDate}
    format={formatDate}
    component={StartDatePicker}
    name={name}
    validate={validate}
    placeholder={placeholder}
    alignLeft={alignLeft}
    showTime={showTime}
  />
);

const EndDatePickerField = ({
  name,
  validate,
  placeholder,
  startDate,
  alignLeft,
  showTime,
}) => (
  <Field
    parse={showTime ? parseDateTime : parseDate}
    format={formatDate}
    component={EndDatePicker}
    name={name}
    validate={validate}
    placeholder={placeholder}
    startDate={startDate}
    alignLeft={alignLeft}
    showTime={showTime}
  />
);

const YearDatePickerRow = ({
  labelSize,
  labelSizes,
  label,
  fieldSize,
  fieldSizes,
  fieldOffset,
  fieldOffsets,
  name,
  validate,
  placeholder = 'dd/mm/yyyy',
  maxDate = subYears(new Date(), DEFAULT_MIN_AGE_ADULT),
  minDate = subYears(new Date(), DEFAULT_MAX_AGE_ADULT),
  autocomplete,
  showTime,
  isDisabled,
}) => {
  return (
    <Row
      labelSizes={labelSizes}
      labelSize={labelSize}
      label={label}
      fieldSizes={fieldSizes}
      fieldSize={fieldSize}
      fieldOffset={fieldOffset}
      fieldOffsets={fieldOffsets}
    >
      <YearDatePickerField
        name={name}
        validate={validate}
        placeholder={placeholder}
        maxDate={maxDate}
        minDate={minDate}
        autocomplete={autocomplete}
        showTime={showTime}
        isDisabled={isDisabled}
      />
    </Row>
  );
};

const StartDatePickerRow = ({
  labelSize,
  labelSizes,
  label,
  fieldSize,
  fieldSizes,
  fieldOffset,
  fieldOffsets,
  name,
  input,
  alignLeft,
  validate,
  showTime,
}) => {
  return (
    <Row
      labelSize={labelSize}
      labelSizes={labelSizes}
      label={label}
      fieldSize={fieldSize}
      fieldSizes={fieldSizes}
      fieldOffset={fieldOffset}
      fieldOffsets={fieldOffsets}
    >
      <StartDatePickerField
        input={input}
        name={name}
        alignLeft={alignLeft}
        validate={validate}
        showTime={showTime}
      />
    </Row>
  );
};

const EndDatePickerRow = ({
  labelSize,
  labelSizes,
  label,
  fieldSize,
  fieldSizes,
  fieldOffset,
  fieldOffsets,
  name,
  input,
  alignLeft,
  validate,
  startDate,
  placeholder,
  showTime,
}) => {
  return (
    <Row
      labelSize={labelSize}
      labelSizes={labelSizes}
      label={label}
      fieldSize={fieldSize}
      fieldSizes={fieldSizes}
      fieldOffset={fieldOffset}
      fieldOffsets={fieldOffsets}
    >
      <EndDatePickerField
        input={input}
        name={name}
        alignLeft={alignLeft}
        validate={validate}
        placeholder={placeholder}
        startDate={startDate}
        showTime={showTime}
      />
    </Row>
  );
};

const DateInput = ({ handleOnChange }) => {
  const onChange = (event) => {
    const value = event.target.value;
    if (value && DATE_REGEX.test(value)) {
      const parsedDate = parse(value, DATE_FORMAT, new Date());
      handleOnChange(startOfDay(parsedDate).toISOString());
    }
    if (!value) {
      handleOnChange(value);
    }
  };
  return (
    <Cleave
      className={'form-control'}
      onChange={onChange}
      options={{
        date: true,
        delimiter: '/',
        datePattern: ['d', 'm', 'Y'],
      }}
    />
  );
};
export {
  StartDatePicker,
  StartDatePickerField,
  StartDatePickerRow,
  EndDatePicker,
  EndDatePickerField,
  EndDatePickerRow,
  DatePicker,
  FutureDatePicker,
  FutureDatePickerRow,
  YearDatePicker,
  YearDatePickerField,
  YearDatePickerRow,
  DateInput,
};
