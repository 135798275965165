import React from 'react';
import { connect } from 'react-redux';
import { passwordReminder } from '../redux/authActions';
import PasswordReminderForm from '../components/PasswordReminderForm/PasswordReminderForm';

const PasswordReminderContainer = ({ dispatch }) => {
  const handlePasswordReminderSubmit = (values) => {
    return dispatch(passwordReminder(values.email));
  };
  return (
    <div>
      <PasswordReminderForm onSubmit={handlePasswordReminderSubmit} />
    </div>
  );
};

export default connect()(PasswordReminderContainer);
