import React from 'react';
import { useSelector } from 'react-redux';
import { BackButton, Button, FullRow, LabelRow } from '../../../../common/components';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import formatDate from '../../../../helpers/formatDate';

const LABEL_SIZES = 2;
const FIELD_SIZES = 10;

const UserDetails = ({ resource, disableUser, enableUser, sendResetEmail }) => {
  const resourceAttributes = resource.data.attributes;
  const organisation = getIncludedResource(
    resource.data,
    resource.included,
    'organisations',
  )
    .map((organisation) => organisation.attributes.name)
    .pop();
  const roles = getIncludedResource(resource.data, resource.included, 'roles')
    .map((role) => role.attributes.name)
    .join(', ');

  const isResetting = useSelector((state) => state.user.isResetting);
  const isDeactivating = useSelector((state) => state.user.isDeactivating);
  const isReactivating = useSelector((state) => state.user.isReactivating);

  return (
    <>
      <h2 className="resource-name">
        {resourceAttributes.name} {resourceAttributes.disabled && ' (disabled)'}
      </h2>

      <LabelRow label="Name" labelSizes={LABEL_SIZES} fieldSizes={FIELD_SIZES}>
        {resourceAttributes.name}
      </LabelRow>

      <LabelRow label="Email" labelSizes={LABEL_SIZES} fieldSizes={FIELD_SIZES}>
        {resourceAttributes.email}
      </LabelRow>

      <LabelRow label="Organisation" fieldSizes={FIELD_SIZES} labelSizes={LABEL_SIZES}>
        {organisation}
      </LabelRow>

      <LabelRow label="Roles" fieldSizes={FIELD_SIZES} labelSizes={LABEL_SIZES}>
        {roles}
      </LabelRow>

      <LabelRow label="Status" fieldSizes={FIELD_SIZES} labelSizes={LABEL_SIZES}>
        {resourceAttributes.disabled ? 'Disabled' : 'Enabled'}
      </LabelRow>

      <LabelRow label="Created At" fieldSizes={FIELD_SIZES} labelSizes={LABEL_SIZES}>
        {formatDate(resourceAttributes.created_at)}
      </LabelRow>

      <FullRow>
        <InternalButtonBar
          buttons={[
            {
              permissions: ['user.edit'],
              button: (
                <Button
                  key="edit-user"
                  to={`/admin/users/${resource.data.id}/edit`}
                  className="pull-right"
                  bsStyle="primary"
                  rightIcon="edit"
                  label="Edit User"
                />
              ),
            },
            {
              filter: [{ value: resourceAttributes.disabled, filterValues: [false] }],
              permissions: ['user.view'],
              button: (
                <Button
                  label="Send Password Reset Email"
                  rightIcon="email"
                  variant="primary"
                  className="pull-right"
                  key="send-password-reset"
                  isLoading={isResetting}
                  handleClick={sendResetEmail}
                />
              ),
            },
            {
              permissions: ['user.edit'],
              filter: [{ value: resourceAttributes.disabled, filterValues: [false] }],
              button: (
                <Button
                  key="disable-user"
                  className="pull-right"
                  label="Disable User"
                  rightIcon="ban"
                  handleClick={disableUser}
                  isLoading={isDeactivating}
                />
              ),
            },
            {
              permissions: ['user.edit'],
              filter: [{ value: resourceAttributes.disabled, filterValues: [true] }],
              button: (
                <Button
                  key="enable-user"
                  className="pull-right"
                  label="Enable User"
                  rightIcon="check"
                  handleClick={enableUser}
                  isLoading={isReactivating}
                />
              ),
            },
            {
              button: <BackButton key="back" to="/admin/users" />,
            },
          ]}
        />
      </FullRow>
    </>
  );
};

export default UserDetails;
