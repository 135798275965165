import React from 'react';
import {
  FaSync,
  FaCaretRight,
  FaCaretLeft,
  FaSpinner,
  FaPlane,
  FaPlus,
  FaPercent,
  FaFilePdf,
  FaRegCopy,
  FaLock,
  FaUserFriends,
  FaFile,
  FaQuestionCircle,
  FaTimes,
  FaTrashAlt,
  FaRegCheckSquare,
  FaExchangeAlt,
  FaArrowAltCircleRight,
  FaArrowAltCircleLeft,
  FaBan,
  FaFileDownload,
  FaFileUpload,
  FaShare,
  FaUser,
  FaClone,
  FaPlusCircle,
  FaEnvelopeOpen,
  FaEnvelopeOpenText,
  FaSms,
  FaMinus,
  FaThumbsUp,
  FaBomb,
  FaList,
  FaSave,
  FaInfoCircle,
  FaChevronLeft,
  FaBars,
  FaSignOutAlt,
  FaHome,
  FaCog,
  FaCogs,
  FaTasks,
  FaSitemap,
  FaPiggyBank,
  FaChartBar,
  FaChevronRight,
  FaCheckCircle,
  FaRegCircle,
  FaBullhorn,
  FaBell,
  FaSignInAlt,
  FaWrench,
  FaDesktop,
  FaToolbox,
  FaPoundSign,
  FaEnvelope,
  FaChevronDown,
  FaChevronUp,
  FaUnlock,
  FaCalendarAlt,
  FaCircle,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaTable,
  FaRegSquare,
  FaFolder,
  FaFolderOpen,
  FaClock,
  FaCar,
  FaRegArrowAltCircleUp,
  FaExclamationCircle,
  FaHeartbeat,
  FaBook,
} from 'react-icons/fa';
import {
  MdPeople,
  MdPerson,
  MdSearch,
  MdInsertDriveFile,
  MdFeedback,
  MdChat,
  MdEdit,
} from 'react-icons/md';

const LeftArrow = ({ className }) => {
  return <FaArrowAltCircleLeft className={className} />;
};

const RightArrow = ({ className }) => {
  return <FaArrowAltCircleRight className={className} />;
};

const Lock = ({ className }) => <FaLock className={className} />;
const UnLock = ({ className }) => <FaUnlock className={className} />;

const Envelope = ({ className }) => <FaEnvelope className={className} />;

const Gbp = ({ className }) => <FaPoundSign className={className} />;
//

// const Trash = ({ width, className }) => (
//   <svg
//     width={width}
//     className={className}
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 448 512"
//   >
//     <path
//       d="M32 464a48 48 0 0048 48h288a48 48 0 0048-48V128H32zm272-256a16 16 0 0132 0v224a16 16 0 01-32 0zm-96 0a16 16 0 0132 0v224a16 16 0 01-32 0zm-96 0a16 16 0 0132 0v224a16 16 0 01-32 0zM432 32H312l-9.4-18.7A24 24 0 00281.1 0H166.8a23.72 23.72 0 00-21.4 13.3L136 32H16A16 16 0 000 48v32a16 16 0 0016 16h416a16 16 0 0016-16V48a16 16 0 00-16-16z"
//     />
//   </svg>
//   );

const Trash = ({ className }) => <FaTrashAlt className={className} />;
const Minimize = ({ width, className }) => (
  <svg
    width={width}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
  </svg>
);
const Maximize = ({ className }) => <FaPlus className={className} />;
const Percent = ({ className }) => <FaPercent className={className} />;

const Check = ({ width, className }) => (
  <svg
    className={className}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
  </svg>
);

const Refresh = ({ width, className }) => <FaSync width={width} className={className} />;

const Spinner = ({ width, className }) => (
  <FaSpinner width={width} className={className} />
);

const Next = ({ width, className }) => (
  <FaCaretRight size={width} className={className} />
);
const Previous = ({ width, className }) => (
  <FaCaretLeft size={width} className={className} />
);

const View = ({ width, className }) => (
  <svg
    className={className}
    // style={{height: '20px', width: '20px', textAlign: 'center', verticalAlign: 'middle', marginLeft: '5px'}}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    data-icon="eye"
    data-prefix="fas"
    viewBox="0 0 576 512"
  >
    <path
      fill="currentColor"
      d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 000 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 000-29.19zM288 400a144 144 0 11144-144 143.93 143.93 0 01-144 144zm0-240a95.31 95.31 0 00-25.31 3.79 47.85 47.85 0 01-66.9 66.9A95.78 95.78 0 10288 160z"
    />
  </svg>
);
const Group = ({ className }) => <MdPeople className={className} />;

const Individual = ({ className }) => <MdPerson className={className} />;

const Search = ({ className }) => <MdSearch className={className} />;

const Download = ({ className }) => <MdInsertDriveFile className={className} />;

const Feedback = ({ className }) => <MdFeedback className={className} />;

const Chat = ({ className }) => <MdChat className={className} />;
const Plane = ({ className }) => <FaPlane className={className} />;
const Pdf = ({ className }) => <FaFilePdf className={className} />;

const File = ({ className }) => <FaFile className={className} />;

const QuestionCircle = ({ className }) => <FaQuestionCircle className={className} />;
const Post = ({ className }) => <FaEnvelopeOpen className={className} />;
const Email = ({ className }) => <FaEnvelopeOpenText className={className} />;

const SMS = ({ className }) => <FaSms className={className} />;

const Times = ({ className }) => <FaTimes className={className} />;

const CheckSquare = ({ className }) => <FaRegCheckSquare className={className} />;

const Exchange = ({ className }) => <FaExchangeAlt className={className} />;

const Ban = ({ className }) => <FaBan className={className} />;

const FileDownload = ({ className }) => <FaFileDownload className={className} />;

const FileUpload = ({ className }) => <FaFileUpload className={className} />;

const Edit = ({ className }) => <MdEdit className={className} />;

const Share = ({ className }) => <FaShare className={className} />;
const User = ({ className }) => <FaUser className={className} />;
const Clone = ({ className }) => <FaClone className={className} />;

const PlusCircle = ({ className }) => <FaPlusCircle className={className} />;
const Remove = ({ className }) => <FaMinus className={className} />;
const ThumbsUp = ({ className }) => <FaThumbsUp className={className} />;
const Bomb = ({ className }) => <FaBomb className={className} />;
const List = ({ className }) => <FaList className={className} />;
const ExpandSquare = ({ className }) => <FaChevronDown className={className} />;
const CollapseSquare = ({ className }) => <FaChevronUp className={className} />;

const InfoCircle = ({ className }) => <FaInfoCircle className={className} />;

const Bars = ({ className }) => <FaBars className={className} />;

const Cog = ({ className }) => <FaCog className={className} />;
const Cogs = ({ className }) => <FaCogs className={className} />;

const Signout = ({ className }) => <FaSignOutAlt className={className} />;

const Signin = ({ className }) => <FaSignInAlt className={className} />;

const Home = ({ className }) => <FaHome className={className} />;

const Tasks = ({ className }) => <FaTasks className={className} />;
const Organisations = ({ className }) => <FaSitemap className={className} />;
const Bank = ({ className }) => <FaPiggyBank className={className} />;
const Chart = ({ className }) => <FaChartBar className={className} />;

const ChevronRight = ({ className }) => <FaChevronRight className={className} />;
const ChevronLeft = ({ className }) => <FaChevronLeft className={className} />;
const CheckedCircle = ({ className }) => <FaCheckCircle className={className} />;
const Circle = ({ className }) => <FaRegCircle className={className} />;
const Announcement = ({ className }) => <FaBullhorn className={className} />;

const Reminder = ({ className }) => <FaBell className={className} />;
const Save = ({ className }) => <FaSave className={className} />;
const NonManual = ({ className }) => <FaDesktop className={className} />;
const LightManual = ({ className }) => <FaToolbox className={className} />;
const HeavyManual = ({ className }) => <FaWrench className={className} />;
const Content = ({ className }) => <FaRegCopy className={className} />;
const Users = ({ className }) => <FaUserFriends className={className} />;
const Calendar = ({ className }) => <FaCalendarAlt className={className} />;

const LoaderDot = ({ className }) => <FaCircle className={className} />;

const MoveLeft = ({ className }) => <FaAngleDoubleLeft className={className} />;
const MoveRight = ({ className }) => <FaAngleDoubleRight className={className} />;
const Rates = ({ className }) => <FaTable className={className} />;
const UnChecked = ({ className }) => <FaRegSquare className={className} />;
const Folder = ({ className }) => <FaFolder className={className} />;
const FolderOpen = ({ className }) => <FaFolderOpen className={className} />;

const Clock = ({ className }) => <FaClock className={className} />;

const Car = ({ className }) => <FaCar className={className} />;

const ArrowUp = ({ className }) => <FaRegArrowAltCircleUp className={className} />;

const Warning = ({ className }) => <FaExclamationCircle className={className} />;

const Heartbeat = ({ className }) => <FaHeartbeat className={className} />;

const Book = ({ className }) => <FaBook className={className} />;

const iconMap = {
  lock: Lock,
  unlock: UnLock,
  envelope: Envelope,
  rightArrow: RightArrow,
  leftArrow: LeftArrow,
  spinner: Spinner,
  gbp: Gbp,
  trash: Trash,
  minimize: Minimize,
  maximize: Maximize,
  plus: Maximize,
  percent: Percent,
  check: Check,
  refresh: Refresh,
  next: Next,
  previous: Previous,
  view: View,
  group: Group,
  individual: Individual,
  search: Search,
  feedback: Feedback,
  download: Download,
  chat: Chat,
  plane: Plane,
  file: File,
  question: QuestionCircle,
  post: Post,
  email: Email,
  sms: SMS,
  no: Times,
  yes: Check,
  issue: CheckSquare,
  ban: Ban,
  exchange: Exchange,
  pdf: Pdf,
  fileDownload: FileDownload,
  fileUpload: FileUpload,
  edit: Edit,
  unlapse: Share,
  user: User,
  clone: Clone,
  new: PlusCircle,
  times: Times,
  remove: Remove,
  add: Maximize,
  activate: ThumbsUp,
  deactivate: Bomb,
  list: List,
  collapse: CollapseSquare,
  expand: ExpandSquare,
  infoCircle: InfoCircle,
  bars: Bars,
  close: Times,
  signout: Signout,
  signin: Signin,
  home: Home,
  management: Tasks,
  organisations: Organisations,
  accounts: Bank,
  chart: Chart,
  cog: Cog,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  checkedCircle: CheckedCircle,
  circle: Circle,
  announcement: Announcement,
  reminder: Reminder,
  contentManagement: Content,
  productManagement: Cogs,
  roleManangement: Lock,
  userManangement: Users,
  save: Save,
  nonManual: NonManual,
  lightManual: LightManual,
  heavyManual: HeavyManual,
  calendar: Calendar,
  loaderDot: LoaderDot,
  moveLeft: MoveLeft,
  moveRight: MoveRight,
  rates: Rates,
  checked: CheckSquare,
  unchecked: UnChecked,
  folder: Folder,
  folderOpen: FolderOpen,
  scheduled: Clock,
  car: Car,
  upgrade: ArrowUp,
  warning: Warning,
  heartbeat: Heartbeat,
  book: Book,
};
const Icon = ({ iconName, fill, className }) => {
  if (!iconMap[iconName]) {
    return null; //  (<span/>);
  }
  const MappedIcon = iconMap[iconName];
  return <MappedIcon fill={fill || '#FFFFFF'} className={`icon ${className}`} />;
};

export default Icon;
