import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import actions from '../redux/userActions';
import { Loader } from '../../../common/components';
import UserForm from '../components/UserForm/UserForm';

const UserEditContainer = ({ getUser, updateUser, goTo }) => {
  const { id } = useParams();

  useEffect(() => {
    getUser(id);
  }, [getUser, id]);

  const saveUser = (values) => {
    return Promise.resolve(
      updateUser(id, values).then((resp) => {
        goTo(`/admin/users/${resp.data.id}`);
      }),
    );
  };

  const resource = useSelector((state) => state.user.resources?.[id]);

  return (
    <div className="container">
      <div className="content-block">
        {resource?.data?.id ? (
          <UserForm resource={resource} saveUser={saveUser} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default connect(null, {
  getUser: actions.getUser,
  updateUser: actions.updateUser,
  goTo: push,
})(UserEditContainer);
