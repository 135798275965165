import React, { useState } from 'react';
import { BackButton, Button, FullRow, LabelRow } from '../../../../common/components';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import VersionComparison from './VersionComparison';
import isEmpty from '../../../../helpers/isEmpty';
import formatDate from '../../../../helpers/formatDate';
import VersionReleaseModal from './VersionReleaseModal';
import { connect, useSelector } from 'react-redux';
import actions from '../../redux/versionActions';

const LABEL_SIZES = 2;
const FIELD_SIZES = 10;

const VersionDetails = ({ resource, comparison, exportRelease }) => {
  const resourceAttributes = resource.data.attributes;
  const comparisonAttributes = comparison.data.attributes;
  const isExporting = useSelector((state) => state.version.isSubmitting);

  const handleExport = () => {
    exportRelease(resource.data.id);
  };

  const noChanges =
    isEmpty(comparisonAttributes.new) &&
    isEmpty(comparisonAttributes.deleted) &&
    isEmpty(comparisonAttributes.changed);

  return (
    <>
      <h2 className="resource-name">{resourceAttributes.version}</h2>

      <LabelRow label="Description" labelSizes={LABEL_SIZES} fieldSizes={FIELD_SIZES}>
        {resourceAttributes.description}
      </LabelRow>
      {resourceAttributes.release_date && (
        <LabelRow label="Release Date" labelSizes={LABEL_SIZES} fieldSizes={FIELD_SIZES}>
          {formatDate(resourceAttributes.release_date)}
        </LabelRow>
      )}
      <hr />
      <VersionComparison comparison={comparisonAttributes} noChanges={noChanges} />

      <FullRow>
        <InternalButtonBar
          buttons={[
            {
              permissions: ['medical_library.version'],
              filter: [{ value: resourceAttributes.release_date, filterValues: [null] }],
              button: (
                <Button
                  key="edit-version"
                  to={`/library/versions/${resource.data.id}/edit`}
                  className="pull-right"
                  bsStyle="primary"
                  rightIcon="edit"
                  label="Edit Version Description"
                />
              ),
            },
            {
              permissions: ['medical_library.version'],
              button: (
                <Button
                  key="export-version"
                  className="pull-right"
                  bsStyle="primary"
                  rightIcon="download"
                  label="Download Library Version"
                  handleClick={handleExport}
                  isLoading={isExporting}
                />
              ),
            },
            {
              permissions: ['medical_library.version'],
              filter: [
                { value: resourceAttributes.release_date, filterValues: [null] },
                { value: noChanges, filterValues: [false] },
              ],
              button: <VersionReleaseModal id={resource.data.id} />,
            },
            {
              button: <BackButton key="back" to="/library/versions" />,
            },
          ]}
        />
      </FullRow>
    </>
  );
};

export default connect(null, {
  exportRelease: actions.exportRelease,
})(VersionDetails);
