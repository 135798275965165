import React from 'react';
import { hasPermission } from '../../../auth/redux/authActions';
import ScreeningScoreSettings from './ScreeningScoreSettings';
import ScreeningScoreScoringSummary from './ScreeningScoreScoringSummary';
import ScreeningScoreConditionBreakdown from './ScreeningScoreConditionBreakdown';

const ScreeningScore = ({ sessionData }) => {
  const showBreakdown = hasPermission('screening.view_breakdown');
  const settings = sessionData.settings ?? {};
  const showAltitudeExclusions = settings.altitudeExclusions ?? false;

  if (sessionData.state !== 'finalised') {
    return (
      <div>
        <p className="text-center">Screening Session was not finished.</p>
      </div>
    );
  }

  return (
    <div>
      {showBreakdown && <ScreeningScoreSettings settings={settings} />}
      <ScreeningScoreScoringSummary
        showBreakdown={showBreakdown}
        showAltitudeExclusions={showAltitudeExclusions}
        sessionData={sessionData}
      />
      <ScreeningScoreConditionBreakdown
        showBreakdown={showBreakdown}
        sessionData={sessionData}
        settings={settings}
      />
    </div>
  );
};

export default ScreeningScore;
