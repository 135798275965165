export default function isEmpty(value) {
  if (value == null) {
    return true;
  }
  if (Array.isArray(value) || typeof value == 'string') {
    if (value.length <= 0) {
      return true;
    }
  }
  if (typeof value == 'object') {
    return Object.getOwnPropertyNames(value).length === 0;
  }
  return false;
}
