import React from 'react';
import { connect } from 'react-redux';
import { login } from '../../auth/redux/authActions';
import LoginForm from '../../auth/components/LoginForm/LoginForm';

const HomepageContainer = ({ dispatch }) => {
  const onLogin = (email, password) => {
    return dispatch(login(email, password));
  };
  return (
    <div>
      <LoginForm onLogin={onLogin} />
    </div>
  );
};

export default connect(null, null)(HomepageContainer);
