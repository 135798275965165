import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { FormattedDate, FormattedTime, FormattedRelativeTime } from 'react-intl';

const ScreeningEvents = ({ events }) => {
  return (
    <div>
      <h2>Event Log</h2>
      <ListGroup>
        {events.map((event, key) => {
          const timeElapsed =
            Math.floor((Date.now() - new Date(event.recordedAt)) / 1000 / 60 / 60) * -1;
          return (
            <ListGroupItem key={key}>
              <div className="pull-right">
                Recorded: <FormattedDate value={new Date(event.recordedAt)} />{' '}
                <FormattedTime value={new Date(event.recordedAt)} /> (
                <FormattedRelativeTime value={timeElapsed} unit="hour" />)
              </div>
              {event.description}
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </div>
  );
};

export default ScreeningEvents;
