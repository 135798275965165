import React, { useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Icon,
  InputField,
  ButtonBar,
  Button,
  FullRow,
} from '../../../../common/components';
import { Row, Col, Card, Table } from 'react-bootstrap';
import QuestionModal from './QuestionModal';
import uuid from 'uuid';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import QuestionAnswersSection from './QuestionAnswersSection';
import './QuestionsSection.scss';

const getItemStyle = (isDragging, draggableStyle) => ({
  boxShadow: isDragging && '0 4px 28px rgba(0,0,0,0.3)',
  margin: '0 0 15px 0',
  borderRadius: '4px',
  ...draggableStyle,
});

const QUESTION_LIMIT = 100;
const LABEL_SIZES = [4, 4, 3, 3, 3];
const FIELD_SIZES = [8, 8, 9, 9, 9];

const Question = ({
  index,
  input,
  handleRemove,
  handleEdit,
  question,
  preloadedForwardConditions,
}) => {
  return (
    <div className="dnd-item-container">
      <Card className="permissions-container question-panels">
        <Card.Title className="permissions-title removable-item">
          <div className="item-section">
            {index + 1}: {question?.text}
          </div>
          <div className="remove-section">
            <div onClick={() => handleEdit(index)}>
              <Icon iconName="edit" />
            </div>
            <div onClick={() => handleRemove(index)}>
              <Icon iconName="trash" />
            </div>
          </div>
        </Card.Title>
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>Answer</th>
                <th>Severity Score</th>
                <th>Next Question</th>
                <th>Forward Condition</th>
              </tr>
            </thead>
            <FieldArray
              name={`data.meta.questions[${index}].answers`}
              component={QuestionAnswersSection}
              answers={question?.answers ?? []}
              preloadedForwardConditions={preloadedForwardConditions}
            />
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

const QuestionsSection = ({
  fields,
  reorderQuestions,
  returnMedicalAnswerSetDetails,
  questions,
  preloadedForwardConditions,
}) => {
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [questionModalInitialValues, setQuestionModalInitialValues] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    reorderQuestions(fields.name, result.source.index, result.destination.index);
  };

  const handleRemove = (index) => {
    fields.remove(index);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    const question = {
      type: 'medical-question',
      id: questions[index]?.id,
      attributes: {
        question: questions[index]?.text,
      },
    };
    const answerSet = {
      type: 'medical-answer-set',
      id: questions[index]?.answerSetId,
    };

    setQuestionModalInitialValues({
      question,
      answerSet,
    });

    setShowQuestionModal(true);
  };

  const showNewQuestionModal = () => {
    setEditIndex(-1);
    setQuestionModalInitialValues([]);
    setShowQuestionModal(true);
  };

  const hideQuestionModal = () => {
    setShowQuestionModal(false);
  };

  const handleAdd = ({ question, answerSet }) => {
    return Promise.resolve(
      returnMedicalAnswerSetDetails(answerSet.id).then((answerSetObject) => {
        const answers = getIncludedResource(
          answerSetObject.data.data,
          answerSetObject.data.included,
          'answers',
        );

        let localId = uuid.v4();
        if (editIndex > -1) {
          localId = questions[editIndex].localId;
        }

        const formQuestion = {
          id: question.id,
          localId,
          medicalConditionQuestionId: null,
          text: question.attributes.question,
          answerSetId: answerSet.id,
          answers: [],
        };
        formQuestion.answers = answers.map((answer) => {
          return {
            id: answer.id,
            text: answer.attributes.answer,
            position: answer.attributes.answerIndex,
            forwardConditionId: '',
            nextQuestionIndex: '',
            severityScore: '',
          };
        });

        if (editIndex > -1) {
          fields.insert(editIndex, formQuestion);
          fields.remove(editIndex + 1);
        } else {
          fields.push(formQuestion);
        }
        hideQuestionModal();
      }),
    );
  };

  return (
    <>
      {fields.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map((question, index) => {
                  return (
                    <Draggable key={index} draggableId={`${index}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <Field
                            key={index}
                            index={index}
                            name={question}
                            question={questions[index]}
                            preloadedForwardConditions={preloadedForwardConditions}
                            component={Question}
                            handleRemove={handleRemove}
                            handleEdit={handleEdit}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <p>No questions defined</p>
      )}
      {fields.length < QUESTION_LIMIT && (
        <FullRow>
          <ButtonBar>
            <Button
              label="Add Question"
              variant={'default'}
              handleClick={showNewQuestionModal}
            />
          </ButtonBar>
        </FullRow>
      )}
      <QuestionModal
        show={showQuestionModal}
        questionValues={questionModalInitialValues}
        onHide={hideQuestionModal}
        handleAdd={handleAdd}
      />
    </>
  );
};

export default QuestionsSection;
