import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { actions } from '../../redux/tabs';

const InternalTabs = ({ id, tabs, setActiveTab }) => {
  const activeTab = useSelector((state) => state.tabs.activeTabs?.[id]);

  const handleTab = (key) => {
    setActiveTab(id, key);
  };

  const matchedTab = (filters) => {
    return filters.reduce((carry, filter) => {
      const matched = filter.filterValues.includes(filter.value);
      return carry && matched;
    }, true);
  };
  const filteredTabs = tabs.filter((tab) => {
    if (!tab.content) {
      return false;
    }
    return tab.filter ? matchedTab(tab.filter, tab) : true;
  });

  return (
    <div className="tabbed-content-block">
      <Tabs activeKey={activeTab} id={id} unmountOnExit onSelect={handleTab} justify fill>
        {filteredTabs.map((tab, key) => (
          <Tab
            eventKey={key + 1}
            title={tab.title}
            key={tab.title}
            tabClassName={`tabs-${filteredTabs.length}`}
          >
            {tab.content}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default connect(null, {
  setActiveTab: actions.setActiveTab,
})(InternalTabs);
