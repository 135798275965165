import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import reducers from './reducers';
import promiseMiddleware from '../api/promiseMiddleware';

export let doNotUseThisStore = {};

/*
 * @param {Object} initial state to bootstrap our stores with for server-side rendering
 * @param {History Object} a history object. We use `createMemoryHistory` for server-side rendering,
 *                          while using browserHistory for client-side
 *                          rendering.
 */
export default function configureStore(initialState, history) {
  const middleware = [thunk, promiseMiddleware, routerMiddleware(history)];

  const composeEnhancers =
    (process.env.NODE_ENV !== 'production' &&
      typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const store = createStore(
    reducers(history),
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  if (process.env.NODE_ENV === 'local' && module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers');
      store.replaceReducer(nextReducer);
    });
  }

  doNotUseThisStore = store;

  return store;
}
