import React, { useState } from 'react';
import { Image, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from '../../../../images/logo.svg';
import { Link, ButtonBar, Button } from '../../../../common/components';
import './InternalHeader.scss';
import { Icon } from '../../../../common/components';
import { hasPermission } from '../../../auth/redux/authActions';

const InternalHeader = ({ user, handleLogout }) => {
  const [showAdmin, setShowAdmin] = useState(false);
  const [showLibrary, setShowLibrary] = useState(false);

  const toggleAdmin = () => {
    setShowAdmin(!showAdmin);
  };

  const toggleLibrary = () => {
    setShowLibrary(!showLibrary);
  };

  return (
    <header className="header">
      <div className="container">
        <Link to="/" className="logo-container">
          <Image src={logo} className="logo" />
        </Link>
        <div className="pull-right current-user d-none d-md-block">
          <h4>Welcome back, {user?.data?.attributes?.first_name ?? ''}!</h4>
          <ButtonBar>
            <Button
              className="pull-right"
              variant="primary"
              rightIcon="signout"
              handleClick={handleLogout}
            >
              Logout
            </Button>
          </ButtonBar>
        </div>
      </div>
      <Navbar variant="dark" bg="primary">
        <Navbar.Toggle />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="container">
            <Nav className="mr-auto">
              <Nav.Item>
                <Link className="nav-link" to="/dashboard">
                  <Icon iconName="home" /> Dashboard
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/setup">
                  <Icon iconName="heartbeat" /> Screening Test
                </Link>
              </Nav.Item>
            </Nav>
            {(hasPermission('medical_library.view') ||
              hasPermission('medical_library.version')) && (
              <Nav className="pull-right">
                <NavDropdown
                  title={
                    <>
                      <Icon iconName="book" /> Medical Library
                    </>
                  }
                  id="dropdown-library"
                  show={showLibrary}
                  onToggle={toggleLibrary}
                >
                  {hasPermission('medical_library.view') && (
                    <Nav.Item>
                      <Link
                        className="nav-link"
                        to="/library/questions"
                        onClick={toggleLibrary}
                      >
                        Questions
                      </Link>
                    </Nav.Item>
                  )}
                  {hasPermission('medical_library.view') && (
                    <Nav.Item>
                      <Link
                        className="nav-link"
                        to="/library/answer-sets"
                        onClick={toggleLibrary}
                      >
                        Answer Sets
                      </Link>
                    </Nav.Item>
                  )}
                  {hasPermission('medical_library.view') && (
                    <Nav.Item>
                      <Link
                        className="nav-link"
                        to="/library/question-sets"
                        onClick={toggleLibrary}
                      >
                        Question Sets
                      </Link>
                    </Nav.Item>
                  )}
                  {hasPermission('medical_library.view') && (
                    <Nav.Item>
                      <Link
                        className="nav-link"
                        to="/library/conditions"
                        onClick={toggleLibrary}
                      >
                        Conditions
                      </Link>
                    </Nav.Item>
                  )}
                  {hasPermission('medical_library.version') && (
                    <Nav.Item>
                      <Link
                        className="nav-link"
                        to="/library/versions"
                        onClick={toggleLibrary}
                      >
                        Versions
                      </Link>
                    </Nav.Item>
                  )}
                </NavDropdown>
              </Nav>
            )}
            {(hasPermission('role.view') ||
              hasPermission('organisation.view') ||
              hasPermission('user.view') ||
              hasPermission('screening.stats')) && (
              <Nav className="pull-right">
                <NavDropdown
                  title={
                    <>
                      <Icon iconName="heavyManual" /> Admin
                    </>
                  }
                  id="dropdown-admin"
                  show={showAdmin}
                  onToggle={toggleAdmin}
                >
                  {hasPermission('organisation.view') && (
                    <Nav.Item>
                      <Link
                        className="nav-link"
                        to="/admin/organisations"
                        onClick={toggleAdmin}
                      >
                        Organisations
                      </Link>
                    </Nav.Item>
                  )}
                  {hasPermission('user.view') && (
                    <Nav.Item>
                      <Link className="nav-link" to="/admin/users" onClick={toggleAdmin}>
                        Users
                      </Link>
                    </Nav.Item>
                  )}
                  {hasPermission('role.view') && (
                    <Nav.Item>
                      <Link className="nav-link" to="/admin/roles" onClick={toggleAdmin}>
                        Roles
                      </Link>
                    </Nav.Item>
                  )}
                  {hasPermission('screening.stats') && (
                    <Nav.Item>
                      <Link
                        className="nav-link"
                        to="/admin/reports"
                        onClick={toggleAdmin}
                      >
                        Reports
                      </Link>
                    </Nav.Item>
                  )}
                </NavDropdown>
              </Nav>
            )}
          </div>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default InternalHeader;
