import React from 'react';
import { Field } from 'redux-form';
import {
  ButtonBar,
  Button,
  FullRow,
  InputRow,
  SelectMenuAsyncRow,
} from '../../../../common/components';
import { FaTrash } from 'react-icons/fa';

const ASSOCIATED_EVENT_LIMIT = 100;
const LABEL_SIZES = [4, 4, 3, 3, 3];
const FIELD_SIZES = [8, 8, 9, 9, 9];

const AssociatedEvent = ({
  input,
  handleRemove,
  associatedEvent,
  preloadedAssociatedEvents,
}) => (
  <div className="removable-item">
    <div className="item-section">
      <SelectMenuAsyncRow
        name={input.name}
        fieldSizes={[12, 12, 12, 12, 12]}
        url="library/conditions"
        filters={[{ type: 'parents', match: true }]}
        optionValuePath="id"
        preSelectedOptionValue={associatedEvent ?? ''}
        preSelectedOptionLabel={preloadedAssociatedEvents[associatedEvent] ?? ''}
      />
    </div>
    <div className="remove-section">
      <a className="remove-btn" onClick={handleRemove}>
        <FaTrash />
      </a>
    </div>
  </div>
);

const AssociatedEventsSection = ({
  fields,
  associatedEvents,
  preloadedAssociatedEvents,
}) => {
  const handleAdd = () => {
    if (fields.length < ASSOCIATED_EVENT_LIMIT) {
      fields.push();
    }
  };

  const handleRemove = (index) => {
    fields.remove(index);
  };

  return (
    <div>
      {fields.length <= 0 && <p>No associated events defined</p>}
      {fields.length > 0 && (
        <InputRow
          name="data.attributes.associatedEventsDescription"
          labelSizes={LABEL_SIZES}
          fieldSizes={FIELD_SIZES}
          label="Description"
        />
      )}
      {fields.map((associatedEvent, index) => {
        return (
          <div key={index}>
            <Field
              name={associatedEvent}
              component={AssociatedEvent}
              index={index}
              associatedEvent={associatedEvents[index]}
              handleRemove={() => handleRemove(index)}
              preloadedAssociatedEvents={preloadedAssociatedEvents}
            />
          </div>
        );
      })}
      {fields.length < ASSOCIATED_EVENT_LIMIT && (
        <FullRow>
          <ButtonBar>
            <Button
              label="Add Associated Event"
              variant={'default'}
              handleClick={handleAdd}
            />
          </ButtonBar>
        </FullRow>
      )}
    </div>
  );
};

export default AssociatedEventsSection;
