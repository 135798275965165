import Axios from 'axios';
import * as screeningActions from './screeningReducer';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { push } from 'connected-react-router';

const setSession = (session) => {
  return {
    type: screeningActions.SET_SESSION,
    session,
  };
};

const setVersions = (data) => {
  return {
    type: screeningActions.SET_VERSIONS,
    data,
  };
};

const resetSession = () => {
  return {
    type: screeningActions.RESET_SESSION,
  };
};

const getAllVersions = () => {
  return (dispatch) => {
    return Axios.get('/library/versions?page[limit]=0').then((response) => {
      dispatch(setVersions(response.data));
    });
  };
};

const startSession = (settings = {}, previousId = null) => {
  return (dispatch) => {
    const data = {
      clientId: 'test-ui',
      settings,
    };

    if (previousId) {
      data.previousId = previousId;
    }

    return Axios.post(`screening`, data).then((response) => {
      dispatch(setSession(response.data));
    });
  };
};

const getSession = (id) => {
  return (dispatch, getState) => {
    const screeningId = id ?? getState().screening.sessionId;

    return Axios.get(`screening/${screeningId}`)
      .then((response) => {
        dispatch(setSession(response.data));

        return response.data;
      })
      .catch((error) => {
        dispatch(resetSession());
        dispatch(push('/dashboard'));
        handleErrorResponse(error, 'Unable to load session');
      });
  };
};

const finishSession = () => {
  return (dispatch, getState) => {
    return Axios.post(`screening/${getState().screening.sessionId}:finish`).then(() => {
      return dispatch(getSession());
    });
  };
};

export const actions = {
  setSession,
  setVersions,
  resetSession,
  getAllVersions,
  startSession,
  getSession,
  finishSession,
};

export default actions;
