export const medicalQuestion = {
  data: {
    type: 'medical-question',
    attributes: {
      question: '',
    },
  },
};

export default medicalQuestion;
