import { Field } from 'redux-form';
import React from 'react';
import SelectMenuAsync from './SelectMenuAsync';
import Row from '../Layout/Row';

const SelectMenuAsyncRow = ({
  labelSizes,
  label,
  fieldSizes,
  fieldOffsets,
  ...props
}) => {
  return (
    <Row
      labelSizes={labelSizes}
      label={label}
      fieldSizes={fieldSizes}
      fieldOffsets={fieldOffsets}
    >
      <Field component={SelectMenuAsync} {...props} />
    </Row>
  );
};

export default SelectMenuAsyncRow;
