import React from 'react';
import SetupContainer from '../../../screening/containers/SetupContainer';

const QuickScreeningTest = () => (
  <div className="content-block">
    <h2>Quick Screening Test</h2>
    <hr />
    <SetupContainer formOnly={true} />
  </div>
);

export default QuickScreeningTest;
