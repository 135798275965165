import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import actions from '../redux/versionActions';
import { Loader } from '../../../common/components';
import VersionForm from '../components/VersionForm/VersionForm';

const VersionEditContainer = ({ getVersion, updateVersion, goTo }) => {
  const { id } = useParams();

  useEffect(() => {
    getVersion(id);
  }, [getVersion, id]);

  const saveVersion = (values) => {
    return Promise.resolve(
      updateVersion(id, values).then((resp) => {
        goTo(`/library/versions/${resp.data.id}`);
      }),
    );
  };
  const resource = useSelector((state) => state.version?.data);

  return (
    <div className="container">
      <div className="content-block">
        {resource?.data?.id ? (
          <VersionForm resource={resource} saveVersion={saveVersion} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default connect(null, {
  getVersion: actions.getVersion,
  updateVersion: actions.updateVersion,
  goTo: push,
})(VersionEditContainer);
