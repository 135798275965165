import React from 'react';
import InputField from './InputField';
import Row from '../Layout/Row';

const InputRow = ({
  name,
  className,
  type,
  addOnBefore,
  addOnAfter,
  isCalc,
  currency,
  percent,
  labelSizes,
  fieldSizes,
  labelSize = 0,
  label,
  fieldSize,
  fieldOffset,
  placeholder,
  validate,
  handleOnChange,
  handleOnBlur,
  showValidationError,
  disabled,
  parse,
  format,
  normalize,
}) => (
  <Row
    labelSize={labelSize}
    labelSizes={labelSizes}
    fieldSizes={fieldSizes}
    label={label}
    fieldSize={fieldSize}
    fieldOffset={fieldOffset}
  >
    <InputField
      name={name}
      className={className}
      type={type}
      addOnBefore={addOnBefore}
      addOnAfter={addOnAfter}
      isCalc={isCalc}
      currency={currency}
      percent={percent}
      placeholder={placeholder}
      validate={validate}
      handleOnChange={handleOnChange}
      handleOnBlur={handleOnBlur}
      showValidationError={showValidationError}
      disabled={disabled}
      parse={parse}
      format={format}
      normalize={normalize}
    />
  </Row>
);

export default InputRow;
