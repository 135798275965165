import { Field } from 'redux-form';
import React from 'react';
import Input from './Input';

const InputField = ({
  name,
  type,
  addOnBefore,
  addOnAfter,
  isCalc,
  currency,
  percent,
  placeholder,
  validate,
  handleOnChange,
  handleOnBlur,
  showValidationError,
  disabled,
  className,
  parse,
  format,
  normalize,
}) => (
  <Field
    name={name}
    component={Input}
    type={type}
    addOnBefore={addOnBefore}
    addOnAfter={addOnAfter}
    isCalc={isCalc}
    currency={currency}
    percent={percent}
    placeholder={placeholder}
    validate={validate}
    handleOnChange={handleOnChange}
    handleOnBlur={handleOnBlur}
    showValidationError={showValidationError}
    disabled={disabled}
    className={className}
    parse={parse}
    format={format}
    normalize={normalize}
  />
);
export default InputField;
