import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import actions from '../redux/medicalConditionActions';
import { Loader } from '../../../common/components';
import MedicalConditionForm from '../components/MedicalConditionForm/MedicalConditionForm';

const MedicalConditionEditContainer = ({
  getMedicalCondition,
  updateMedicalCondition,
  goTo,
}) => {
  const { id } = useParams();

  useEffect(() => {
    getMedicalCondition(id);
  }, [getMedicalCondition, id]);

  const saveCondition = (values) => {
    return Promise.resolve(
      updateMedicalCondition(id, values).then((resp) => {
        goTo(`/library/conditions/${resp.data.id}`);
      }),
    );
  };
  const resource = useSelector((state) => state.medicalCondition.resources?.[id]);

  return (
    <div className="container">
      <div className="content-block">
        {resource?.data?.id ? (
          <MedicalConditionForm resource={resource} saveCondition={saveCondition} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default connect(null, {
  getMedicalCondition: actions.getMedicalCondition,
  updateMedicalCondition: actions.updateMedicalCondition,
  goTo: push,
})(MedicalConditionEditContainer);
