export default function filterString(filters = [], join = '?') {
  let string = '';

  filters.map((filter, filterKey) => {
    if (filterKey) {
      string += '';
    }

    const expression = 'filter[' + filter.attr + ']=' + filter.value;
    string += (string === '' ? join : '&') + expression;
  });

  return string;
}
