import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import OrganisationForm from '../components/OrganisationForm/OrganisationForm';
import organisationSchema from '../../../schemas/organisation';
import actions from '../redux/organisationActions';

const OrganisationNewContainer = ({ createOrganisation, goTo }) => {
  const saveOrganisation = (values) => {
    return Promise.resolve(
      createOrganisation(values).then((resp) => {
        goTo(`/admin/organisations/${resp.data.id}`);
      }),
    );
  };

  return (
    <div className="container">
      <div className="content-block">
        <OrganisationForm
          resource={organisationSchema}
          saveOrganisation={saveOrganisation}
        />
      </div>
    </div>
  );
};

export default connect(null, {
  createOrganisation: actions.createOrganisation,
  goTo: push,
})(OrganisationNewContainer);
