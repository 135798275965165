import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import * as roleActions from './roleReducer';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { push } from 'connected-react-router';

export const defaultIncludes = ['permissions'];

const getRole = (id, includes = defaultIncludes) => {
  return (dispatch) => {
    const endpoint = `roles/${id}${queryString(includes)}`;

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: roleActions.SET_ROLE,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        dispatch(push('/dashboard'));
        handleErrorResponse(error, 'Unable to load role');
      });
  };
};

const getPermissions = () => {
  return (dispatch) => {
    return Axios.get('roles/permissions?page[limit]=0')
      .then((response) => {
        dispatch({
          type: roleActions.SET_PERMISSIONS,
          data: response.data.data,
        });
        return true;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };
};

const createRole = (resource) => {
  return () => {
    return Axios.post('roles', resource)
      .then((response) => {
        toastr.success('Success', 'Role has been created successfully');
        return response.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error creating the role');
      });
  };
};

const updateRole = (roleId, resource, includes = defaultIncludes) => {
  return () => {
    const endpoint = `roles/${roleId}${queryString(includes)}`;

    return Axios.patch(endpoint, resource)
      .then((resp) => {
        toastr.success('Success', 'Role has been updated successfully');
        return resp.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error updating the role');
      });
  };
};

const actions = {
  getRole,
  getPermissions,
  createRole,
  updateRole,
};
export default actions;
