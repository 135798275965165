import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import actions from '../redux/organisationActions';
import { Loader } from '../../../common/components';
import OrganisationForm from '../components/OrganisationForm/OrganisationForm';

const OrganisationEditContainer = ({ getOrganisation, updateOrganisation, goTo }) => {
  const { id } = useParams();

  useEffect(() => {
    getOrganisation(id);
  }, [getOrganisation, id]);

  const saveOrganisation = (values) => {
    return Promise.resolve(
      updateOrganisation(id, values).then((resp) => {
        goTo(`/admin/organisations/${resp.data.id}`);
      }),
    );
  };
  const resource = useSelector((state) => state.organisation.resources?.[id]);

  return (
    <div className="container">
      <div className="content-block">
        {resource?.data?.id ? (
          <OrganisationForm resource={resource} saveOrganisation={saveOrganisation} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default connect(null, {
  getOrganisation: actions.getOrganisation,
  updateOrganisation: actions.updateOrganisation,
  goTo: push,
})(OrganisationEditContainer);
