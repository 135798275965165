import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from '../redux/roleActions';
import { Loader } from '../../../common/components';
import RoleDetails from '../components/RoleDetails/RoleDetails';

const RoleShowContainer = ({ getRole }) => {
  const { id } = useParams();
  useEffect(() => {
    getRole(id);
  }, [getRole, id]);

  const resource = useSelector((state) => state.role.resources?.[id]);

  return (
    <div className="container">
      <div className="content-block">
        {resource ? <RoleDetails resource={resource} /> : <Loader />}
      </div>
    </div>
  );
};

export default connect(null, {
  getRole: actions.getRole,
})(RoleShowContainer);
