import React, { useState } from 'react';
import { Button, YearDatePickerRow, ButtonBar } from '../../../../common/components';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import actions from '../../redux/versionActions';
import { Form, reduxForm, getFormValues } from 'redux-form';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import { addYears, addHours } from 'date-fns';
import { required } from 'redux-form-validators';

const FORM_NAME = 'VersionReleaseForm';
const LABEL_SIZES = [6, 6, 6, 6, 6];
const FIELD_SIZES = [6, 6, 6, 6, 6];

const VersionReleaseModal = ({ id, scheduleRelease, goTo, reset, handleSubmit }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleReleaseSubmit = (values) => {
    const releaseDate = values.meta?.release_date
      ? addHours(new Date(values.meta.release_date), 1)
          .toISOString()
          .substring(0, 10)
      : null;
    if (!releaseDate) {
      return null;
    }

    return Promise.resolve(
      scheduleRelease(id, releaseDate).then(() => {
        goTo(`/library/versions`);
      }),
    );
  };

  const handleOpen = () => {
    setShow(true);
  };

  const resetState = () => {
    dispatch(reset());
    setShow(false);
  };

  return (
    <>
      <Button
        key="release-version"
        className="pull-right"
        bsStyle="primary"
        rightIcon="calendar"
        label="Schedule Release"
        handleClick={handleOpen}
      />

      {show && (
        <Modal show={show} onHide={resetState}>
          <Modal.Header closeButton>
            <Modal.Title>Schedule Version Release</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit(handleReleaseSubmit)}>
              <YearDatePickerRow
                label="Release Date"
                labelSizes={LABEL_SIZES}
                fieldSizes={FIELD_SIZES}
                maxDate={addYears(new Date(), 1)}
                minDate={new Date()}
                validate={required({ msg: 'Release date is required' })}
                name="meta.release_date"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <ButtonBar>
              <Button
                type="submit"
                key="create"
                variant="primary"
                className="pull-right"
                handleClick={handleSubmit(handleReleaseSubmit)}
                label="Schedule Release"
              />
              <Button
                key="cancel"
                type="button"
                variant="default"
                className="pull-right"
                handleClick={resetState}
                label="Cancel"
              />
            </ButtonBar>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default compose(
  connect(
    (state) => ({
      formValues: getFormValues(FORM_NAME)(state),
    }),
    {
      scheduleRelease: actions.scheduleRelease,
      goTo: push,
    },
  ),
  reduxForm({ form: FORM_NAME }),
)(withRouter(VersionReleaseModal));
