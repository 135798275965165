import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { getFormSyncErrors, reduxForm } from 'redux-form';
import './LoginForm.scss';
import { required } from 'redux-form-validators';
import {
  ButtonBar,
  InputRow,
  Button,
  FullRow,
  Icon,
} from '../../../../common/components';

const FIELD_SIZE = 12;
const LABEL_SIZE = 0;

const LoginForm = ({ onLogin, handleSubmit, submitting, submitFailed, errors }) => {
  const handleFormSubmit = (values) => {
    return onLogin(values.email, values.password);
  };

  return (
    <div className="container">
      <div className="content-block login-box-container">
        <div className="login-box">
          <h1>Login</h1>
          <hr />
          <Form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="login-form"
            method="post"
          >
            <>
              <InputRow
                fieldSize={FIELD_SIZE}
                labelSize={LABEL_SIZE}
                className="white"
                name="email"
                validate={[required({ msg: 'Email is required' })]}
                type="email"
                addOnBefore={<Icon iconName="envelope" />}
                placeholder="Email address"
              />
            </>
            <div className="spacer" />
            <>
              <InputRow
                fieldSize={FIELD_SIZE}
                labelSize={LABEL_SIZE}
                className="white"
                id="password"
                name="password"
                validate={[required({ msg: 'Password is required' })]}
                type="password"
                addOnBefore={<Icon iconName="lock" />}
                iconName="lock"
                placeholder="Password"
              />
            </>
            <hr />
            <FullRow>
              <ButtonBar>
                <Button
                  id="login-button"
                  type="submit"
                  className="pull-right"
                  variant="primary"
                  isLoading={submitting}
                  leftIcon="signin"
                  disabled={submitting}
                >
                  login
                </Button>
                <Button
                  link
                  className="pull-right"
                  variant="default"
                  label="forgotten password?"
                  to="/password/remind"
                />
              </ButtonBar>
            </FullRow>
            {Object.values(errors).length > 0 && submitFailed && (
              <FullRow>
                <div className="invalid-error">
                  <strong>{Object.values(errors).join(', ')}</strong>
                </div>
              </FullRow>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

const FORM_NAME = 'Login';
export default connect(
  (state) => ({
    errors: getFormSyncErrors(FORM_NAME)(state),
  }),
  null,
)(reduxForm({ form: FORM_NAME })(LoginForm));
