import React from 'react';
import { BackButton, Button, FullRow, LabelRow } from '../../../../common/components';
import InternalButtonBar from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import displayName from '../../../../helpers/displayName';

const LABEL_SIZES = 2;
const FIELD_SIZES = 10;

const OrganisationDetails = ({ resource }) => {
  const resourceAttributes = resource.data.attributes;

  return (
    <>
      <h2 className="resource-name">{resourceAttributes.name}</h2>

      <LabelRow label="Name" labelSizes={LABEL_SIZES} fieldSizes={FIELD_SIZES}>
        {resourceAttributes.name}
      </LabelRow>

      <LabelRow
        label="Organisation Type"
        labelSizes={LABEL_SIZES}
        fieldSizes={FIELD_SIZES}
      >
        {displayName(resourceAttributes.type)}
      </LabelRow>

      <FullRow>
        <InternalButtonBar
          buttons={[
            {
              permissions: ['organisation.edit'],
              button: (
                <Button
                  key="edit-organisation"
                  to={`/admin/organisations/${resource.data.id}/edit`}
                  className="pull-right"
                  bsStyle="primary"
                  rightIcon="edit"
                  label="Edit Organisation"
                />
              ),
            },
            {
              button: <BackButton key="back" to="/admin/organisations" />,
            },
          ]}
        />
      </FullRow>
    </>
  );
};

export default OrganisationDetails;
