import React from 'react';
import { Table } from 'react-bootstrap';
import displayName from '../../../../helpers/displayName';

const ScreeningScoreSettings = ({ settings }) => {
  const renderSetting = (setting, key) => {
    let display = setting ? setting : '-';

    return (
      <tr key={key}>
        <td>{displayName(key)}</td>
        <td>{displayName(display)}</td>
      </tr>
    );
  };

  return (
    <>
      <h2>Settings</h2>
      <Table responsive>
        <tbody>
          {Object.keys(settings).map((key) => renderSetting(settings[key], key))}
        </tbody>
      </Table>
    </>
  );
};

export default ScreeningScoreSettings;
