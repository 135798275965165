import React from 'react';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-text">
          <p>
            &copy;{' '}
            <a href="http://www.cegagroup.com/" target="_blank">
              CEGA Group
            </a>
            . All Rights Reserved. Powered by{' '}
            <a href="http://socrates.systems" target="_blank">
              Socrates Systems
            </a>
            .
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
