import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from '../redux/medicalConditionActions';
import { Loader } from '../../../common/components';
import MedicalConditionDetails from '../components/MedicalConditionDetails/MedicalConditionDetails';
import getIncludedResource from '../../../helpers/getIncludedResource';

const MedicalConditionShowContainer = ({ getMedicalCondition }) => {
  const { id } = useParams();
  useEffect(() => {
    getMedicalCondition(id);
  }, [getMedicalCondition, id]);

  const resource = useSelector((state) => state.medicalCondition.resources?.[id]);
  return (
    <div className="container">
      <div className="content-block">
        {resource ? <MedicalConditionDetails resource={resource} /> : <Loader />}
      </div>
    </div>
  );
};

export default connect(null, {
  getMedicalCondition: actions.getMedicalCondition,
})(MedicalConditionShowContainer);
