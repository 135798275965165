import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import { reducer as selects } from '../common/components/Selects/redux/SelectRedux';
import { reducer as toastr } from 'react-redux-toastr';

import auth from '../modules/auth/redux/authReducer';
import dataTables from '../common/components/DataTable/redux/dataTableReducer';
import medicalAnswerSet from '../modules/library/redux/medicalAnswerSetReducer';
import medicalCondition from '../modules/library/redux/medicalConditionReducer';
import medicalQuestion from '../modules/library/redux/medicalQuestionReducer';
import medicalQuestionSet from '../modules/library/redux/medicalQuestionSetReducer';
import organisation from '../modules/organisation/redux/organisationReducer';
import role from '../modules/role/redux/roleReducer';
import screening from '../modules/screening/redux/screeningReducer';
import stats from '../modules/library/redux/statsReducer';
import tabs from '../modules/layout/redux/tabs';
import user from '../modules/user/redux/userReducer';
import version from '../modules/library/redux/versionReducer';

// Combine reducers with routeReducer which keeps track of
// router state

const test = (history) => {
  return combineReducers({
    router: connectRouter(history),
    auth,
    dataTables,
    form,
    medicalAnswerSet,
    medicalCondition,
    medicalQuestion,
    medicalQuestionSet,
    organisation,
    role,
    screening,
    stats,
    selects,
    tabs,
    toastr,
    user,
    version,
  });
};

export default test;
